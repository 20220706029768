import React, { FC } from "react";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import styles from "./styles.module.scss";
import cx from "classnames";
import { CmsResponsiveImage } from "../Blog";

export interface IdentImageTitleProps {
  Tag: "h1" | "h2" | "h3" | "h4";
  image: CmsResponsiveImage;
  title: string;
  imageAlignment: "right" | "left";
}

export const IdentImageTitle: FC<IdentImageTitleProps> = ({
  Tag,
  title,
  image,
  imageAlignment
}) => {
  return (
    <div className={styles.identImageTitle}>
      <Tag>{title}</Tag>
      <GatsbyImage
        className={cx(styles.titleIdentImage, styles[imageAlignment])}
        alt={image.title}
        image={getImage(image.gatsbyImageData) as IGatsbyImageData}
      />
    </div>
  );
};
