import React, { FC, SyntheticEvent } from "react";
import cx from "classnames";
import { Button } from "components/Button";
import {
  FormErrors,
  FormFieldDefinition,
  FormTouched,
  UseFieldInterface
} from "hooks";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { Spinner } from "../Spinner/Spinner";
import { FormField } from "../FormField";
import { EmailFormValues } from "../EmailForm";
import styles from "./form.module.scss";

export interface FormProps {
  touched: FormTouched<string>;
  errors: FormErrors<string>;
  useField: (key: string) => UseFieldInterface;
  fields: Record<string, FormFieldDefinition<string | boolean>>;
  isSubmitting: boolean;
  submissionError?: Error;
  onFormSubmit: (event: SyntheticEvent) => void;
  onSubmitted: (values: EmailFormValues) => void;
  instructions?: string;
  sendButtonText: string;
  focusedField: string | null;
  className?: string;
  errorClassName?: string;
}

export const Form: FC<FormProps> = props => {
  const {
    useField,
    fields,
    touched,
    errors,
    isSubmitting,
    submissionError,
    onFormSubmit,
    focusedField,
    instructions,
    sendButtonText,
    className = "",
    errorClassName = ""
  } = props;
  const hasErrors = Object.values(errors).some(el => el !== null);
  return (
    <form
      onSubmit={onFormSubmit}
      className={cx(className, { [styles.isSubmitting]: isSubmitting })}
    >
      {instructions && <h3 className={styles.instructions}>{instructions}</h3>}
      {Object.entries(fields).map(([id, field]) => {
        const fieldProps = {
          id,
          useField,
          touched,
          errors,
          field,
          focusedField
        };
        return <FormField {...fieldProps} key={id} />;
      })}
      {isSubmitting ? (
        <Spinner />
      ) : (
        <Button
          className={styles.sendButton}
          onClick={onFormSubmit}
          disabled={hasErrors}
        >
          {sendButtonText}
        </Button>
      )}
      <ErrorMessage error={submissionError} className={errorClassName} />
    </form>
  );
};
Form.displayName = "Form";
