import React, { FC } from "react";
import { CmsBlogPost } from "../types";
import { useSortPosts } from "../hooks";
import { PageCarousel } from "../../PageCarousel";
import { Colour } from "../../constants";
import { BlogPostCardProps } from "../BlogHome";
import { CmsPageSectionBase } from "../../CardPanel";

export interface CmsCategorySection extends CmsPageSectionBase {
  id: string;
  title: string;
  slug: string;
  blog_post: CmsBlogPost[];
}

export const Category: FC<CmsCategorySection> = ({ title, blog_post }) => {
  const sortedPosts = useSortPosts(blog_post);
  const mapped = sortedPosts.slice(0, 9).map((post): BlogPostCardProps => {
    return {
      __typename: "ContentfulBlogPost",
      post: post,
      id: post.id,
      inCarousel: true
    };
  });

  return (
    <PageCarousel
      __typename="ContentfulPageCarousel"
      backgroundColour={Colour.warmStone}
      title={title}
      displayItems={3}
      showControls={true}
      autoScroll={false}
      items={mapped}
    />
  );
};

Category.displayName = "Category";
