import React, { FC } from "react";
import { graphql } from "gatsby";
import {
  LostCardPage,
  LostCardPageLayout
} from "components/LostCard/LostCardPageLayout";

export interface LostCardPageProps {
  data: {
    page: LostCardPage;
  };
}

export const LostCardHomePage: FC<LostCardPageProps> = ({ data: { page } }) => (
  <LostCardPageLayout title="Lost your card?" page={page} includeForm={true} />
);

export const PageQuery = graphql`
  query LostCardHomePageQuery {
    page: contentfulLostCardPage(lostCardPageId: { eq: "lostCard" }) {
      darkTheme
      image {
        gatsbyImageData(width: 2560, placeholder: BLURRED)
      }
      title
      mainContent {
        raw
      }
      helpText {
        raw
      }
      formCheckboxText
      confirmationTextTitle
      confirmationTextBody
      confirmationTextFooter
    }
  }
`;

export default LostCardHomePage;
