import { useEffect } from "react";
import { generateQR, QRCodeParameters } from "lib";

export const useQRCode = ({
  canvas,
  link,
  size = 300,
  errorCorrectionLevel = "H"
}: QRCodeParameters) => {
  useEffect(() => {
    if (canvas && link) {
      generateQR({ canvas, link, size, errorCorrectionLevel });
    }
  }, [link, canvas]);
};
