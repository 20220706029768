import React, { FC } from "react";
import { Colour } from "../constants";
import cx from "classnames";
import styles from "./styles.module.scss";

export interface AwardCardProps {
  awardTitle: string;
  awardImage: { title: string; file: { url: string } };
  awardUrl?: string;
  backgroundColour: Colour;
}

export const AwardCard: FC<AwardCardProps> = ({
  awardTitle,
  awardImage,
  backgroundColour
}) => {
  return (
    <div className={cx(styles.awardCard, styles[backgroundColour])}>
      <div className={styles.copy}>
        <p>{awardTitle}</p>
      </div>
      <div className={styles.image}>
        <img
          className={styles.image}
          alt={awardImage.title}
          src={awardImage.file.url}
        />
      </div>
    </div>
  );
};
