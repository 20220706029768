import React, { FC } from "react";
import { Panel, PanelItem, PanelLayout } from "../../Panel";
import { Colour } from "../../constants";
import styles from "./styles.module.scss";
import { BlogPostCard } from "../BlogHome";
import { CmsBlogPost } from "../types";
export interface YouMayAlsoLikeProps {
  posts: CmsBlogPost[] | null;
}

export const YouMayAlsoLike: FC<YouMayAlsoLikeProps> = ({ posts }) => {
  return posts && posts.length > 0 ? (
    <Panel
      data-testid="you-may-also-like"
      layout={PanelLayout.full}
      colour={Colour.warmStone}
      short
      className={styles.youMayAlsoLikePanel}
    >
      <PanelItem className={styles.youMayAlsoLike}>
        <h4 className={styles.title}>You might also like</h4>
        <div className={styles.inner}>
          {posts.map(post => (
            <BlogPostCard
              __typename="ContentfulBlogPost"
              id={post.id}
              key={post.slug}
              showHeroImage={true}
              post={post}
            />
          ))}
        </div>
      </PanelItem>
    </Panel>
  ) : null;
};

YouMayAlsoLike.displayName = "YouMayAlsoLike";
