import React, { FC } from "react";
import { CmsBlogPost } from "../Blog";
import { useSortPosts } from "../Blog/hooks";
import { PageCarousel } from "../PageCarousel";
import { Colour } from "../constants";
import { useMappedPosts } from "../Blog/hooks/useMappedPosts";

interface AuthorPostsCarouselProps {
  blogPosts: CmsBlogPost[];
  authorName: string;
}

export const AuthorPostsCarousel: FC<AuthorPostsCarouselProps> = ({
  blogPosts,
  authorName
}) => {
  const sortedPosts = useSortPosts(blogPosts);
  const mapped = useMappedPosts(sortedPosts.slice(0, 5));
  const formattedTitle = () => `Previous articles by ${authorName}`;

  return (
    <PageCarousel
      __typename="ContentfulPageCarousel"
      backgroundColour={Colour.warmStone}
      title={formattedTitle()}
      displayItems={Math.min(3, mapped.length)}
      autoScroll={false}
      items={mapped}
      showControls
    />
  );
};

AuthorPostsCarousel.displayName = "AuthorPostsCarousel";
