import React, { ReactElement, ReactNode } from "react";
import {
  BLOCKS,
  AssetLinkBlock,
  Hyperlink,
  INLINES,
  Heading3,
  Inline,
  Text
} from "@contentful/rich-text-types";
import kebabCase from "lodash/kebabCase";
import { Link } from "components/Link";
import { ReactPostProcessor, useRichTextWithOptions } from "hooks/useRichText";
import { InlineAsset, InlineAssetPair } from "../BlogPost/InlineAsset";
import { CONTENT_TYPE_MP4, VideoAsset } from "../BlogPost/VideoAsset";

export const getKeysForInlineAssets = (nodes: ReactElement[]): number[] =>
  nodes
    .filter(Boolean)
    .filter(({ type }) => type === InlineAsset)
    .map(({ key }) => Number(key));

export const convertToPairs = (keys: number[]): [number, number][] => {
  return keys.reduce(
    (acc, key, index, keys) => {
      if (!index || acc.some(pair => pair.includes(key))) {
        return acc;
      }
      if (keys[index - 1] === key - 1) {
        return acc.concat([[keys[index - 1], key]]);
      }
      return acc;
    },
    [] as [number, number][]
  );
};

export const reduceToInlineAssetPair = (
  nodes: ReactElement[],
  pair: [number, number]
) => {
  const [first, second] = pair.map(pairKey =>
    nodes.find(({ key }) => pairKey === Number(key))
  );

  // This shouldn't ever happen, but stranger things etc.
  if (!first || !second) {
    return nodes;
  }

  return [
    ...nodes.slice(0, nodes.indexOf(first)),
    <InlineAssetPair key={pair.join("-")}>
      {first}
      {second}
    </InlineAssetPair>,
    ...nodes.slice(nodes.indexOf(second) + 1)
  ];
};

/**
 * This magic little function takes any two consecutive InlineAsset elements
 * and combines them into a single entity.
 */
export const combineInlineAssets: ReactPostProcessor = nodes => {
  const keys = getKeysForInlineAssets(nodes);
  const pairs = convertToPairs(keys);
  return pairs.reduce(reduceToInlineAssetPair, nodes);
};

export interface File {
  url: string;
  contentType: string;
  fileName: string;
  details: {
    size: number;
    image: { width: number; height: number };
  };
}

export interface Asset {
  __typename: "ContentfulAsset";
  title: string;
  description: string;
  contentful_id: string;
  file: File;
  sys: {
    type: "Link";
    linkType: "Asset";
    id: string;
  };
}

export const renderHyperlink = (
  { data: { uri } }: Hyperlink,
  children: React.ReactElement
) => {
  return <Link url={uri}>{children}</Link>;
};

export const renderEmbeddedAsset = (node: AssetLinkBlock) => {
  const { file, title, description } = node.data.target as Asset;

  if (!file) {
    console.warn(`Asset not found for embedded asset "${title}"`, node);
    return null;
  }

  const { contentType, url } = file;
  if (contentType === CONTENT_TYPE_MP4) {
    return (
      <VideoAsset
        url={url}
        fileName={file.fileName}
        videoType={description}
        autoHeight
      />
    );
  } else {
    const {
      details: {
        image: { height, width }
      }
    } = file;

    return (
      <InlineAsset url={url} width={width} height={height} caption={title} />
    );
  }
};

const isText = (content: Inline | Text): content is Text =>
  content.nodeType === "text";

const renderHeading3 = (node: Heading3, children: ReactNode[]) => {
  const content = node.content[0];
  const props = {
    id: isText(content) ? kebabCase(content.value) : ""
  };
  return React.createElement("h3", props, children);
};

export const useBlogRichText = useRichTextWithOptions(
  {
    renderNode: {
      [BLOCKS.HEADING_3]: renderHeading3,
      [BLOCKS.EMBEDDED_ASSET]: renderEmbeddedAsset,
      [INLINES.HYPERLINK]: renderHyperlink
    }
  },
  combineInlineAssets
);
