import React, { FC } from "react";
import styles from "./styles.module.scss";
import SVG from "react-inlinesvg";
import icon from "../../../assets/icons/info.svg";
import cx from "classnames";

export interface CalculatorInputProps {
  value?: string;
  changeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  type: string;
  label: string;
  invalidMessage: string;
  unit: string;
  helpText: string;
  placeholder: string;
  max: number;
}

export const CalculatorInput: FC<CalculatorInputProps> = ({
  value,
  changeHandler,
  name,
  type,
  label,
  invalidMessage,
  unit,
  helpText,
  placeholder,
  max
}) => {
  const invalid = () => !value || Number(value) > max;

  return (
    <div className={styles.input}>
      <label htmlFor={name}>
        <span>{label}</span>{" "}
        <div tabIndex={0} className={styles.button}>
          <SVG src={icon} />
          <div className={cx(styles.tooltip, styles.desktopToolTip)}>
            <span>{helpText}</span>
          </div>
        </div>
      </label>
      <span
        className={cx(styles.inputWrapper, {
          [styles.currency]: unit === "currency",
          [styles.percentage]: unit === "percentage"
        })}
      >
        <input
          className={cx({
            [styles.invalid]: invalid()
          })}
          name={name}
          value={value}
          onChange={changeHandler}
          placeholder={placeholder}
          type={type}
          min="1"
        />
      </span>
      <div className={styles.invalidMsg}>{invalid() ? invalidMessage : ""}</div>
    </div>
  );
};

CalculatorInput.displayName = "CalculatorInput";
