import React from "react";
import { useBranchQRCode } from "./useBranchQRCode";
import cx from "classnames";
import styles from "./styles.module.scss";
import { PanelItem } from "components/Panel";
import { CardCopy } from "components/Card/CardCopy";
import { Colour } from "components/constants";

const steps: { title: string; colour: Colour }[] = [
  {
    title: "Scan the QR code to download Mettle",
    colour: Colour.teal100
  },
  {
    title: "Complete your application",
    colour: Colour.teal20
  },
  {
    title: "Start using Mettle",
    colour: Colour.softBlack
  }
];

export const QRCodeForm = () => {
  const { qrCode, loaded, type } = useBranchQRCode();

  return (
    <PanelItem className={styles.qrForm}>
      <div className={styles.applyCode}>
        <h2 className={styles.title}>Apply in minutes from the app</h2>

        <img
          alt="Qr Code"
          className={cx(styles.qrCodeImage, {
            [styles.wobble]: loaded,
            [styles.staticQRCode]: type === "static"
          })}
          src={qrCode}
        ></img>
      </div>

      <div className={styles.steps}>
        {steps.map((step, index) => (
          <div className={cx(styles.card, styles[step.colour])} key={index}>
            <CardCopy
              backgroundColour={step.colour}
              subtitle={step.title}
              number={String(index + 1)}
              reducedHeaders
            />
          </div>
        ))}
      </div>
    </PanelItem>
  );
};
