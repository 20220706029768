import React, { FC } from "react";
import styles from "./styles.module.scss";
import { Colour, SectionPaddingDefault } from "../constants";
import { graphql } from "gatsby";
import { CmsPageSectionBase } from "../CardPanel";
import { Panel, PanelItem, PanelLayout } from "../Panel";
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData
} from "gatsby-source-contentful/rich-text";
import { CmsAuthor } from "../Blog";
import cx from "classnames";
import { AuthorImage, AuthorImageSize } from "../AuthorImage";
import { Link } from "../Link";
import { useContributorUrl } from "../Blog/hooks/useContributorUrl";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
  BLOCKS,
  Heading4,
  Hyperlink,
  INLINES
} from "@contentful/rich-text-types";
import { useIsBlogPage } from "../../hooks/useIsBlogPage";

export interface CmsQuoteCard extends CmsPageSectionBase {
  id: string;
  title: string;
  attribution: string;
  ctaText?: string;
  ctaUrl?: string;
  richText: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  backgroundColour: Colour;
  author: CmsAuthor;
  cardPanel?: boolean;
}

export const QuoteCard: FC<CmsQuoteCard> = ({
  backgroundColour,
  author,
  isPageSection,
  richText,
  sectionPaddingTop,
  sectionPaddingBottom,
  cardPanel
}) => {
  const url = useContributorUrl(author.slug);
  const isBlogPage = useIsBlogPage();
  const options = {
    renderNode: {
      [BLOCKS.HEADING_4]: (_: Heading4, children: React.ReactElement) => {
        return (
          <h4 className={styles.heading4}>
            <span className={styles.openingQuote}>&ldquo;</span>
            {children}&rdquo;
          </h4>
        );
      },
      [INLINES.HYPERLINK]: (
        { data: { uri } }: Hyperlink,
        children: React.ReactElement
      ) => {
        return <Link url={uri}>{children}</Link>;
      }
    }
  };
  const Content = () => {
    return (
      <div
        className={cx(styles.quoteCard, styles[backgroundColour], {
          [styles.inCardPanel]: Boolean(cardPanel),
          [styles.isBlog]: isBlogPage,
          [styles.noPad]: isBlogPage && backgroundColour === Colour.warmStone
        })}
      >
        {!isBlogPage && (
          <AuthorImage
            className={styles.authorImage}
            photo={author?.photo}
            size={AuthorImageSize.MEDIUM}
            name={author.name}
          />
        )}
        <div className={styles.contentSection}>
          <div className={styles.richText}>
            {documentToReactComponents(
              JSON.parse(richText?.raw || ""),
              options
            )}
          </div>
          <div className={styles.authorDetails}>
            {isBlogPage && (
              <AuthorImage
                className={styles.authorImage}
                photo={author?.photo}
                name={author.name}
              />
            )}
            <div>
              <Link backgroundColour={backgroundColour} url={url} title={url}>
                {author.name}
              </Link>
              <div className={styles.authorPosition}>
                {`${author.jobTitle}, ${author.company}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return isPageSection ? (
    <Panel
      className={cx(
        styles.quoteSection,
        styles[sectionPaddingTop || SectionPaddingDefault.TOP],
        styles[sectionPaddingBottom || SectionPaddingDefault.BOTTOM]
      )}
      layout={PanelLayout.full}
      short
      colour={Colour[backgroundColour]}
    >
      <PanelItem>
        <Content />
      </PanelItem>
    </Panel>
  ) : (
    <Content />
  );
};

QuoteCard.displayName = "Quote Card";

export const ContentfulQuoteCardFragment = graphql`
  fragment ContentfulQuoteCardFragment on ContentfulQuoteCard {
    __typename
    id
    richText {
      raw
    }
    backgroundColour
    sectionPaddingTop
    sectionPaddingBottom
    author {
      ...ContentfulAuthorFragment
    }
  }
`;
