import * as Sentry from "@sentry/gatsby";

export async function fetchCarefully<T>(
  endpoint: string,
  options: RequestInit = {}
) {
  try {
    return await fetch(endpoint, options);
  } catch (error) {
    Sentry.captureException(error);
    return {} as Response;
  }
}
