import React, { FC } from "react";
import { Link } from "components/Link";
import { NumberedList } from "components/NumberedList";
import styles from "./styles.module.scss";

type DesktopLeftPanelProps = {
  title: string;
  alternatively: string;
  listItems?: string[];
  desktopMessage: string;
};

export const DesktopLeftPanel: FC<DesktopLeftPanelProps> = ({
  title,
  alternatively,
  listItems = [],
  desktopMessage
}) => (
  <>
    <h2 className={styles.title}>{title}</h2>
    <p className={styles.description}>{desktopMessage}</p>
    <div className={styles.link}>
      <Link
        className={styles.link}
        url="https://intercom.help/MettleFAQs/en/articles/3058263-how-to-scan-the-qr-code-with-your-phone"
      >
        How to scan a QR code
      </Link>
    </div>
    {listItems.length > 0 && (
      <>
        <h5 className={styles.alternativeText}>{alternatively}</h5>
        <NumberedList listItems={listItems} />
      </>
    )}
  </>
);

DesktopLeftPanel.displayName = "DesktopLeftPanel";
