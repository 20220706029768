import { graphql } from "gatsby";
import React, { FC } from "react";
import { CmsAuthor } from "../types";
import styles from "./Author.module.scss";
import { Link } from "../../Link";
import { useBlogRichText } from "../hooks";
import lowerCase from "lodash/lowerCase";
import { useContributorUrl } from "../hooks/useContributorUrl";
import { AuthorImage } from "components/AuthorImage";
import { AuthorLinkedInIcon } from "../../AuthorLinkedInIcon";
import { AuthorHiddenSeo } from "../../AuthorHiddenSeo";

export const BlogPostAuthor: FC<CmsAuthor> = ({
  name,
  photo,
  jobTitle,
  company,
  knowsAbout,
  linkedInUrl,
  bio,
  slug,
  website
}) => {
  const getCompany = () => {
    return lowerCase(company) === "mettle" ? (
      <span itemProp="name">{company}</span>
    ) : (
      <Link title={company} secondary itemProp="url" url={website}>
        <span itemProp="name">{company}</span>
      </Link>
    );
  };

  const getBioLink = () => {
    return lowerCase(slug) === "mettle" ? (
      <span itemProp="name">{name}</span>
    ) : (
      <Link title={name} secondary itemProp="url" url={useContributorUrl(slug)}>
        <span itemProp="name">{name}</span>
      </Link>
    );
  };

  return (
    <div
      className={styles.author}
      data-testid="author"
      itemScope
      itemType="http://schema.org/Person"
    >
      <AuthorImage className={styles.authorPhoto} photo={photo} name={name} />
      <div className={styles.details}>
        <div className={styles.bioLink}>{getBioLink()}</div>
        {jobTitle && (
          <div className={styles.job}>
            <span itemProp="jobTitle">{jobTitle}</span>,&nbsp;
            <span
              itemProp="worksFor"
              itemScope
              itemType="http://schema.org/Organization"
            >
              {getCompany()}
            </span>
          </div>
        )}
        {linkedInUrl && (
          <AuthorLinkedInIcon
            linkedInUrl={linkedInUrl}
            name={name}
            className={styles.linkedIn}
          />
        )}
        <div itemProp="description" className={styles.bio}>
          {useBlogRichText(bio)}
        </div>
        <AuthorHiddenSeo
          knowsAbout={knowsAbout}
          slug={slug}
          linkedInUrl={linkedInUrl}
        />
      </div>
    </div>
  );
};
BlogPostAuthor.displayName = "BlogPostAuthor";

export const ContentfulAuthorFragment = graphql`
  fragment ContentfulAuthorFragment on ContentfulAuthor {
    slug
    name
    website
    twitter
    linkedInUrl
    company
    knowsAbout
    jobTitle
    bio {
      raw
    }
    extendedBio {
      raw
    }
    photo {
      gatsbyImageData(width: 500, placeholder: BLURRED)
    }
  }
`;

export const ContentfulAuthorWithPostsFragment = graphql`
  fragment ContentfulAuthorWithPostsFragment on ContentfulAuthor {
    ...ContentfulAuthorFragment
    blog_post {
      ...ContentfulBlogPost
    }
  }
`;
