import {
  DeviceOS,
  DeviceSize,
  useDeviceOs,
  useDeviceSize
} from "@mettle/hooks";
import { isAndroid, isIOs } from "lib/os";

export const useOnboardingType = (): Exclude<DeviceOS, DeviceOS.Other> => {
  const os = useDeviceOs();
  const size = useDeviceSize();

  if (isAndroid(os)) {
    return DeviceOS.Android;
  } else if (isIOs(os) && size === DeviceSize.Mobile) {
    return DeviceOS.iOS;
  }

  return DeviceOS.Desktop;
};
