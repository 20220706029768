import QRCode, { QRCodeErrorCorrectionLevel } from "qrcode";

export type QRCodeParameters = {
  canvas?: any;
  link?: string;
  size?: number;
  errorCorrectionLevel?: QRCodeErrorCorrectionLevel;
};

export const generateQR = ({
  canvas,
  link = "",
  size = 300,
  errorCorrectionLevel = "H"
}: QRCodeParameters) => {
  QRCode.toCanvas(
    canvas,
    link,
    {
      errorCorrectionLevel,
      width: size
    },
    (error: any) => {
      if (error) console.error(error);
    }
  );
};
