export const inViewport = (el: HTMLElement | null, fudgeFactor: number) => {
  if (el === null) {
    return false;
  }
  const rect = el.getBoundingClientRect();
  const widthAdjustment = rect.width * fudgeFactor;
  const heightAdjustment = rect.height * fudgeFactor;
  return (
    rect.top + heightAdjustment >= 0 &&
    rect.left + widthAdjustment >= 0 &&
    rect.bottom - heightAdjustment <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right - widthAdjustment <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};
