import React, { FC } from "react";
import { useOnboardingState } from "hooks";
import { Onboarding } from "components/OpenAccount/Onboarding";

export const OnboardingContainer: FC = () => {
  const { isVisible, hide } = useOnboardingState();

  return isVisible ? <Onboarding onClose={hide} /> : null;
};
OnboardingContainer.displayName = "OnboardingContainer";
