import React, { FC } from "react";
import { Link } from "components/Link";
import { PLAY_STORE_URL, useConfig } from "hooks";
import { Env } from "conf/env";
import playStoreIcon from "assets/icons/playStore.png";

interface AndroidStoreButtonProps {
  androidQuickLink: string;
  testAndroidQuickLink: string;
  linkClassName?: string;
  imgClassName?: string;
}

export const AndroidStoreButton: FC<AndroidStoreButtonProps> = ({
  testAndroidQuickLink,
  androidQuickLink,
  linkClassName,
  imgClassName
}) => {
  const config = useConfig();
  let androidLink = PLAY_STORE_URL;

  if (config?.BRANCH_IO_ENABLED) {
    androidLink =
      config.ENV === Env.TEST ? testAndroidQuickLink : androidQuickLink;
  }

  return (
    <Link
      url={androidLink}
      className={linkClassName}
      data-testid="android-link"
    >
      <img
        src={playStoreIcon}
        className={imgClassName}
        alt="Download the Mettle app on Android"
      />
    </Link>
  );
};
