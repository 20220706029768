import React, { FC } from "react";
import { Button, ButtonProps, ButtonVariants } from "components/Button";
import { useExternalNavigation } from "hooks";
import { handleNavigation } from "components/Link/hooks";

const url = "https://web.mettle.co.uk/";

export const LogInButton: FC<ButtonProps> = ({
  id = "unknown",
  text,
  smallText,
  ...buttonProps
}) => {
  const logExternalNavigation = useExternalNavigation();

  const navigate = React.useCallback(() => {
    logExternalNavigation(url, true);
    handleNavigation(url)({} as React.SyntheticEvent<any>);
  }, []);

  return (
    <Button
      smallText={smallText}
      {...buttonProps}
      variant={ButtonVariants.SECONDARY}
      url={url}
      onClick={navigate}
    >
      {text}
    </Button>
  );
};
