import React, { FC } from "react";
import { graphql } from "gatsby";
import { Panel, PanelItem, PanelLayout } from "../Panel";
import styles from "./styles.module.scss";
import cx from "classnames";
import { HeroCtas } from "../PageHero/HeroCtas";
import { AppStoreButtons } from "components/AppStoreButtons";
import { CmsPageSectionBase, ImageFit } from "../CardPanel";
import { Colour, SectionPaddingDefault } from "../constants";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";

export interface CmsPaddedPageBannerSection extends CmsPageSectionBase {
  title?: string;
  subtitle?: string;
  bodyCopy?: string;
  listItems?: string[];
  primaryCtaUrl?: string;
  primaryCtaText?: string;
  secondaryCtaUrl?: string;
  secondaryCtaText?: string;
  showOpenAccountButton: boolean;
  iosQuickLink?: string;
  testIosQuickLink?: string;
  androidQuickLink?: string;
  testAndroidQuickLink?: string;
  image: IGatsbyImageData;
  backgroundColour: Colour;
}

export const PaddedPageBanner: FC<CmsPaddedPageBannerSection> = ({
  title,
  subtitle,
  bodyCopy,
  listItems,
  primaryCtaUrl,
  primaryCtaText,
  secondaryCtaUrl,
  secondaryCtaText,
  showOpenAccountButton,
  iosQuickLink,
  testIosQuickLink,
  androidQuickLink,
  testAndroidQuickLink,
  image,
  sectionPaddingTop,
  sectionPaddingBottom,
  backgroundColour
}) => {
  return (
    <Panel
      className={styles.paddedPageBanner}
      short
      colour={Colour.warmStone}
      layout={PanelLayout.full}
    >
      <PanelItem
        className={cx(
          styles.bannerItem,
          styles[sectionPaddingTop || SectionPaddingDefault.TOP],
          styles[sectionPaddingBottom || SectionPaddingDefault.BOTTOM]
        )}
      >
        <div className={styles.content}>
          <div
            className={cx(
              styles.copyContainer,
              styles[backgroundColour || Colour.warmStone]
            )}
          >
            <div className={styles.copy}>
              {title && <h4>{title}</h4>}
              {subtitle && <h5>{subtitle}</h5>}
              {bodyCopy && <p>{bodyCopy}</p>}
              {listItems?.length && (
                <ul>
                  {listItems.map(item => {
                    return <li key={item}>{item}</li>;
                  })}
                </ul>
              )}
              <HeroCtas
                className={styles.ctaContainer}
                showOpenAccountButton={showOpenAccountButton}
                primaryCtaUrl={primaryCtaUrl}
                primaryCtaText={primaryCtaText}
                secondaryCtaUrl={secondaryCtaUrl}
                secondaryCtaText={secondaryCtaText}
              />
              <AppStoreButtons
                className={cx(styles.appStoreButtons, styles.ctaContainer)}
                testIosQuickLink={testIosQuickLink}
                testAndroidQuickLink={testAndroidQuickLink}
                iosQuickLink={iosQuickLink}
                androidQuickLink={androidQuickLink}
              />
            </div>
          </div>
        </div>
      </PanelItem>

      {image && (
        <div className={styles.backgroundImage}>
          <GatsbyImage
            alt=""
            imgStyle={{
              objectFit: ImageFit.COVER
            }}
            image={getImage(image) as IGatsbyImageData}
          />
        </div>
      )}
    </Panel>
  );
};

PaddedPageBanner.displayName = "PaddedPageBanner";

export const ContentfulPaddedPageBannerFragment = graphql`
  fragment ContentfulPaddedPageBannerFragment on ContentfulPaddedPageBanner {
    __typename
    contentful_id
    title
    subtitle
    bodyCopy
    listItems
    primaryCtaUrl
    primaryCtaText
    secondaryCtaUrl
    secondaryCtaText
    showOpenAccountButton
    iosQuickLink
    testIosQuickLink
    androidQuickLink
    testAndroidQuickLink
    sectionPaddingTop
    sectionPaddingBottom
    image {
      gatsbyImageData(width: 2560, placeholder: BLURRED)
    }
    backgroundColour
  }
`;
