import React, { FC } from "react";
import cx from "classnames";
import { NumberSize } from "../constants";
import styles from "./styles.module.scss";

interface Props {
  number: number | string;
  size?: NumberSize;
  className?: string;
}

export const Number: FC<Props> = props => {
  const { number, size = NumberSize.small, className } = props;
  return (
    <div
      className={cx(
        styles.number,
        {
          [styles.large]: size === NumberSize.large
        },
        className
      )}
    >
      {number}
    </div>
  );
};
Number.displayName = "Number";
