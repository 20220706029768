import React, { FC } from "react";
import cx from "classnames";
import { captureLeadEmail, isValidEmail, isValidLength } from "lib";
import { formStateHook } from "hooks";
import { getPolicyFieldConfig } from "../CommonForm";
import { Form } from "../Form/Form";
import {
  EmailFormConfig,
  getOpenAccountEmailFormConfig
} from "components/CaptureLeadSection/emailFormConfig";
import commonStyles from "../CommonForm/styles.module.scss";
import styles from "./styles.module.scss";

export interface EmailFormValues {
  email: string;
  policyAgreed: boolean;
}

export const createEmailValidationFunction =
  (
    emailFormConfig: Pick<
      EmailFormConfig,
      "noEmailValidationError" | "emailValidationError"
    >
  ) =>
  (email: string | null) => {
    const trimmedEmail = email?.trim() || "";
    if (!isValidLength(trimmedEmail)) {
      throw new Error(emailFormConfig.noEmailValidationError);
    }
    if (!isValidEmail(trimmedEmail)) {
      throw new Error(emailFormConfig.emailValidationError);
    }
  };

export const EmailIcon: FC = () => <div className={styles.emailIcon} />;

const createEmailOnboardingFormHook = (emailFormConfig: EmailFormConfig) =>
  formStateHook<EmailFormValues>({
    fields: {
      email: {
        type: "text",
        applyFocus: false,
        placeholder: "Email",
        className: styles.emailInput,
        focusClassName: styles.focus,
        required: true,
        pattern: /.+/,
        extraContent: <EmailIcon />,
        validate: createEmailValidationFunction(emailFormConfig)
      },
      policyAgreed: getPolicyFieldConfig(emailFormConfig)
    },
    async submit(values) {
      await captureLeadEmail(emailFormConfig, values.email);
    }
  });

interface Props {
  emailFormConfig?: EmailFormConfig;
  onSubmitted: (values: EmailFormValues) => void;
  className?: string;
}

export const OnboardingEmailForm: FC<Props> = ({
  emailFormConfig = getOpenAccountEmailFormConfig(),
  onSubmitted,
  className = ""
}) => {
  const useEmailForm = createEmailOnboardingFormHook(emailFormConfig);
  const emailForm = useEmailForm({ onSubmitted });
  return (
    <div className={cx(commonStyles.top, className)}>
      <h2>{emailFormConfig.header}</h2>
      <Form
        {...emailForm}
        onSubmitted={onSubmitted}
        instructions={emailFormConfig.subHeader}
        sendButtonText={emailFormConfig.buttonText}
      />
    </div>
  );
};
OnboardingEmailForm.displayName = "OnboardingEmailForm";
