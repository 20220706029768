import React, { FC } from "react";
import { useIsClient } from "@mettle/hooks";
import { Colour } from "components/constants";
import { Panel, PanelItem, PanelLayout } from "components/Panel";
import { Link } from "components/Link";
import styles from "./styles.module.scss";
import { SupplierVariant } from "./types";
import { getUriForDocType } from "./legalDocs";
import { LegalDocsData, useGetLegalDocsData } from "hooks/useGetLegalDocsData";
import { PRIVACY_NOTICE_URL } from "components/OpenAccount/flows/desktop/CommonForm";

const getFooterLinks = (
  location: Location | null,
  legalDocsData: LegalDocsData,
  variant: SupplierVariant
) => [
  [
    "Contact",
    "https://intercom.help/MettleFAQs/en/articles/3206000-how-do-i-access-support"
  ],
  [
    "Terms & Conditions",
    getUriForDocType(
      location,
      legalDocsData,
      variant === SupplierVariant.Genie
        ? "mettle-natwest-app-terms-and-conditions"
        : "terms-and-conditions"
    )
  ],
  ["Privacy Notice", PRIVACY_NOTICE_URL],
  ["Frequently Asked Questions", "https://faqs.mettle.co.uk/en/"],
  [
    "Third Party API Portal",
    "https://docs.openbanking.prd-mettle.co.uk/registering"
  ],
  [
    "Responsible Disclosure",
    getUriForDocType(location, legalDocsData, "responsible-disclosure")
  ],
  ["Careers", "https://jobs.mettle.co.uk/"]
];

const preventDefaultHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
  e.preventDefault();
  if (window.OneTrust) {
    window.OneTrust.ToggleInfoDisplay();
  }
};

interface FooterLinksPanelProps {
  variant: SupplierVariant;
}

export const FooterLinksPanel: FC<FooterLinksPanelProps> = ({ variant }) => {
  const legalDocsData = useGetLegalDocsData();
  const isClient = useIsClient();
  const footerLinks = getFooterLinks(
    isClient ? window.location : null,
    legalDocsData,
    variant
  );

  return (
    <Panel
      colour={Colour.black}
      className={styles.footerLinksPanel}
      layout={PanelLayout.full}
      short
    >
      <PanelItem className={styles.panelContent}>
        <ul className={styles.footerLinks}>
          {footerLinks.map(([text, url]) => (
            <li key={text}>
              <Link url={url}>{text}</Link>
            </li>
          ))}
          <li>
            <a
              onClick={preventDefaultHandler}
              id="ot-sdk-btn"
              className={`${styles.oneTrustLink} optanon-show-settings`}
            >
              Cookie preferences
            </a>
          </li>
        </ul>
      </PanelItem>
    </Panel>
  );
};
