import React, { FC, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { Modal } from "components/Modal";
import { useDeviceOs, useDeviceSize } from "@mettle/hooks";
import { OnboardingDesktop } from "./flows";
import cx from "classnames";
import styles from "./modal.module.scss";
import { useOnboardingType } from "hooks";
import { EmailFormValues } from "./flows/desktop/EmailForm";

interface Props {
  onClose: () => void;
}

export const Onboarding: FC<Props> = ({ onClose }) => {
  const onboardingType = useOnboardingType();
  const [submittedValues, setSubmitted] = useState<EmailFormValues | null>(
    null
  );
  const modalClassName = cx(styles[`${onboardingType}Modal`], {
    [styles.hasSubmitted]: !isEmpty(submittedValues)
  });
  const os = useDeviceOs();
  const size = useDeviceSize();

  return (
    <Modal
      id={`onboarding-${onboardingType}`}
      onClose={onClose}
      className={modalClassName}
    >
      <OnboardingDesktop
        submittedValues={submittedValues}
        onSubmitted={setSubmitted}
        onClose={onClose}
        os={os}
        size={size}
      />
    </Modal>
  );
};
Onboarding.displayName = "Onboarding";
