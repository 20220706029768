import React, { FC } from "react";
import {
  CmsFourCardPanelCard,
  FourCardPanelCard
} from "components/FourCardPanel/FourCardPanelCard";
import { graphql } from "gatsby";
import { Panel, PanelItem, PanelLayout } from "../Panel";
import { Colour, SectionPaddingDefault } from "../constants";
import styles from "./styles.module.scss";
import { chunk } from "lodash";
import cx from "classnames";
import { useIsBlogPage } from "hooks/useIsBlogPage";
import { CmsPageSectionBase } from "../CardPanel";

export interface CmsFourCardPanelSection extends CmsPageSectionBase {
  cards: CmsFourCardPanelCard[];
  backgroundColour: Colour;
  title?: string;
}

export const FourCardPanel: FC<CmsFourCardPanelSection> = ({
  cards,
  backgroundColour,
  title,
  sectionPaddingBottom,
  sectionPaddingTop
}) => {
  const isBlog = useIsBlogPage();

  return (
    <Panel
      colour={Colour[backgroundColour]}
      layout={PanelLayout.full}
      className={cx(
        styles.fourCardPanel,
        styles[sectionPaddingTop || SectionPaddingDefault.TOP],
        styles[sectionPaddingBottom || SectionPaddingDefault.BOTTOM],
        { [styles.blog]: isBlog }
      )}
      short
    >
      <PanelItem className={cx({ [styles.blogItem]: isBlog })}>
        {title && <h4 className={styles.title}>{title}</h4>}
        <div className={cx(styles.cardsContainer, { [styles.blog]: isBlog })}>
          {chunk(cards, 2).map((cardChunk, index) => {
            return (
              <div
                key={index}
                className={cx(styles.cardSecondaryContain, {
                  [styles.blog]: isBlog
                })}
              >
                {cardChunk.map(card => {
                  return <FourCardPanelCard key={card.id} {...card} />;
                })}
              </div>
            );
          })}
        </div>
      </PanelItem>
    </Panel>
  );
};

FourCardPanel.displayName = "FourCardPanel";

export const ContentfulFourCardPanelFragment = graphql`
  fragment ContentfulFourCardPanelFragment on ContentfulFourCardPanel {
    __typename
    contentful_id
    backgroundColour
    title
    sectionPaddingTop
    sectionPaddingBottom
    cards {
      id
      subtitle
      iconImage {
        title
        description
        file {
          url
        }
      }
      title
      backgroundColour
      richText {
        raw
      }
      backgroundImage {
        gatsbyImageData(placeholder: BLURRED)
        title
      }
    }
  }
`;
