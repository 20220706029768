import React, { FC } from "react";
import styles from "./styles.module.scss";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { CmsResponsiveImage } from "../Blog";

export interface CardBackgroundImageProps {
  image: CmsResponsiveImage;
}

export const CardBackgroundImage: FC<CardBackgroundImageProps> = ({
  image
}) => {
  return (
    <div className={styles.bgImageContainer}>
      <GatsbyImage
        className={styles.bgImage}
        imgStyle={{
          objectFit: "cover"
        }}
        image={getImage(image.gatsbyImageData) as IGatsbyImageData}
        alt={image.title}
      />
    </div>
  );
};
