import React, { FC } from "react";
import cx from "classnames";
import styles from "./styles.module.scss";

interface Props {
  prefix?: string;
  error?: Error;
  className?: string;
}
export const ErrorMessage: FC<Props> = ({ error, prefix, className = "" }) => {
  if (!error) return null;

  return (
    <div className={cx(styles.errorMessage, className)}>
      {prefix ? `${prefix} ${error.message}` : `${error.message}`}
    </div>
  );
};
ErrorMessage.displayName = "ErrorMessage";
