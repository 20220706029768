import React, { FC } from "react";
import cx from "classnames";
import styles from "./styles.module.scss";

interface Props {
  title: string;
  firstParagraph: string;
  secondParagraph?: string;
  quote?: string;
  className?: string;
}

export const Description: FC<Props> = ({
  title,
  firstParagraph,
  secondParagraph,
  quote,
  className
}) => {
  return (
    <div
      className={cx(styles.description, { [className!]: Boolean(className) })}
    >
      <h2>{title}</h2>

      {quote && <span className={styles.quote}>{quote}</span>}
      <div className={styles.paragraph}>
        <p className={cx({ [styles.noMargin]: !Boolean(secondParagraph) })}>
          {firstParagraph}
        </p>

        {secondParagraph && (
          <p className={cx({ [styles.noMargin]: Boolean(secondParagraph) })}>
            {secondParagraph}
          </p>
        )}
      </div>
    </div>
  );
};
