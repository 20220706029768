import {
  CalculatorSection,
  CmsCalculatorSection
} from "../../CalculatorSection";
import {
  CmsComparisonTableSection,
  ComparisonTableSection
} from "../../ComparisonTableSection";
import { CmsInfoAccordionSection, InfoAccordion } from "../../InfoAccordion";
import { CmsPageHeroSection, PageHero } from "../../PageHero";
import { Panel, PanelItem, PanelLayout } from "../../Panel";
import { CmsQuoteCard, QuoteCard } from "../../QuoteCard";
import { Colour } from "../../constants";
import {
  BlogPostRichTextSection,
  CmsBlogPostRichTextSection
} from "../BlogHome/BlogPostRichTextSection";
import { YouMayAlsoLike } from "../YouMayAlsoLike";
import { CmsBlogPost } from "../types";
import { BlogPostAuthor } from "./Author";
import styles from "./BlogPost.module.scss";
import { BlogPostHero } from "./BlogPostHero";
import { ScrollProgressBar } from "./ScrollProgressBar";
import { TableOfContents } from "./TableOfContents/TableOfContents";
import { TocDropdown } from "./TableOfContents/TocDropdown";
import { VideoAsset } from "./VideoAsset";
import { useIsClient } from "@mettle/hooks";
import cx from "classnames";
import { ArticleHero } from "components/ArticleHero";
import { BreadCrumbs } from "components/BreadCrumbs";
import { CardPanel, CmsCardPanelSection } from "components/CardPanel";
import {
  CmsBannersSectionType,
  CmsBlogArticleSectionType
} from "components/FeaturesPage";
import {
  CmsFourCardPanelSection,
  FourCardPanel
} from "components/FourCardPanel";
import {
  CmsTrustPilotSection,
  TrustPilotSection
} from "components/HomePagePanel/TrustPilot";
import { formatDate } from "date-fns/format";
import { useGenerateTocHeadings } from "hooks/useGenerateTocHeadings";
import React, { FC } from "react";

export type CmsBannerSection = CmsTrustPilotSection | CmsInfoAccordionSection;

export const BannerSections: Record<
  CmsBannersSectionType,
  FC<CmsBannerSection>
> = {
  [CmsBannersSectionType.TrustPilot]: TrustPilotSection,
  [CmsBannersSectionType.InfoAccordion]: InfoAccordion
};

export type CmsBlogArticleSection =
  | CmsBlogPostRichTextSection
  | CmsCardPanelSection
  | CmsFourCardPanelSection
  | CmsInfoAccordionSection
  | CmsComparisonTableSection
  | CmsQuoteCard
  | CmsCalculatorSection
  | CmsPageHeroSection;

export const ArticleSections: Record<
  CmsBlogArticleSectionType,
  FC<CmsBlogArticleSection>
> = {
  [CmsBlogArticleSectionType.BlogPostRichTextSection]: BlogPostRichTextSection,
  [CmsBlogArticleSectionType.CardPanel]: CardPanel,
  [CmsBlogArticleSectionType.FourCardPanel]: FourCardPanel,
  [CmsBlogArticleSectionType.InfoAccordion]: InfoAccordion,
  [CmsBlogArticleSectionType.ComparisonTableSection]: ComparisonTableSection,
  [CmsBlogArticleSectionType.QuoteCard]: QuoteCard,
  [CmsBlogArticleSectionType.CalculatorSection]: CalculatorSection,
  [CmsBlogArticleSectionType.PageHero]: PageHero
};

export const BlogPost: FC<CmsBlogPost> = ({
  title,
  subTitle,
  sections,
  heroImage,
  heroVideo,
  categories,
  author,
  secondaryAuthor,
  secondaryBylineText,
  createdAt,
  publishedDate,
  readingTime,
  youMayAlsoLike,
  slug,
  bottomBanners,
  hasTableOfContents,
  isPrivacyPage
}) => {
  const isClient = useIsClient();
  const richTextSections = () =>
    sections.filter(
      section =>
        section.__typename === CmsBlogArticleSectionType.BlogPostRichTextSection
    ) as CmsBlogPostRichTextSection[];

  return (
    <div itemScope itemProp="https://schema.org/BlogPosting">
      {!isPrivacyPage && (
        <BreadCrumbs category={categories[0]} post={{ title, slug }} />
      )}
      {!isPrivacyPage && (
        <ArticleHero
          categories={categories}
          title={title}
          subtitle={subTitle}
          readingTime={readingTime}
          createdAt={createdAt}
          slug={slug}
          publishedDate={publishedDate}
          author={author}
          secondaryAuthor={secondaryAuthor}
          secondaryBylineText={secondaryBylineText}
        />
      )}
      {heroVideo && (
        <Panel colour={Colour.warmStone} layout={PanelLayout.full} short>
          <PanelItem>
            <VideoAsset
              autoHeight
              poster={heroImage?.file?.url}
              url={heroVideo.file.url}
              fileName={heroVideo.file.fileName}
              videoType={heroVideo.description}
              contentType={heroVideo.file.contentType}
              className={cx(styles.heroVideo)}
            />
          </PanelItem>
        </Panel>
      )}
      {hasTableOfContents && (
        <Panel
          datatest-id="toc"
          layout={PanelLayout.full}
          colour={Colour.warmStone}
          className={cx(styles.tocDropdownContainer, {
            [styles.privacy]: isPrivacyPage
          })}
          short
        >
          <PanelItem>
            <TocDropdown
              headings={useGenerateTocHeadings(richTextSections())}
            />
          </PanelItem>
        </Panel>
      )}
      <Panel layout={PanelLayout.full} colour={Colour.warmStone} short>
        <PanelItem
          className={cx(styles.blogPost, {
            [styles.hasToc]: hasTableOfContents
          })}
        >
          <div className={styles.container}>
            <article
              className={cx(styles.article, {
                [styles.privacy]: isPrivacyPage
              })}
            >
              <h2 itemProp="abstract">{subTitle}</h2>
              {isPrivacyPage && (
                <p>
                  {formatDate(
                    new Date(publishedDate || createdAt),
                    "d MMMM yyyy"
                  )}
                </p>
              )}
              {heroImage && !heroVideo && !hasTableOfContents && (
                <BlogPostHero heroImage={heroImage} className={styles.hero} />
              )}
              <section itemProp="articleBody">
                {sections.map((section, index) => {
                  const Component = ArticleSections[section.__typename];
                  return Component ? (
                    <Component key={index} {...section} blog={true} />
                  ) : null;
                })}
              </section>
              {!isPrivacyPage && <BlogPostAuthor {...author} />}
            </article>
            {hasTableOfContents && (
              <div data-testid="toc-container" className={styles.tocContainer}>
                <TableOfContents sections={richTextSections()} />
              </div>
            )}
          </div>
        </PanelItem>
      </Panel>
      <YouMayAlsoLike posts={youMayAlsoLike} />
      {bottomBanners &&
        bottomBanners.map((section, index) => {
          const Component = BannerSections[section.__typename];
          return Component ? <Component key={index} {...section} /> : null;
        })}

      {isClient && <ScrollProgressBar />}
    </div>
  );
};

BlogPost.displayName = "BlogPost";
