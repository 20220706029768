import React, { FC, ReactElement, useEffect, useRef, useState } from "react";
import { CmsPageSectionBase } from "../CardPanel";
import { BodySize, Colour, SectionPaddingDefault } from "../constants";
import { Panel, PanelItem, PanelLayout } from "components/Panel";
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData
} from "gatsby-source-contentful/rich-text";
import { Link } from "../Link";
import { graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
  BLOCKS,
  Hyperlink,
  INLINES,
  Table,
  TableCell,
  TableRow
} from "@contentful/rich-text-types";
import InlineSVG from "react-inlinesvg";
import tick from "assets/icons/tick-outlined.svg";
import cross from "assets/icons/cross-outlined.svg";
import styles from "./styles.module.scss";
import cx from "classnames";
import { PanelTitles } from "../PanelTitles";
import { useIsBlogPage } from "../../hooks/useIsBlogPage";
import { IconographyButton } from "../IconographyButton";
import arrowLeft from "../../assets/icons/arrow-left-white.svg";
import arrowRight from "../../assets/icons/arrow-right-white.svg";
import throttle from "lodash/throttle";

export interface CmsComparisonTableSection extends CmsPageSectionBase {
  title?: string;
  subtitle?: string;
  ctaText?: string;
  ctaUrl?: string;
  backgroundColour?: Colour;
  tableHeaderColour?: Colour;
  darkenSecondColumn: boolean;
  lockSecondColumn: boolean;
  tintFirstColumn: boolean;
  tableData: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  reducedWidth?: boolean;
}

export const ComparisonTableSection: FC<CmsComparisonTableSection> = ({
  backgroundColour = Colour.warmStone,
  tableHeaderColour = Colour.olive20,
  title,
  subtitle,
  ctaText,
  ctaUrl,
  tableData,
  sectionPaddingTop,
  sectionPaddingBottom,
  darkenSecondColumn,
  lockSecondColumn,
  tintFirstColumn,
  reducedWidth
}) => {
  const MAX_DESKTOP_COLUMNS = 5;
  const MAX_TABLET_COLUMNS = 4;
  const MAX_MOBILE_COLUMNS = 3;
  const tableRef = useRef<null | HTMLDivElement>(null);
  const isBlog = useIsBlogPage();
  const options = {
    renderNode: {
      [BLOCKS.TABLE]: (_: Table, children: ReactElement[]) => (
        <table>
          <tbody>{children}</tbody>
        </table>
      ),
      [BLOCKS.TABLE_ROW]: (_: TableRow, children: ReactElement[]) => (
        <tr>{children}</tr>
      ),
      [BLOCKS.TABLE_CELL]: (_: TableCell, children: ReactElement[]) => (
        <td>{children}</td>
      ),
      [BLOCKS.TABLE_HEADER_CELL]: (_: TableCell, children: ReactElement[]) => (
        <th>{children}</th>
      ),
      [INLINES.HYPERLINK]: (
        { data: { uri } }: Hyperlink,
        children: React.ReactElement
      ) => {
        return (
          <Link secondary url={uri}>
            {children}
          </Link>
        );
      }
    },
    renderText: (text: string) => {
      if (text && /:tick:/.test(text)) {
        return <InlineSVG description="checkmark" src={tick} />;
      } else if (text && /:cross:/.test(text)) {
        return <InlineSVG description="cross" src={cross} />;
      } else {
        return text;
      }
    }
  };
  const [tableState, setTableState] = useState({
    maxDisplayColumns: 0,
    windowWidth: 0,
    position: 0,
    maxPosition: 0,
    rowLength: 0,
    displayControls: false
  });

  const getMaxDisplayColumns = () => {
    const width = window.innerWidth;

    if (width > BodySize.maxTablet) {
      return MAX_DESKTOP_COLUMNS;
    } else if (width <= BodySize.maxTablet && width > BodySize.maxMobile) {
      return MAX_TABLET_COLUMNS;
    } else {
      return MAX_MOBILE_COLUMNS;
    }
  };

  const getRowLength = () => {
    const el = tableRef.current;
    const table = el?.querySelector("table");
    const rows = table?.querySelectorAll("tr");
    let rowLength = 0;

    if (rows) {
      rowLength = rows[0].querySelectorAll("th, td").length;
    }

    return rowLength;
  };

  const getMaxPosition = () => {
    const maxDisplayColumns = getMaxDisplayColumns();

    return getRowLength() - maxDisplayColumns;
  };

  const shouldDisplayControls = (rowLength: number) => {
    const width = window.innerWidth;

    if (width > BodySize.maxTablet) {
      return rowLength > MAX_DESKTOP_COLUMNS;
    } else if (width <= BodySize.maxTablet && width > BodySize.maxMobile) {
      return rowLength > MAX_TABLET_COLUMNS;
    } else {
      return rowLength > MAX_MOBILE_COLUMNS;
    }
  };

  const configureForWidth = () => {
    if (window.innerWidth !== tableState.windowWidth) {
      const rowLength = getRowLength();

      setTableState(prev => {
        return {
          ...prev,
          position: 0,
          windowWidth: window.innerWidth,
          maxDisplayColumns: getMaxDisplayColumns(),
          maxPosition: getMaxPosition(),
          rowLength: rowLength,
          displayControls: shouldDisplayControls(rowLength)
        };
      });
    }
  };

  const updateTableDisplay = () => {
    const frozenColumns = lockSecondColumn ? 2 : 1;
    const position = tableState.position;

    if (tableRef.current) {
      const el = tableRef.current;
      const table = el?.querySelector("table");
      const rows = table?.querySelectorAll("tr");

      rows?.forEach(row => {
        const cells = row.querySelectorAll("td ,th");

        cells?.forEach((cell, index) => {
          if (
            (index >= frozenColumns && index - position < frozenColumns) ||
            index - position > tableState.maxDisplayColumns - 1
          ) {
            cell.classList.add(styles.hidden);
            cell.classList.remove(styles.show);
          } else {
            cell.classList.add(styles.show);
            cell.classList.remove(styles.hidden);
          }

          if (
            index - position === tableState.maxDisplayColumns - 1 ||
            index === tableState.rowLength - 1
          ) {
            cell.classList.add(styles.last);
          } else {
            cell.classList.remove(styles.last);
          }
        });
      });
    }
  };

  useEffect(() => {
    const callback = throttle(configureForWidth, 100);

    window.addEventListener("resize", callback);

    return () => window.removeEventListener("resize", callback);
  }, [tableState.windowWidth]);

  useEffect(configureForWidth, []);
  useEffect(updateTableDisplay, [
    tableState.windowWidth,
    tableState.position,
    tableState.maxDisplayColumns,
    tableState.rowLength
  ]);

  return (
    <Panel
      className={styles.comparisonTable}
      colour={Colour[backgroundColour]}
      layout={PanelLayout.full}
      short
    >
      <PanelItem
        className={cx(
          styles.item,
          styles[sectionPaddingTop || SectionPaddingDefault.TOP],
          styles[sectionPaddingBottom || SectionPaddingDefault.BOTTOM],
          { [styles.isBlog]: Boolean(isBlog) }
        )}
      >
        <PanelTitles
          title={title}
          subtitle={subtitle}
          ctaText={ctaText}
          ctaUrl={ctaUrl}
          className={tableState.displayControls ? styles.noBottomMargin : ""}
        />
        <div
          className={cx(styles.content, {
            [styles.reducedWidth]: reducedWidth
          })}
        >
          {tableState.displayControls ? (
            <div className={cx(styles.controls, styles[backgroundColour])}>
              <IconographyButton
                value="Back"
                hollow
                icon={arrowLeft}
                handler={() =>
                  setTableState(prev => {
                    return { ...prev, position: prev.position - 1 };
                  })
                }
                disabled={tableState.position === 0}
              />
              <IconographyButton
                value="Forward"
                icon={arrowRight}
                handler={() =>
                  setTableState(prev => {
                    return { ...prev, position: prev.position + 1 };
                  })
                }
                disabled={tableState.position === tableState.maxPosition}
              />
            </div>
          ) : (
            <div className={cx(styles.spacer, styles[backgroundColour])} />
          )}
          <div
            data-testid="table-container"
            className={cx(styles.tableContainer, {
              [styles.hasControls]: tableState.displayControls,
              [styles.darkSecondColumn]: darkenSecondColumn,
              [styles.tintFirstColumn]: tintFirstColumn,
              [styles.olive20]: tableHeaderColour === Colour.olive20,
              [styles.teal20]: tableHeaderColour === Colour.teal20
            })}
          >
            {tableData.raw && (
              <div ref={tableRef}>
                {documentToReactComponents(JSON.parse(tableData.raw), options)}
              </div>
            )}
          </div>
        </div>
      </PanelItem>
    </Panel>
  );
};

ComparisonTableSection.displayName = "ComparisonTableSection";

export const ContentfulComparisonTableFragment = graphql`
  fragment ContentfulComparisonTableSectionFragment on ContentfulComparisonTableSection {
    __typename
    contentful_id
    title
    subtitle
    ctaText
    ctaUrl
    backgroundColour
    tableHeaderColour
    darkenSecondColumn
    lockSecondColumn
    tintFirstColumn
    tableData {
      raw
    }
    sectionPaddingTop
    sectionPaddingBottom
    reducedWidth
  }
`;
