import { CmsBlogPost } from "../types";
import { compareDesc } from "date-fns/compareDesc";
import { useMemo } from "react";

export const useSortPosts = (posts: CmsBlogPost[]) =>
  useMemo(
    () =>
      posts.sort((firstPost, secondPost) => {
        return compareDesc(
          new Date(firstPost.publishedDate || firstPost.createdAt),
          new Date(secondPost.publishedDate || secondPost.createdAt)
        );
      }),
    [posts]
  );
