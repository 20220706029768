import testQr from "components/OpenAccount/flows/desktop/QRCodeForm/staticQrCodes/TEST.svg";
import prodQr from "components/OpenAccount/flows/desktop/QRCodeForm/staticQrCodes/PROD.svg";
import savingsQr from "components/OpenAccount/flows/desktop/QRCodeForm/staticQrCodes/SAVINGS.jpeg";
import useIsPotsPage from "./useIsPotsPage";

export const useStaticQrCode = (isTest: boolean) => {
  const isPotsPage = useIsPotsPage();

  if (isPotsPage) {
    return savingsQr;
  }

  return isTest ? testQr : prodQr;
};
