export enum Colour {
  // Updated palette
  warmStone = "warmStone",
  white = "white",
  black = "black",
  softBlack = "softBlack",
  fuschiaPunch = "fuschiaPunch",
  cyan = "cyan",
  limeSorbet = "limeSorbet",
  teal20 = "teal20",
  teal100 = "teal100",
  olive20 = "olive20",
  olive100 = "olive100",
  alertYellow = "alertYellow",
  black90 = "black90",
  darkStone = "darkStone",

  // TODO old: will probably go away
  lightGrey = "lightGrey",
  lightestGrey = "lightestGrey",
  darkGrey = "darkGrey",
  pink = "pink",
  primary = "primary",
  secondary = "secondary"
}
