import React, { FC, useEffect, useState } from "react";
import { CmsPage, ComposedPage } from "components/ComposedPage";
import { graphql } from "gatsby";
import { Panel, PanelItem, PanelLayout } from "components/Panel";
import { CmsBlogPost } from "components/Blog";
import { useLocationPage, useSearchedEvent } from "hooks";
import { useSearch } from "hooks/useSearch";
import { Colour } from "components/constants";
import { BlogHomeSection } from "components/Blog/BlogHome/BlogHomeSection";
import styles from "./styles.module.scss";
import { useIsClient } from "@mettle/hooks";

export interface ContentHubSearchPageProps {
  data: {
    page: CmsPage;
    posts: { nodes: CmsBlogPost[] };
  };
}

const OPTIONS = {
  minLength: 2,
  keys: ["title", "subTitle", "keywords"]
};

export const ContentHubSearch: FC<ContentHubSearchPageProps> = ({
  data: { page, posts }
}) => {
  const [searchTerm, setSearch] = useState("");
  const { find, results } = useSearch(posts.nodes, OPTIONS);
  const currentPage = useLocationPage();
  const pushSearchedEvent = useSearchedEvent(currentPage);
  const isClient = useIsClient();

  useEffect(() => {
    if (isClient) {
      const paramTerm = decodeURI(window.location.search.split("?q=")[1]);

      setSearch(paramTerm === "undefined" ? "" : paramTerm);
    }
  }, [isClient]);

  useEffect(() => {
    if (
      searchTerm &&
      searchTerm.length >= OPTIONS.minLength &&
      searchTerm !== "undefined"
    )
      find(searchTerm);
  }, [searchTerm, find]);

  useEffect(() => {
    if (results.status !== "skipped") {
      const resultsCount =
        results.status === "found" ? results.items.length : 0;
      pushSearchedEvent(results.searchTerm, resultsCount);
    }
  }, [results, pushSearchedEvent]);

  return (
    <ComposedPage
      title={page.title}
      metaTitle={page.metaTitle}
      metaDescription={page.metaDescription}
      sections={page.sections}
      slug={page.slug}
      aboveFoldExperimentation={page.aboveFoldExperimentation}
    >
      <Panel
        colour={Colour.warmStone}
        className={styles.searchResults}
        short
        layout={PanelLayout.full}
      >
        <PanelItem>
          {results?.status === "not-found" && (
            <h5 className={styles.noResults}>
              We couldn’t find any results for “{results.searchTerm}”. Why not
              take a look at some of our latest articles?
            </h5>
          )}

          {!searchTerm && (
            <h5 className={styles.noResults}>
              Sorry, we couldn't find anything. Why not take a look at some of
              our latest articles?
            </h5>
          )}

          {results?.status === "found" && (
            <>
              <h5 className={styles.searchTerm}>Results for "{searchTerm}"</h5>
              <BlogHomeSection posts={results.items} showSubTitle={false} />
            </>
          )}
        </PanelItem>
      </Panel>
    </ComposedPage>
  );
};

export default ContentHubSearch;

export const pageQuery = graphql`
  query contentHubSearchPage {
    page: contentfulContentHubSearchPage {
      id
      metaDescription
      metaTitle
      aboveFoldExperimentation
      title
      sections {
        __typename
        ...ContentfulPageHeroFragment
        ...ContentfulContentHubNavBarFragment
      }
    }
    posts: allContentfulBlogPost(
      filter: {
        slug: {
          nin: [
            "test-article"
            "test-video-blog"
            "test-article-do-not-remove"
            "privacy-notice-pps"
            "privacy-notice-genie"
          ]
        }
      }
    ) {
      nodes {
        ...ContentfulBlogPost
      }
    }
  }
`;
