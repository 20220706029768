import { useCallback, useEffect, useMemo, useState } from "react";

import _debounce from "lodash/debounce";
import { inViewport } from "./utils";

type Settings = {
  fudgeFactor?: number;
  debounceDelay?: number;
};

const defaultSettings = {
  fudgeFactor: 0.7,
  debounceDelay: 1000
};

export const useScrolledIntoView = (
  element: HTMLElement | null,
  settings?: Settings
) => {
  const [displayed, setDisplayed] = useState(false);
  const { fudgeFactor, debounceDelay } = useMemo(
    () => ({
      fudgeFactor: settings?.fudgeFactor || defaultSettings.fudgeFactor,
      debounceDelay: settings?.debounceDelay || defaultSettings.debounceDelay
    }),
    [settings]
  );

  useEffect(() => {
    if (element) {
      document.addEventListener("scroll", inspect);
    }
    return () => document.removeEventListener("scroll", inspect);
  }, [element]);

  const inspect = useCallback(
    _debounce(() => {
      if (inViewport(element, fudgeFactor)) {
        setDisplayed(true);
        document.removeEventListener("scroll", inspect);
      }
    }, debounceDelay),
    [element, fudgeFactor, setDisplayed, debounceDelay]
  );

  return { displayed };
};
