// TODO - potentially no longer needed

import React, { FC } from "react";
import { SocialLinksPanel } from "./SocialLinksPanel";
import { FooterLinksPanel } from "./FooterLinksPanel";
import { SmallPrintPanel } from "./SmallPrintPanel";
import { NatwestPanel } from "./NatwestPanel";
import { SupplierVariant } from "./types";

interface FooterProps {
  variant?: SupplierVariant;
}

export const Footer: FC<FooterProps> = ({ variant = SupplierVariant.PPS }) => (
  <>
    <SocialLinksPanel />
    <FooterLinksPanel variant={variant} />
    <SmallPrintPanel variant={variant} />
    <NatwestPanel />
  </>
);
Footer.displayName = "Footer";
