export enum CmsSectionAlignment {
  LEFT = "left",
  RIGHT = "right",
  CENTRE = "centre"
}

export enum CmsSectionColour {
  BLACK = "black",
  WHITE = "white",
  GREY = "grey",
  PINK = "pink"
}

export enum CmsBannersSectionType {
  TrustPilot = "ContentfulTrustPilotSection",
  InfoAccordion = "ContentfulInfoAccordion"
}

export enum CmsBlogArticleSectionType {
  BlogPostRichTextSection = "ContentfulBlogPostRichTextSection",
  CardPanel = "ContentfulCardPanel",
  FourCardPanel = "ContentfulFourCardPanel",
  InfoAccordion = "ContentfulInfoAccordion",
  ComparisonTableSection = "ContentfulComparisonTableSection",
  QuoteCard = "ContentfulQuoteCard",
  CalculatorSection = "ContentfulCalculatorSection",
  PageHero = "ContentfulPageHero"
}
