import React, { FC } from "react";
import { graphql } from "gatsby";
import { BlogPostNative, CmsBlogPost } from "components/Blog";
import { DefaultLayout } from "layouts";
import { Colour } from "components/constants";

export const NativeBlogPostTemplate: FC<{ data: { post: CmsBlogPost } }> = ({
  data: { post }
}) => {
  return (
    <DefaultLayout
      title={post.metaTitle || `${post.title} | Mettle blog`}
      description={post.metaDescription}
      background={Colour.white}
      image={post.heroImage}
      footer={false}
      header={false}
    >
      <BlogPostNative {...post} />
    </DefaultLayout>
  );
};

export default NativeBlogPostTemplate;

export const pageQuery = graphql`
  query getNativeBlogPost($id: String!) {
    post: contentfulBlogPost(id: { eq: $id }) {
      slug
      title
      metaTitle
      metaDescription
      subTitle
      publishedDate
      createdAt
      readingTime
      heroImage {
        gatsbyImageData(placeholder: BLURRED, width: 1200)
        title
        description
        file {
          url
        }
      }
      heroVideo {
        title
        description
        file {
          url
          fileName
          contentType
          details {
            size
          }
        }
      }
      sections {
        ...ContentfulCardPanelFragment
        ...ContentfulFourCardPanelFragment
        ...ContentfulBlogPostRichTextSectionFragment
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            description
            file {
              url
              fileName
              contentType
              details {
                image {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`;
