import { useLocationPage } from "./useLocationPage";

export const useIsPrivacyPage = () => {
  const page = useLocationPage();

  return (
    page.includes("/privacy-notice/e-money") ||
    page.includes("/privacy-notice/bank-account")
  );
};
