import { CmsBlogPostRichTextSection } from "../components/Blog/BlogHome/BlogPostRichTextSection";
import { BLOCKS, Document, Text } from "@contentful/rich-text-types";

export const useGenerateTocHeadings = (
  sections: CmsBlogPostRichTextSection[]
) => {
  const docs = sections.map(
    section => JSON.parse(section?.body?.raw || "") as Document
  );
  const headings: string[] = [];

  docs.forEach(doc => {
    doc.content
      .filter(node => node.nodeType === BLOCKS.HEADING_3)
      .forEach(heading => {
        headings.push((heading.content[0] as Text).value);
      });
  });

  return headings;
};
