import React, { FC } from "react";
import { Colour } from "../constants";
import { Panel, PanelItem, PanelLayout } from "components/Panel";
import { graphql } from "gatsby";
import styles from "./styles.module.scss";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { CmsPageSectionBase } from "../CardPanel";
import { CmsResponsiveImage } from "../Blog";

export interface CmsCenteredImagePanelSection extends CmsPageSectionBase {
  backgroundColour: Colour;
  image: CmsResponsiveImage;
}

export const CenteredImagePanel: FC<CmsCenteredImagePanelSection> = ({
  backgroundColour,
  image
}) => {
  return (
    <Panel
      layout={PanelLayout.full}
      colour={Colour[backgroundColour]}
      className={styles.centeredImagePanel}
      short
    >
      <PanelItem className={styles.item}>
        <div className={styles.imageContainer}>
          <div className={styles.image}>
            <GatsbyImage
              imgStyle={{
                objectFit: "contain"
              }}
              alt={image.title}
              image={getImage(image.gatsbyImageData) as IGatsbyImageData}
            />
          </div>
        </div>
      </PanelItem>
    </Panel>
  );
};

export const ContentfulCenteredImagePanelFragment = graphql`
  fragment ContentfulCenteredImagePanelFragment on ContentfulCenteredImagePanel {
    __typename
    contentful_id
    backgroundColour
    image {
      gatsbyImageData(placeholder: BLURRED)
      title
    }
  }
`;
