import React from "react";
import cx from "classnames";
import { useEventListener, useIsClient } from "@mettle/hooks";
import { Colour } from "components/constants";
import { OpenAccountButton } from "components/OpenAccount/OpenAccountButton";
import { DesktopMenu } from "./DesktopMenu";
import { MobileMenu, MobileMenuIcon } from "./MobileMenu";
import styles from "./styles.module.scss";
import { LogInButton } from "components/LogIn/LogInButton";
import { useStaticQuery, graphql } from "gatsby";

const MAX_MOBILE_SIZE = 990;

interface Props {
  className?: string;
}

export const Navigation: React.FC<Props> = ({ className = "" }) => {
  const isClient = useIsClient();
  const [isMenuVisible, setMenuVisibility] = React.useState<boolean>(false);
  const [isMenuClosing, setMenuClosing] = React.useState<boolean>(true);
  const data = useStaticQuery(graphql`
    {
      contentfulSiteHeader {
        showLoginButton
        loginButtonText
        showOpenAccountButton
        openAccountButtonText
      }
    }
  `);

  useEventListener(
    typeof window !== "undefined" ? window : null,
    "resize",
    () => {
      if (isMenuVisible && window.outerWidth > MAX_MOBILE_SIZE) {
        setMenuVisibility(false);
        setMenuClosing(true);
      }
    }
  );

  const toggleMenu = React.useCallback(() => {
    setMenuClosing(!isMenuClosing);
    setTimeout(() => setMenuVisibility(!isMenuVisible), 0);
  }, [isMenuClosing, isMenuVisible]);

  const hideMenu = React.useCallback(() => {
    setMenuClosing(true);
    setTimeout(() => setMenuVisibility(false), 0);
  }, []);

  return (
    <section className={className}>
      <DesktopMenu className={styles.desktopMenu} />
      {!isMenuVisible && (
        <>
          {data.contentfulSiteHeader.showOpenAccountButton && (
            <OpenAccountButton
              smallText={true}
              id="header"
              colour={Colour.fuschiaPunch}
              text={data.contentfulSiteHeader.openAccountButtonText}
              className={cx(styles.headerButton)}
            />
          )}
          {data.contentfulSiteHeader.showLoginButton && (
            <LogInButton
              smallText={true}
              id="log-in"
              colour={Colour.fuschiaPunch}
              text={data.contentfulSiteHeader.loginButtonText}
              className={cx(styles.headerButton, styles.logInButton)}
            />
          )}
        </>
      )}
      <MobileMenuIcon
        className={cx(styles.mobMenuIcon, {
          [styles.mobMenuOpen]: !isMenuVisible
        })}
        isOpen={isMenuVisible}
        toggle={toggleMenu}
      />
      {isClient && (isMenuVisible || isMenuClosing) && (
        <div data-testid="mobile-menu">
          <MobileMenu
            isMenuVisible={isMenuVisible}
            isMenuClosing={isMenuClosing}
            hideMenu={hideMenu}
          />
        </div>
      )}
    </section>
  );
};
