import React, { FC, useRef } from "react";
import { asPortal, useDeviceSize, useEventListener } from "@mettle/hooks";
import {
  VelocityTransitionGroup,
  VelocityTransitionGroupProps
} from "velocity-react";
import cx from "classnames";
import cross from "assets/icons/crossWhite.svg";
import hamburger from "assets/icons/hamburger_white.svg";
import { AnimatedMenuLinks } from "components/Navigation/MenuLinks";
import { useBodySize } from "hooks";
import { isTabletSize } from "lib/device";
import styles from "./styles.module.scss";

export const animationDefaults = {
  duration: 300,
  easing: "ease-in-out"
} as VelocityTransitionGroupProps;

export const animate = (
  animation: string | Record<string, number | string>,
  extra: Partial<VelocityTransitionGroupProps["enter"]> = {}
) =>
  ({
    ...animationDefaults,
    animation,
    ...extra
  } as VelocityTransitionGroupProps["enter"]);

interface MobileMenuProps {
  hideMenu(): void;
  isMenuClosing: boolean;
  isMenuVisible: boolean;
}

export const MobileMenu: FC<MobileMenuProps> = ({
  hideMenu,
  isMenuClosing,
  isMenuVisible
}) => {
  const bodySize = useBodySize();
  const tablet =
    isTabletSize(useDeviceSize()) ||
    (bodySize && bodySize.width >= 720 && bodySize.width <= 1080);

  return asPortal(
    <>
      <VelocityTransitionGroup
        runOnMount
        enter={animate("fadeIn")}
        leave={animate("fadeOut")}
      >
        {tablet && !isMenuClosing && <Overlay hideMenu={hideMenu} />}
      </VelocityTransitionGroup>
      <VelocityTransitionGroup
        runOnMount
        enter={animate(tablet ? { translateX: 0 } : "slideDown")}
        leave={animate(tablet ? { translateX: 368 } : "slideUp")}
      >
        {!isMenuClosing && (
          <MobileMenuLinks
            isMenuClosing={isMenuClosing}
            isMenuVisible={isMenuVisible}
            hideMenu={hideMenu}
          />
        )}
      </VelocityTransitionGroup>
    </>
  );
};
MobileMenu.displayName = "MobileMenu";

export const MobileMenuLinks: FC<MobileMenuProps> = ({
  isMenuClosing,
  hideMenu
}) => {
  const bounceRef = useRef<HTMLDivElement>(null);

  useEventListener(bounceRef.current, "scroll", () => {
    const el = bounceRef.current!;
    const isOverScroll =
      el.scrollTop < 0 || el.scrollTop > el.scrollHeight - el.clientHeight;
    // @ts-ignore
    el.style.webkitOverflowScrolling = isOverScroll ? "auto" : "touch";
  });

  return (
    <section
      data-testid="mobile-menu-links"
      className={styles.mobileMenu}
      ref={bounceRef}
    >
      <div className={styles.wrapper}>
        <MobileMenuIcon isOpen toggle={hideMenu} className={styles.open} />
        <AnimatedMenuLinks
          className={styles.links}
          openClassName={styles.open}
          isMenuClosing={isMenuClosing}
          hideMenu={hideMenu}
        />
      </div>
    </section>
  );
};
MobileMenuLinks.displayName = "MobileMenuLinks";

export const MobileMenuIcon: FC<{
  isOpen: boolean;
  toggle: () => void;
  className?: string;
}> = ({ isOpen, toggle, className }) => (
  <div className={className} onClick={toggle} role="button">
    {isOpen ? (
      <img
        className={cx(styles.menuIcon, styles.closeIcon)}
        src={cross}
        alt="Close Menu"
      />
    ) : (
      <img
        className={cx(styles.menuIcon, styles.openIcon)}
        src={hamburger}
        alt="Open Menu"
      />
    )}
  </div>
);
MobileMenuIcon.displayName = "MobileMenuIcon";

export const Overlay: FC<{ hideMenu(): void }> = ({ hideMenu }) => (
  <div data-testid="overlay" className={styles.overlay} onClick={hideMenu} />
);
