import React, { FC } from "react";
import { CmsAuthor, CmsCategory } from "../Blog";
import { Panel, PanelItem, PanelLayout } from "components/Panel";
import { Colour } from "../constants";
import styles from "./styles.module.scss";
import { Button } from "../Button";
import { BlogPostMetadata } from "../Blog/BlogPost/PostMetadata";
import { ShareButton } from "../ShareButton";
import lowerCase from "lodash/lowerCase";
import { Link } from "../Link";
import { useContributorUrl } from "../Blog/hooks/useContributorUrl";

export interface ArticleHeroProps {
  categories: CmsCategory[];
  publishedDate?: string;
  title: string;
  subtitle: string;
  slug: string;
  readingTime: number;
  createdAt: string;
  author: CmsAuthor;
  secondaryAuthor?: CmsAuthor;
  secondaryBylineText?: string;
}

export const ArticleHero: FC<ArticleHeroProps> = ({
  categories,
  publishedDate,
  title,
  subtitle,
  readingTime,
  createdAt,
  slug,
  author,
  secondaryBylineText,
  secondaryAuthor
}) => {
  const getCategoryUrl = (category: CmsCategory) => `/blog/${category.slug}/`;

  // Even for testing we want to share links from the actual website
  // to get open graph entries in post preview
  const articleLink = `https://www.mettle.co.uk/blog/${slug}/`;

  const authorLink = (author: CmsAuthor) => {
    if (lowerCase(author.name) === "mettle") {
      return <span>{author.name}</span>;
    } else {
      return (
        <span>
          <Link
            secondary
            url={useContributorUrl(author.slug, false)}
            title={author.name}
          >
            {author.name}
          </Link>
          {author.jobTitle ? `, ${author.jobTitle}` : ""}
          {author.company ? `, ${author.company}` : ""}
        </span>
      );
    }
  };

  return (
    <Panel
      layout={PanelLayout.full}
      colour={Colour.warmStone}
      className={styles.articleHero}
      data-testid="articleHero"
      short
    >
      <PanelItem>
        <div className={styles.categoriesContainer}>
          {categories.map((category: CmsCategory) => {
            return (
              <Button
                smallText
                key={category.slug}
                className={styles.articleHeroButton}
                url={getCategoryUrl(category)}
              >
                {category.title}
              </Button>
            );
          })}
          <ShareButton url={articleLink} title={title} subtitle={subtitle} />
        </div>
        <h1 itemProp="headline" className={styles.title}>
          {title}
        </h1>
        <div className={styles.metaContainer}>
          <div className={styles.bylines}>
            {author && (
              <div className={styles.primaryAuthor}>
                Written by: {authorLink(author)}
              </div>
            )}
            {secondaryAuthor && secondaryBylineText && (
              <div>
                {secondaryBylineText}&nbsp;{authorLink(secondaryAuthor)}
              </div>
            )}
          </div>
          <BlogPostMetadata
            className={styles.metaData}
            createdAt={publishedDate || createdAt}
            readingTime={readingTime}
            categories={[]}
          />
        </div>
      </PanelItem>
    </Panel>
  );
};
