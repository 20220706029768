import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { CmsAsset } from "../CardPanel";
import { CONTENT_TYPE_MP4, VideoAsset } from "../Blog/BlogPost/VideoAsset";
import { IconographyButton } from "../IconographyButton";
import { BodySize } from "../constants";
import throttle from "lodash/throttle";
import playImg from "assets/icons/play.svg";

export interface VideoProps {
  video: CmsAsset;
  videoPoster?: CmsAsset;
  mobileVideo?: CmsAsset;
  mobileVideoPoster?: CmsAsset;
  play?: boolean;
  className?: string;
  onPlay?: () => void;
}

export const Video: FC<VideoProps> = ({
  video,
  videoPoster,
  mobileVideo,
  mobileVideoPoster,
  play = false,
  className,
  onPlay = () => {}
}) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const [videoPlayed, setVideoPlayed] = useState(false);
  const [width, setWidth] = useState(0);
  const [duration, setDuration] = useState("");

  const updateDuration = () => {
    if (videoRef.current) {
      const video = videoRef.current.querySelector("video");
      const duration = video?.duration || 0;
      const minutes = Math.floor(duration / 60);
      const remainingSeconds = Math.ceil(duration % 60);

      setDuration(
        `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`
      );
    }
  };

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    const callback = throttle(handleResize, 100);

    window.addEventListener("resize", callback);

    return () => window.removeEventListener("resize", callback);
  }, [width]);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  const handlePlayVideo = async () => {
    if (videoRef.current && !videoPlayed) {
      const video = videoRef.current.querySelector("video");

      await video?.play();

      setVideoPlayed(true);
    }
  };

  const pauseVideo = async () => {
    if (videoRef.current) {
      const video = videoRef.current.querySelector("video");

      video?.pause();
    }
  };

  useEffect(() => {
    if (play) {
      handlePlayVideo();
    } else {
      pauseVideo();
    }
  }, [play]);

  const getDynamicUrl = () => {
    if (width > BodySize.maxMobile) {
      return video?.file.url;
    } else {
      return mobileVideo ? mobileVideo.file.url : video?.file.url;
    }
  };

  const getDynamicPoster = () => {
    if (width > BodySize.maxMobile) {
      return videoPoster?.file.url;
    } else {
      return mobileVideoPoster
        ? mobileVideoPoster.file.url
        : videoPoster?.file.url;
    }
  };

  return (
    <div className={styles.video}>
      <div ref={videoRef}>
        <VideoAsset
          onPlay={onPlay}
          className={className}
          onLoad={updateDuration}
          controls={videoPlayed}
          url={getDynamicUrl() || ""}
          poster={getDynamicPoster()}
          fileName={video.file.fileName!}
          videoType={CONTENT_TYPE_MP4}
        />
      </div>

      {!videoPlayed && (
        <div className={styles.cta}>
          <IconographyButton
            icon={playImg}
            value="Play"
            disabled={false}
            handler={handlePlayVideo}
          />
        </div>
      )}

      {!videoPlayed && duration && (
        <div data-testid="duration" className={styles.duration}>
          {duration}
        </div>
      )}
    </div>
  );
};

Video.displayName = "Video";
