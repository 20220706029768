import React, { FC } from "react";
import { graphql } from "gatsby";
import { LostCardPageLayout } from "components/LostCard/LostCardPageLayout";
import { LostCardPageProps } from "..";

export const CardLockFailedPage: FC<LostCardPageProps> = ({
  data: { page }
}) => <LostCardPageLayout page={page} title="Card lock failed" />;

export const PageQuery = graphql`
  query LostCardCardLockFailedPageQuery {
    page: contentfulLostCardPage(lostCardPageId: { eq: "cardLockFailed" }) {
      darkTheme
      title
      image {
        gatsbyImageData(width: 2560, placeholder: BLURRED)
      }
      mainContent {
        raw
      }
      helpText {
        raw
      }
      errorText
    }
  }
`;

export default CardLockFailedPage;
