import React, { FC } from "react";
import { Colour } from "components/constants";
import { LinkProps, Link } from "components/Link";
import cx from "classnames";
import styles from "./styles.module.scss";

export enum ButtonVariants {
  PRIMARY = "primary",
  SECONDARY = "secondary"
}

export interface ButtonProps extends LinkProps<any> {
  id?: string;
  colour?: Colour;
  variant?: ButtonVariants;
  disabled?: boolean;
  text?: string;
  smallText?: boolean;
  dataTrack?: string;
}

export const Button: FC<ButtonProps> = ({
  disabled = false,
  className,
  children,
  variant = ButtonVariants.PRIMARY,
  smallText,
  dataTrack,
  ...linkProps
}) => {
  const button = cx(
    styles.button,
    {
      [styles[variant]]: Boolean(variant),
      [styles.disabled]: disabled
    },
    className
  );

  const optionalTrackProps = dataTrack ? { "data-track": dataTrack } : {};

  return (
    <div data-testid="button" className={button}>
      <Link
        role="button"
        className={cx(styles.link, {
          [styles.smallText]: Boolean(smallText)
        })}
        {...linkProps}
        {...optionalTrackProps}
      >
        {children}
      </Link>
    </div>
  );
};
Button.displayName = "Button";
