import { useEffect, useState } from "react";

export const useInitialScroll = (shouldWatch: boolean | undefined) => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const handleScroll = () => {
    setHasScrolled(true);
  };

  useEffect(() => {
    if (shouldWatch) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (shouldWatch) {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, [shouldWatch]);

  return hasScrolled;
};
