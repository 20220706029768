import React, { FC, useEffect, useRef } from "react";
import { Colour, SectionPaddingDefault } from "components/constants";
import { Panel, PanelItem } from "components/Panel";
import { CmsPageSectionType } from "../../ComposedPage/types";
import styles from "./styles.module.scss";
import { graphql } from "gatsby";
import { Templates, TrustBox } from "./TrustBox";
import { useScrolledIntoViewEvent } from "hooks/useAnalytics";
import { useLocationPage, useScrolledIntoView } from "hooks";
import { CmsPageSectionBase } from "../../CardPanel";
import cx from "classnames";

export enum WidgetType {
  Slider = "Slider",
  MicroCombo = "MicroCombo",
  Horizontal = "Horizontal"
}
export interface CmsTrustPilotSection extends CmsPageSectionBase {
  __typename: CmsPageSectionType.TrustPilot;
  title: string;
  widgetType: WidgetType;
}

export const TrustPilotSection: FC<CmsTrustPilotSection> = ({
  title,
  sectionPaddingBottom,
  sectionPaddingTop,
  widgetType
}) => {
  const panelRef = useRef(null);
  const page = useLocationPage();
  const pushScrolledIntoView = useScrolledIntoViewEvent("Trustpilot", page);
  const { displayed } = useScrolledIntoView(panelRef.current);

  useEffect(() => {
    if (displayed) {
      pushScrolledIntoView();
    }
  }, [displayed]);

  return (
    <Panel
      id="trustBoxSection"
      className={cx(
        styles.container,
        styles[sectionPaddingTop || SectionPaddingDefault.TOP],
        styles[sectionPaddingBottom || SectionPaddingDefault.BOTTOM]
      )}
      colour={Colour.warmStone}
      forwardRef={panelRef}
      short
    >
      <PanelItem>
        {(!widgetType || widgetType === WidgetType.Slider) && (
          <>
            <div className={styles.titleContainer}>
              {title && <p className={styles.title}>{title}</p>}
              <span className={styles.microStar}>
                <TrustBox template={Templates.MicroStar} />
              </span>
            </div>
            <div className={styles.item}>
              <TrustBox template={Templates.Slider} />
            </div>
          </>
        )}

        {widgetType === WidgetType.MicroCombo && (
          <div className={styles.item}>
            <TrustBox template={Templates.MicroCombo} />
          </div>
        )}

        {widgetType === WidgetType.Horizontal && (
          <div className={styles.item}>
            <TrustBox template={Templates.Horizontal} />
          </div>
        )}
      </PanelItem>
    </Panel>
  );
};

TrustPilotSection.displayName = "TrustPilotSection";

export const ContentfulTrustPilotSection = graphql`
  fragment ContentfulTrustPilotSectionFragment on ContentfulTrustPilotSection {
    __typename
    contentful_id
    title
    sectionPaddingTop
    sectionPaddingBottom
    widgetType
  }
`;
