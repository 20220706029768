import React, { FC, useEffect, useRef, useState } from "react";
import { useDeviceOs } from "@mettle/hooks";
import { PanelItem } from "components/Panel";
import styles from "../styles.module.scss";
import { useQRCode, useMobileAppLink } from "hooks";
import cx from "classnames";

export const DeepLinkRightPanel: FC = () => {
  const [isMobile, setIsMobile] = useState(false);
  const canvas = useRef(null);
  const deviceOS = useDeviceOs();
  const { universalDeepLink: link } = useMobileAppLink();
  useQRCode({ canvas: canvas.current, link });

  useEffect(() => {
    setIsMobile(deviceOS === "ios" || deviceOS === "android");
  }, []);

  return (
    <PanelItem className={cx(styles.heroPanel, { [styles.mobile]: isMobile })}>
      <div className={styles.QRPanel}>
        <div className={styles.QRContainer}>
          <canvas className={styles.canvas} ref={canvas}></canvas>
        </div>
      </div>
    </PanelItem>
  );
};
