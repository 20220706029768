import React, { useEffect, useRef } from "react";
import { useIsClient } from "@mettle/hooks";

declare global {
  interface Window {
    Trustpilot: { loadFromElement: (element: null, option: boolean) => void };
  }
}

export enum Theme {
  dark = "dark",
  light = "light"
}

export enum Templates {
  MicroStar = "5419b732fbfb950b10de65e5",
  Slider = "54ad5defc6454f065c28af8b",
  Mini = "53aa8807dec7e10d38f59f32",
  MicroCombo = "5419b6ffb0d04a076446a9af",
  Horizontal = "5406e65db0d04a09e042d5fc"
}

const commonProps = {
  className: "trustpilot-widget",
  "data-locale": "en-UK",
  "data-businessunit-id": "5c9ce2d4164d3700018251c7",
  "data-style-width": "100%"
};

const templateProps = {
  [Templates.MicroStar]: {
    "data-template-id": "5419b732fbfb950b10de65e5",
    "data-style-height": "24px",
    "data-text-color": "#454949"
  },
  [Templates.Slider]: {
    "data-template-id": "54ad5defc6454f065c28af8b",
    "data-style-height": "240px",
    "data-review-languages": "en",
    "data-stars": "4,5"
  },
  [Templates.Mini]: {
    "data-template-id": "53aa8807dec7e10d38f59f32",
    "data-style-height": "150px",
    "data-style-width": "100%",
    "data-theme": "light"
  },
  [Templates.MicroCombo]: {
    "data-template-id": "5419b6ffb0d04a076446a9af",
    "data-style-height": "20px",
    "data-style-width": "100%",
    "data-theme": "light"
  },
  [Templates.Horizontal]: {
    "data-template-id": "5406e65db0d04a09e042d5fc",
    "data-style-height": "28px",
    "data-style-width": "100%",
    "data-theme": "light"
  }
};

export interface TrustBoxProps {
  theme?: Theme;
  template: Templates;
}

export const TrustBox: React.FC<TrustBoxProps> = ({
  theme = Theme.light,
  template
}) => {
  const isClient = useIsClient();
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = useRef(null);
  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script in <head /> isn't loaded yet.
    // When it is, it will automatically load the TrustBox.
    if (ref.current && window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [isClient]);

  return isClient ? (
    <div
      style={{ zIndex: 3 }}
      ref={ref} // We need a reference to this element to load the TrustBox in the effect.
      data-theme={theme}
      {...{ ...commonProps, ...templateProps[template] }}
    ></div>
  ) : null;
};

TrustBox.displayName = "TrustBox";
