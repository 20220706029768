import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData
} from "gatsby-source-contentful/rich-text";
import All from "assets/icons/All.svg";

import { CmsBannerSection, CmsBlogArticleSection } from "./BlogPost";
import { CmsPageSection } from "../ComposedPage";
import { IGatsbyImageData } from "gatsby-plugin-image";

export interface CmsImage {
  title: string;
  description: string;
  file?: {
    url: string;
  };
}

export interface CmsResponsiveImage {
  file: {
    url: string;
  };
  title: string;
  gatsbyImageData: IGatsbyImageData;
}

export interface VideoDetails {
  size: number;
}

export interface CmsVideoFile {
  url: string;
  details: VideoDetails;
  fileName: string;
  contentType: string;
}

export interface CmsVideo {
  title: string;
  description: string;
  file: CmsVideoFile;
}

export interface CmsEntity {
  id: string;
  slug: string;
}

export interface CmsAuthor extends CmsEntity {
  name: string;
  website: string;
  twitter: string;
  jobTitle?: string;
  company: string;
  knowsAbout: string;
  linkedInUrl?: string;
  bio: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  extendedBio?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  photo: CmsResponsiveImage;
  blog_post?: CmsBlogPost[];
  sections?: CmsPageSection[];
}

export interface CmsCategory extends CmsEntity {
  title: string;
  heading?: string;
  description?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  order: number;
  icon: {
    file: {
      url: string;
    };
  };
}

export const AllCategory: CmsCategory = {
  id: "All",
  heading: "",
  description: { raw: "", references: [] },
  icon: { file: { url: All } },
  order: 0,
  slug: "all",
  title: "All"
};

export interface CmsCategoryWithBlogPosts extends CmsCategory {
  blog_post: { id: String }[] | null;
}

export interface CmsDocument {
  title: string;
  file: {
    url: string;
  };
}

export interface CmsBlogPost extends CmsEntity {
  __typename: "ContentfulBlogPost";
  id: string;
  title: string;
  subTitle: string;
  metaTitle: string;
  metaDescription: string;
  noIndex?: boolean;
  aboveFoldExperimentation?: boolean;
  heroImage?: CmsResponsiveImage;
  heroVideo?: CmsVideo;
  body: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  sections: CmsBlogArticleSection[];
  author: CmsAuthor;
  secondaryAuthor?: CmsAuthor;
  secondaryBylineText?: string;
  publishedDate?: string;
  updatedAt: string;
  createdAt: string;
  readingTime: number;
  hasTableOfContents: boolean;
  documents: CmsDocument[];
  categories: CmsCategory[];
  youMayAlsoLike: CmsBlogPost[] | null;
  bottomBanners?: CmsBannerSection[];
  canonicalUrl?: string;
  isPrivacyPage?: boolean;
}
