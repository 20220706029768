import React, { FC } from "react";
import cx from "classnames";
import styles from "./spinner.module.scss";

export const Spinner: FC = () => (
  <div data-testid="spinner" className={styles.loadingSpinner}>
    <svg
      className={styles.svg}
      viewBox="0 0 56 56"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: 56, height: 56 }}
    >
      <circle className={styles.fill} cx={28} cy={28} r={28} />
      <circle
        className={cx(styles.circle, styles.pinkSpinner)}
        cx={28}
        cy={28}
        r={14}
      />
      <circle
        className={cx(styles.circleEdge, styles.pinkSpinner)}
        cx={28}
        cy={28}
        r={14}
      />
    </svg>
  </div>
);
Spinner.displayName = "Spinner";

export const BlueSpinner: FC = () => (
  <div className={styles.loadingSpinner}>
    <svg
      className={styles.svg}
      viewBox="0 0 56 56"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: 56, height: 56 }}
    >
      <circle
        className={cx(styles.circle, styles.blueSpinner)}
        cx={28}
        cy={28}
        r={26}
      />
      <circle
        className={cx(styles.circleEdge, styles.blueSpinner)}
        cx={28}
        cy={28}
        r={26}
      />
    </svg>
  </div>
);
BlueSpinner.displayName = "BlueSpinner";
