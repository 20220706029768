import React from "react";
import isEmpty from "lodash/isEmpty";
import { Link } from "components/Link";
import { EmailFormConfig } from "components/CaptureLeadSection/emailFormConfig";
import { useBlogRichText } from "components/Blog/hooks";

export const createPolicyAgreedValidationFunction =
  (errorMessage: string) => (agreed: boolean) => {
    if (!agreed) {
      throw new Error(errorMessage);
    }
  };

export const PRIVACY_NOTICE_URL = "/blog/mettle-privacy-notice/";
export const getPolicyFieldConfig = (emailFormConfig: EmailFormConfig) => {
  return {
    type: "checkbox",
    applyFocus: false,
    label: isEmpty(emailFormConfig?.policyCheckbox?.raw) ? (
      <p>
        I confirm that I have read and agree to the Mettle{" "}
        <Link newTab url={PRIVACY_NOTICE_URL}>
          privacy notice
        </Link>
      </p>
    ) : (
      <>{useBlogRichText(emailFormConfig.policyCheckbox)}</>
    ),
    required: true,
    validate: createPolicyAgreedValidationFunction(
      emailFormConfig.policyCheckboxValidationError
    )
  };
};
