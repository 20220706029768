import React, { FC } from "react";
import cx from "classnames";
import { Panel, PanelItem, PanelLayout } from "components/Panel";
import { Logo } from "components/Logo";
import { Colour } from "components/constants";
import { Navigation } from "components/Navigation";
import styles from "./styles.module.scss";
import { useStaticQuery, graphql } from "gatsby";

interface Props {
  showButton?: boolean;
  transparent?: boolean;
}

export const Header: FC<Props> = ({ transparent = false }) => {
  const data = useStaticQuery(graphql`
    query SiteLogo {
      contentfulSiteHeader {
        logo {
          title
          file {
            url
          }
        }
      }
    }
  `);

  return (
    <Panel
      data-testid="header"
      id="header"
      key="HeaderPanel"
      short
      layout={PanelLayout.full}
      colour={Colour.black}
      className={cx(styles.header, {
        [styles.hasBackground]: !transparent
      })}
    >
      <PanelItem className={styles.content} key="HeaderItem">
        <Logo
          src={data.contentfulSiteHeader.logo.file.url}
          title={data.contentfulSiteHeader.logo.title}
        />
        <Navigation className={styles.rightSide} />
      </PanelItem>
    </Panel>
  );
};
Header.displayName = "Header";
