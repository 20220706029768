import React from "react";
import cx from "classnames";
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData
} from "gatsby-source-contentful/rich-text";
import styles from "./BlogArticle.module.scss";
import { useBlogRichText } from "../hooks";
import { graphql } from "gatsby";

export interface CmsBlogPostRichTextSection {
  __typename: string;
  body: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  className?: string;
}

export const BlogPostRichTextSection: React.FC<CmsBlogPostRichTextSection> = ({
  body,
  className
}) => {
  return (
    <section className={cx(styles.article, className)}>
      {useBlogRichText(body)}
    </section>
  );
};

export const ContentfulBlogPostRichTextSection = graphql`
  fragment ContentfulBlogPostRichTextSectionFragment on ContentfulBlogPostRichTextSection {
    __typename
    body {
      raw
      references {
        ... on ContentfulAsset {
          __typename
          contentful_id
          title
          description
          file {
            url
            fileName
            contentType
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }
  }
`;
