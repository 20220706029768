import React, { FC } from "react";
import cx from "classnames";
import styles from "./styles.module.scss";
import { OpenAccountButton } from "components/OpenAccount/OpenAccountButton";
import { Link } from "components/Link";

export interface PanelTitlesProps {
  title?: string;
  subtitle?: string;
  ctaText?: string;
  ctaUrl?: string;
  showOpenAccountButton?: boolean;
  className?: string;
}

export const PanelTitles: FC<PanelTitlesProps> = ({
  title,
  subtitle,
  ctaText,
  ctaUrl,
  showOpenAccountButton,
  className
}) => {
  const hasContent = () =>
    title || subtitle || (ctaText && ctaUrl) || showOpenAccountButton;

  return hasContent() ? (
    <div
      className={cx(styles.panelTitles, className, {
        [styles.containsCta]: (ctaText && ctaUrl) || showOpenAccountButton
      })}
    >
      {title && <h3 className={styles.panelTitle}>{title}</h3>}
      {subtitle && <p className={styles.panelSubtitle}>{subtitle}</p>}
      {((ctaText && ctaUrl) || showOpenAccountButton) && (
        <div className={styles.ctaContainer}>
          {showOpenAccountButton && <OpenAccountButton />}
          <Link className={styles.url} url={ctaUrl}>
            {ctaText}
          </Link>
        </div>
      )}
    </div>
  ) : null;
};
