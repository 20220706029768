import React, { FC } from "react";
import { Button } from "components/Button";
import styles from "./styles.module.scss";
import { Data } from "../data";

type MobileLeftPanelProps = { onTapButton: () => void };

export const MobileLeftPanel: FC<MobileLeftPanelProps> = ({ onTapButton }) => {
  return (
    <>
      <h2 className={styles.title}>{Data.default.title}</h2>
      <p className={styles.description}>{Data.default.mobileMessage}</p>
      <Button className={styles.button} onClick={onTapButton}>
        {Data.default.button}
      </Button>
    </>
  );
};
MobileLeftPanel.displayName = "MobileLeftPanel";
