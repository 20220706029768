import React, { FC } from "react";
import {
  AppStoreRatingCard,
  AppStoreRatingCardProps,
  PlatformType
} from "../AppStoreRatingCard";
import { AwardCard, AwardCardProps } from "../AwardCard";
import {
  TrustPilotRatingCard,
  TrustPilotRatingCardProps
} from "../TrustPilotRatingCard";
import { Colour } from "../constants";

export interface CmsSocialProofingCard {
  __typename: string;
  id: string;
  androidQuickLink: string;
  appStoreRating?: string;
  appStoreRatingDescription?: string;
  appStoreType?: PlatformType;
  backgroundColour: Colour;
  cardType:
    | "award"
    | "appStoreRating"
    | "trustPilotRating"
    | "trustPilotReview";
  iosQuickLink: string;
  testAndroidQuickLink: string;
  testIosQuickLink: string;
}

enum SocialProofingCardTypes {
  AppStoreRating = "appStoreRating",
  Award = "award",
  TrustPilotRating = "trustPilotRating"
}

type SocialProofingCardType =
  | AppStoreRatingCardProps
  | AwardCardProps
  | TrustPilotRatingCardProps;

const SocialProofingCardTypeMap: Record<
  SocialProofingCardTypes,
  React.FC<SocialProofingCardType>
> = {
  [SocialProofingCardTypes.Award]: AwardCard,
  [SocialProofingCardTypes.TrustPilotRating]: TrustPilotRatingCard,
  [SocialProofingCardTypes.AppStoreRating]: AppStoreRatingCard
};
export const SocialProofingCard: FC<CmsSocialProofingCard> = (
  props: CmsSocialProofingCard
) => {
  const Component = SocialProofingCardTypeMap[props.cardType];

  return <Component {...props} />;
};
