import React, { FC } from "react";
import { formatDate } from "date-fns/format";
import cx from "classnames";
import { CmsBlogPost } from "../types";
import styles from "./PostMetadata.module.scss";

import { BlogCategory } from "../BlogHome/BlogCategory";

export type PostMetadata = Pick<
  CmsBlogPost,
  "createdAt" | "readingTime" | "categories"
> & { showDate?: boolean };

export const BlogPostMetadata: FC<
  PostMetadata & { className?: string; showDate?: boolean }
> = ({ createdAt, readingTime, categories, className, showDate = true }) => {
  const publishingDate = new Date(createdAt);
  return (
    <div className={cx(styles.metadata, className)}>
      <div className={styles.left}>
        {showDate && (
          <div data-testid="created-at">
            <time
              itemProp="datePublished"
              dateTime={formatDate(publishingDate, "yyyy-MM-dd")}
            >
              {formatDate(publishingDate, "d MMMM yyyy")}
            </time>
          </div>
        )}
        <div className={styles.readingTime} data-testid="reading-duration">
          <span itemProp="timeRequired" content={`PT${readingTime}M`}>
            {readingTime}
          </span>{" "}
          min read
        </div>
      </div>
      {categories && categories.length > 0 && (
        <BlogCategory category={categories[0]} className={styles.category} />
      )}
    </div>
  );
};
BlogPostMetadata.displayName = "BlogPostMetadata";
