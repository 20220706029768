import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import cx from "classnames";
import { useLocationPage, useVideoAnalytics, VideoEventType } from "hooks";
import unsupportedVideo from "assets/icons/unsupported-video.svg";
import styles from "./VideoAsset.module.scss";

export const CONTENT_TYPE_MP4 = "video/mp4";

interface Props {
  url: string;
  fileName: string;
  videoType: string;
  poster?: string;
  contentType?: string;
  autoHeight?: boolean;
  className?: string;
  controls?: boolean;
  onLoad?: () => void;
  onPlay?: () => void;
}

export const convertToPercent = (num: number) =>
  Math.round((num + Number.EPSILON) * 100);

export const convertToQuartile = (percent: number) =>
  Math.floor(percent / 25) * 25;

export const VideoAsset: React.FC<Props> = ({
  url,
  poster,
  contentType = CONTENT_TYPE_MP4,
  autoHeight = false,
  fileName,
  videoType,
  className,
  controls = true,
  onLoad = () => {},
  onPlay = () => {}
}) => {
  const page = useLocationPage();
  const videoRef = useRef<HTMLVideoElement>(null);
  const handleVideoEvent = useVideoAnalytics({ fileName, videoType, page });
  const [quartile, setQuartile] = useState<number>(-1);

  useEffect(() => {
    videoRef.current?.load();
  }, [url]);

  React.useEffect(() => {
    if (videoRef.current && quartile >= 0) {
      handleVideoEvent({
        event: VideoEventType.VIDEO_DEPTH,
        videoTime:
          quartile === 0 ? "0.00" : videoRef.current.currentTime.toFixed(2),
        value: `${quartile}%`
      });

      if (quartile === 100) {
        setQuartile(-1);
      }
    }
  }, [videoRef.current, quartile]);

  const onTimeUpdate = useCallback(
    (e: SyntheticEvent<HTMLVideoElement>) => {
      const currentTime = e.currentTarget.currentTime;
      const duration = e.currentTarget.duration;
      const percent = convertToPercent(currentTime / duration);
      const currentQuartile = convertToQuartile(percent);
      if (currentQuartile >= quartile) {
        setQuartile(currentQuartile);
      }
    },
    [quartile]
  );

  const createEventHandler =
    (eventType: VideoEventType) => (e: SyntheticEvent<HTMLVideoElement>) => {
      handleVideoEvent({
        event: eventType,
        videoTime: e.currentTarget.currentTime.toFixed(2)
      });
      onPlay();
    };

  return (
    <div className={cx(styles.video, { [className!]: Boolean(className) })}>
      <video
        ref={videoRef}
        controls={controls}
        controlsList="nodownload"
        data-testid="video"
        preload="metadata"
        poster={poster}
        onPlay={createEventHandler(VideoEventType.PLAY)}
        onPause={createEventHandler(VideoEventType.PAUSE)}
        onSeeked={createEventHandler(VideoEventType.SEEKED)}
        onEnded={createEventHandler(VideoEventType.ENDED)}
        onTimeUpdate={onTimeUpdate}
        onLoadedMetadata={onLoad}
        playsInline
      >
        <source src={url} type={contentType} />
        <div
          className={cx(styles.noSupport, {
            [styles.autoHeight]: autoHeight,
            [styles.noAutoHeight]: !autoHeight
          })}
        >
          <div className={styles.inner}>
            <div className={styles.content}>
              <div>
                <img src={unsupportedVideo} alt="" className={styles.icon} />
              </div>
              Your browser doesn't support this video format.
              <br />
              Try updating to the latest version.
            </div>
          </div>
        </div>
      </video>
    </div>
  );
};

VideoAsset.displayName = "VideoAsset";
