import { useInitialScroll } from "../../hooks/useInitialScroll";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";
import { AppStoreButtons } from "../AppStoreButtons";
import { CmsResponsiveImage } from "../Blog";
import { useBlogRichText } from "../Blog/hooks";
import {
  AnimationTrigger,
  CmsAsset,
  CmsPageSectionBase,
  ImageFit
} from "../CardPanel";
import { IdentImageTitle } from "../IdentImageTitle";
import { LottieAnimation } from "../LottieAnimation";
import { Panel, PanelItem, PanelLayout } from "../Panel";
import { Colour, SectionPaddingDefault } from "../constants";
import { HeroCtas } from "./HeroCtas";
import styles from "./styles.module.scss";
import cx from "classnames";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData
} from "gatsby-source-contentful/rich-text";
import React, { FC, useRef } from "react";

export type copyPosition = "leftAligned" | "rightAligned" | "center";
export type copyAlignment = "center" | "bottom" | undefined;
export type imagePosition = "rightAligned" | "leftAligned" | "bottom";

export interface CmsPageHeroSection extends CmsPageSectionBase {
  title?: string;
  titleIdentImage?: CmsResponsiveImage;
  subtitle?: string;
  bodyCopy?: string;
  richText?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  disclaimerText?: string;
  restrictCopyWidth?: boolean;
  fontColour?: "dark" | "light";
  textShadow?: boolean;
  backgroundColour: Colour;
  primaryCtaText?: string;
  primaryCtaUrl?: string;
  secondaryCtaText?: string;
  secondaryCtaUrl?: string;
  linkUrl?: string;
  linkText?: string;
  fullHeight?: boolean;
  minimumHeight?: "medium" | "large";
  padImage?: boolean;
  image?: CmsResponsiveImage;
  mobileImage?: CmsResponsiveImage;
  centerImage?: boolean;
  lottieFile?: CmsAsset;
  mobileLottieFile?: CmsAsset;
  loopAnimation?: boolean;
  animationTrigger?: AnimationTrigger;
  backgroundImage?: CmsResponsiveImage;
  imageFit?: ImageFit;
  imagePosition?: imagePosition;
  copyPosition: copyPosition;
  copyAlignment?: copyAlignment;
  footerText?: string;
  openAccountButtonText?: string;
  showOpenAccountButton?: boolean;
  showAppStoreButtons?: boolean;
  testIosQuickLink?: string;
  testAndroidQuickLink?: string;
  iosQuickLink?: string;
  androidQuickLink?: string;
  pagePosition: number;
  parallax?: boolean;
  reducedWidth?: boolean;
  blog?: boolean;
}

export const PageHero: FC<CmsPageHeroSection> = ({
  title,
  titleIdentImage,
  subtitle,
  bodyCopy,
  richText,
  disclaimerText,
  restrictCopyWidth,
  backgroundColour,
  copyPosition,
  imagePosition,
  image,
  mobileImage,
  centerImage = false,
  lottieFile,
  mobileLottieFile,
  loopAnimation = false,
  animationTrigger,
  backgroundImage,
  imageFit,
  primaryCtaText,
  primaryCtaUrl,
  secondaryCtaText,
  secondaryCtaUrl,
  linkUrl,
  linkText,
  footerText,
  openAccountButtonText,
  showOpenAccountButton,
  showAppStoreButtons,
  testIosQuickLink,
  testAndroidQuickLink,
  iosQuickLink,
  androidQuickLink,
  minimumHeight,
  sectionPaddingBottom,
  sectionPaddingTop,
  pagePosition,
  textShadow,
  reducedWidth,
  blog = false
}) => {
  const animationRef = useRef(null);
  const mobileAnimationRef = useRef(null);
  const animateOnIntersect = useIntersectionObserver(
    animationRef,
    lottieFile && animationTrigger === AnimationTrigger.onScrollIntoView
  );
  const mobileAnimateOnIntersect = useIntersectionObserver(
    mobileAnimationRef,
    mobileLottieFile && animationTrigger === AnimationTrigger.onScrollIntoView
  );
  const animateOnScroll = useInitialScroll(
    (lottieFile || mobileLottieFile) &&
      animationTrigger === AnimationTrigger.onScroll
  );
  const rightCopy = (position: copyPosition) => position === "rightAligned";
  const centerCopy = (position: copyPosition) => position === "center";
  const Title = () => {
    const Tag = pagePosition === 0 ? "h1" : "h2";
    const getImageAlignment = () =>
      copyPosition === "rightAligned" ? "left" : "right";

    return (
      <div
        className={cx(styles.heroTitle, styles.titleContainer, {
          [styles.hasIdentImage]: Boolean(titleIdentImage),
          [styles.isBlog]: blog
        })}
      >
        {titleIdentImage ? (
          <IdentImageTitle
            Tag={Tag}
            image={titleIdentImage}
            title={title!}
            imageAlignment={getImageAlignment()}
          />
        ) : (
          <Tag>{title}</Tag>
        )}
      </div>
    );
  };

  return (
    <Panel
      className={cx(styles.pageHero, {
        [styles.isBlog]: blog
      })}
      colour={Colour[backgroundColour]}
      layout={PanelLayout.full}
      blog={blog}
      short
    >
      <PanelItem
        className={cx(styles.panelItem, {
          [styles.minimumLarge]: minimumHeight === "large",
          [styles.minimumMedium]: minimumHeight === "medium"
        })}
      >
        <div
          className={cx(styles.content, {
            [styles.reducedWidth]: reducedWidth,
            [styles.textShadow]: textShadow,
            [styles.imageLeft]: imagePosition === "leftAligned",
            [styles.imageBottom]: imagePosition === "bottom"
          })}
        >
          <div
            className={cx(
              styles.copy,
              styles[sectionPaddingTop || SectionPaddingDefault.TOP],
              styles[sectionPaddingBottom || SectionPaddingDefault.BOTTOM],
              {
                [styles.restrictWidth]: Boolean(restrictCopyWidth),
                [styles.copyRight]: rightCopy(copyPosition),
                [styles.copyCenter]: centerCopy(copyPosition)
              }
            )}
          >
            {title && <Title />}
            {subtitle && <h4>{subtitle}</h4>}
            {bodyCopy && <h5>{bodyCopy}</h5>}
            {richText && useBlogRichText(richText)}
            {disclaimerText && (
              <p className={styles.disclaimerText}>{disclaimerText}</p>
            )}
            {showAppStoreButtons && (
              <AppStoreButtons
                testIosQuickLink={testIosQuickLink}
                testAndroidQuickLink={testAndroidQuickLink}
                iosQuickLink={iosQuickLink}
                androidQuickLink={androidQuickLink}
              />
            )}
            <HeroCtas
              className={styles.heroCtas}
              primaryCtaUrl={primaryCtaUrl}
              primaryCtaText={primaryCtaText}
              secondaryCtaUrl={secondaryCtaUrl}
              secondaryCtaText={secondaryCtaText}
              linkText={linkText}
              linkUrl={linkUrl}
              showOpenAccountButton={showOpenAccountButton}
              openAccountButtonText={openAccountButtonText}
            />
            {footerText && <p className={styles.footerText}>{footerText}</p>}
          </div>
          {image && (
            <div
              className={cx(styles.image, {
                [styles.centerImage]: centerImage
              })}
            >
              {image && (
                <GatsbyImage
                  className={cx(styles.desktopImage, {
                    [styles.hasMobileImage]: Boolean(mobileImage)
                  })}
                  alt={image.title}
                  imgStyle={{
                    objectFit: imageFit || ImageFit.COVER
                  }}
                  image={getImage(image.gatsbyImageData) as IGatsbyImageData}
                />
              )}

              {mobileImage && (
                <GatsbyImage
                  className={styles.mobileImage}
                  alt={image.title}
                  imgStyle={{
                    objectFit: imageFit || ImageFit.COVER
                  }}
                  image={
                    getImage(mobileImage.gatsbyImageData) as IGatsbyImageData
                  }
                />
              )}
            </div>
          )}
          {lottieFile && (
            <div
              className={cx(styles.lottieContainer, styles.desktopLottie, {
                [styles.hasMobileLottie]: Boolean(mobileLottieFile),
                [styles.centerImage]: centerImage
              })}
              ref={animationRef}
            >
              <LottieAnimation
                className={styles.lottie}
                play={animateOnIntersect || animateOnScroll}
                path={lottieFile.file.url}
                loop={loopAnimation}
              />
            </div>
          )}

          {mobileLottieFile && (
            <div
              className={cx(styles.lottieContainer, styles.mobileLottie, {
                [styles.centerImage]: centerImage
              })}
              ref={mobileAnimationRef}
            >
              <LottieAnimation
                className={styles.lottie}
                play={mobileAnimateOnIntersect || animateOnScroll}
                path={mobileLottieFile.file.url}
                loop={loopAnimation}
              />
            </div>
          )}
        </div>
      </PanelItem>
      {backgroundImage && (
        <div className={styles.backgroundImage}>
          <GatsbyImage
            alt=""
            imgStyle={{
              objectFit: ImageFit.COVER
            }}
            image={
              getImage(backgroundImage.gatsbyImageData) as IGatsbyImageData
            }
          />
        </div>
      )}
    </Panel>
  );
};

export const ContentfulPageHeroFragment = graphql`
  fragment ContentfulPageHeroFragment on ContentfulPageHero {
    __typename
    id
    contentful_id
    title
    titleIdentImage {
      gatsbyImageData(placeholder: BLURRED)
      title
    }
    subtitle
    bodyCopy
    richText {
      raw
    }
    disclaimerText
    restrictCopyWidth
    fontColour
    textShadow
    primaryCtaText
    primaryCtaUrl
    secondaryCtaText
    secondaryCtaUrl
    linkUrl
    linkText
    fullHeight
    minimumHeight
    backgroundColour
    imagePosition
    padImage
    copyPosition
    copyAlignment
    footerText
    showOpenAccountButton
    openAccountButtonText
    showAppStoreButtons
    testIosQuickLink
    testAndroidQuickLink
    iosQuickLink
    androidQuickLink
    sectionPaddingTop
    sectionPaddingBottom
    image {
      gatsbyImageData(placeholder: BLURRED)
      title
    }
    mobileImage {
      gatsbyImageData(placeholder: BLURRED)
      title
    }
    centerImage
    backgroundImage {
      gatsbyImageData(placeholder: BLURRED, quality: 80)
    }
    imageFit
    parallax
    lottieFile {
      file {
        url
      }
    }
    mobileLottieFile {
      file {
        url
      }
    }
    loopAnimation
    animationTrigger
    reducedWidth
  }
`;

PageHero.displayName = "Page Hero";
