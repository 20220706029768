import { DeviceSize } from "@mettle/hooks";

export function isDesktopSize(size: DeviceSize): boolean {
  return size === DeviceSize.Desktop;
}

export function isMobileSize(size: DeviceSize): boolean {
  return size === DeviceSize.Mobile;
}

export function isTabletSize(size: DeviceSize): boolean {
  return size === DeviceSize.Tablet;
}
