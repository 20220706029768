import React, { FC } from "react";
import { InlineDropDownNav } from "../../InlineDropDownNav";
import styles from "./styles.module.scss";
import all from "../../../assets/icons/All.svg";
import { AlphabetisedKey } from "../hooks/useSortGlossaryTerms";
import { GlossaryNavigationPropsBase } from "./index";

export type GlossaryNavigationBarProps = GlossaryNavigationPropsBase & {
  activeScrollLink: AlphabetisedKey;
};

export const GlossaryMobileNavigation: FC<GlossaryNavigationBarProps> = ({
  activeScrollLink,
  links
}) => {
  return (
    <InlineDropDownNav
      className={styles.mobileNavigation}
      closedText={activeScrollLink}
      openText="Select"
      icon={all}
      options={links.map(link => (
        <a
          data-testid={`mobile-glossary-link-${link}`}
          key={link}
          href={`#${link}`}
        >
          {link}
        </a>
      ))}
    />
  );
};

GlossaryMobileNavigation.displayName = "GlossaryMobileNavigation";
