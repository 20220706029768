import React from "react";
import styles from "./BlogPostHero.module.scss";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { CmsResponsiveImage } from "../types";

export interface Props {
  heroImage?: CmsResponsiveImage;
  className?: string;
}

export const BlogPostHero: React.FC<Props> = ({ heroImage }) => {
  if (heroImage) {
    return (
      <div data-testid="image" className={styles.heroImage}>
        <GatsbyImage
          imgStyle={{ objectFit: "cover" }}
          image={getImage(heroImage.gatsbyImageData) as IGatsbyImageData}
          alt={heroImage.title}
        />
      </div>
    );
  }
  return null;
};

BlogPostHero.displayName = "BlogPostHero";
