import React, { FC } from "react";
import { graphql } from "gatsby";
import { DefaultLayout } from "../../layouts";
import { Colour } from "components/constants";
import { RichText } from "components/RichText";
import { EligibilityHeader } from "components/EligibilityHeader";
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData
} from "gatsby-source-contentful/rich-text";
import { CmsBannerSection } from "components/Blog";

export interface EligibilityPageProps {
  data: {
    page: {
      title: string;
      metaTitle?: string;
      metaDescription?: string;
      subTitle?: string;
      body: RenderRichTextData<ContentfulRichTextGatsbyReference>;
      bottomBanners?: CmsBannerSection[];
    };
  };
}

export const EligibilityPageTemplate: FC<EligibilityPageProps> = ({
  data: { page }
}) => (
  <DefaultLayout
    title={page.metaTitle || `${page.title} | Mettle blog`}
    description={page.metaDescription}
    background={Colour.white}
    footer={false}
    header={false}
  >
    <EligibilityHeader title={page.title} subTitle={page.subTitle} />
    <RichText body={page.body} />
  </DefaultLayout>
);
export default EligibilityPageTemplate;

export const pageQuery = graphql`
  query AppEligibilityPageQuery {
    page: contentfulEligibilityPage {
      title
      metaTitle
      metaDescription
      subTitle
      body {
        raw
      }
    }
  }
`;
