import React, { FC, ReactElement, useEffect, useRef, useState } from "react";
import { useToggle } from "@mettle/hooks";
import styles from "./styles.module.scss";
import cx from "classnames";
import SVG from "react-inlinesvg";
import { BlogCategoryProps } from "../Blog/BlogHome/BlogCategory";
import downArrow from "../../assets/icons/down.svg";
import { TocLinkProps } from "../Blog/BlogPost/TableOfContents/TocLink";

export interface InlineDropDownNavProps {
  closedText: string;
  openText: string;
  options: ReactElement<BlogCategoryProps | TocLinkProps>[];
  icon: string;
  className?: string;
}

export const InlineDropDownNav: FC<InlineDropDownNavProps> = ({
  closedText,
  openText,
  options,
  icon,
  className
}) => {
  const navHeaderHeight = 202;
  const [expanded, { toggle }] = useToggle();
  const optionsEl = useRef<HTMLDivElement>(null);
  const navEl = useRef<HTMLDivElement>(null);
  const [optionsHeight, setOptionsHeight] = useState(0);

  const handleKeydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      toggle();
    } else if (e.key === "Tab" && !expanded) {
      toggle();
    }
  };

  const configureOptionsHeight = () => {
    if (optionsEl.current) {
      const height = optionsEl.current.clientHeight;

      setOptionsHeight(height);
    }
  };

  const calcMax = () => {
    if (navEl.current) {
      return (
        window.innerHeight -
        navEl.current.getBoundingClientRect()?.top -
        navHeaderHeight
      );
    }

    return 0;
  };

  useEffect(() => {
    configureOptionsHeight();

    window.addEventListener("resize", configureOptionsHeight);

    return () => window.removeEventListener("resize", configureOptionsHeight);
  }, []);

  return (
    <div ref={navEl} className={cx(styles.inlineDropdownNav, className)}>
      <div className={styles.dropdownInner}>
        <div
          role="navigation"
          tabIndex={0}
          className={styles.select}
          onClick={toggle}
          onKeyDown={handleKeydown}
        >
          <h5 className={styles.label}>
            <div className={cx(styles.labelIcon)}>
              <SVG src={icon} />
            </div>
            <>{expanded ? openText : closedText}</>
          </h5>
          <div className={cx({ [styles.expanded]: expanded }, styles.icon)}>
            <SVG src={downArrow} />
          </div>
        </div>

        <div
          className={cx(styles.expand, {
            [styles.expanded]: expanded
          })}
        >
          <div
            className={cx(styles.expandedContainer, {
              [styles.expanded]: expanded,
              [styles.scroll]: optionsHeight > calcMax()
            })}
          >
            <div className={styles.options} ref={optionsEl}>
              {options.map((option, index) => (
                <div className={styles.item} key={index} onClick={toggle}>
                  {option}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
