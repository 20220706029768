import { useContext, useState, useEffect } from "react";
import {
  BranchIO,
  BranchQRCodeSettings,
  LastAttributedTouchDataResponse,
  QRCodeLinkData
} from "branch-sdk";
import { AnalyticsContext } from "hooks/useAnalytics";
import spinner from "./spinner.svg";
import { useConfig } from "hooks";
import { Env } from "conf/env";
import { useStaticQrCode } from "hooks/useStaticQrCode";

const ATTRIBUTION_WINDOW = 30;

const createQRCode = async (
  branch: BranchIO | null,
  params: QRCodeLinkData = {}
) => {
  return new Promise((resolve, reject) => {
    if (!branch) {
      return reject("Create QR Code - Branch instance not found.");
    }

    const qrCodeSettings: BranchQRCodeSettings = {
      code_color: "#000000",
      background_color: "#FFFFFF",
      margin: 0,
      width: 500,
      image_format: "png"
    };

    branch.qrCode(params, qrCodeSettings, (err, qrCode) => {
      if (err) {
        return reject(err);
      }
      resolve(qrCode?.base64());
    });
  });
};

const lastAttributedTouchData = async (
  branch: BranchIO | null
): Promise<LastAttributedTouchDataResponse | undefined> => {
  return new Promise((resolve, reject) => {
    if (!branch) {
      return reject("Last Attributed Touch Data - Branch instance not found.");
    }

    branch.lastAttributedTouchData(ATTRIBUTION_WINDOW, (_err, latd) => {
      // Don't reject if there is an error. There is no need to use the static QR code if this fails.
      resolve(latd);
    });
  });
};

export const useBranchQRCode = () => {
  const config = useConfig();
  const [qrCode, setQrCode] = useState<string>(spinner);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [type, setType] = useState<"static" | "dynamic">("dynamic");
  const { branch, tracking_disabled } = useContext(AnalyticsContext);

  const staticQrCode = () => useStaticQrCode(config?.ENV === Env.TEST);

  useEffect(() => {
    setType(qrCode === staticQrCode() ? "static" : "dynamic");
  }, [qrCode]);

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        const searchParams = new URLSearchParams(location.search);
        const queryParams = Object.fromEntries(searchParams);
        const tags = Array.from(searchParams.entries()).map(
          val => `${val[0]} = ${val[1]}`
        );
        const latd = await lastAttributedTouchData(branch);

        let params: QRCodeLinkData = {
          channel: latd?.last_attributed_touch_data?.data["~channel"],
          feature:
            latd?.last_attributed_touch_data?.data["~feature"] || "onboarding",
          stage: "new user - QR code",
          tags,
          campaign:
            latd?.last_attributed_touch_data?.data["~campaign"] ||
            queryParams.utm_campaign,

          data: {
            "~advertising_partner_name":
              latd?.last_attributed_touch_data?.data[
                "~advertising_partner_name"
              ],
            $3p: latd?.last_attributed_touch_data?.data.$3p || queryParams.$3p,
            "~ad_id": latd?.last_attributed_touch_data?.data["~ad_id"],
            "~ad_set_id": latd?.last_attributed_touch_data?.data["~ad_set_id"],
            "~ad_set_name":
              latd?.last_attributed_touch_data?.data["~ad_set_name"] ||
              queryParams.utm_content,
            "~campaign_id":
              latd?.last_attributed_touch_data?.data["~campaign_id"] ||
              queryParams.utm_id,
            "~keyword":
              latd?.last_attributed_touch_data?.data["~keyword"] ||
              queryParams.utm_term
          }
        };

        if (!params?.data?.$3p) {
          delete params?.data?.$3p;
        }

        if (!tracking_disabled) {
          const qrResult = await createQRCode(branch, params);
          setQrCode(`data:image/png;charset=utf-8;base64, ${qrResult}`);
        } else {
          setQrCode(staticQrCode());
        }
      } catch (err) {
        setError(err);
        setQrCode(staticQrCode());
      } finally {
        setLoading(false);
        setLoaded(true);
      }
    };
    if (config) {
      load();
    }
  }, [config]);

  return {
    error,
    qrCode,
    loading,
    loaded,
    type
  };
};
