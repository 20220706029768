import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { CmsCardPanelCardTakeover } from "../../CardPanel";
import { CardCopy } from "../CardCopy";
import cx from "classnames";
import { Colour } from "../../constants";
import { CardImage } from "../CardImage";

export interface CardTakeoverProps {
  shown: boolean;
  cardTakeover: CmsCardPanelCardTakeover;
  toggleCardTakeover: () => void;
  hideImageOnMobile?: boolean;
  singleCardRow?: boolean;
}

export const CardTakeover: FC<CardTakeoverProps> = ({
  shown,
  cardTakeover,
  toggleCardTakeover = () => {},
  hideImageOnMobile = false,
  singleCardRow = false
}) => {
  const colour = () => cardTakeover?.backgroundColour || Colour.fuschiaPunch;
  const [clickableTakeover, setClickableTakeover] = useState(false);
  const takeoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (takeoverRef.current) {
      setClickableTakeover(!takeoverRef.current.querySelectorAll("a").length);
    }
  }, []);

  const handleClick = () => {
    if (shown && clickableTakeover) {
      toggleCardTakeover();
    }
  };

  return (
    <div
      ref={takeoverRef}
      onClick={handleClick}
      className={cx(
        styles.cardTakeover,
        {
          [styles.shown]: shown,
          [styles.clickable]: clickableTakeover
        },
        styles[colour()]
      )}
    >
      <div
        className={cx(
          styles.overlay,
          { [styles.shown]: shown },
          styles[colour()]
        )}
      />
      <div
        className={cx(styles.inner, { [styles.singleCardRow]: singleCardRow })}
      >
        <CardCopy
          inTakeover
          backgroundColour={cardTakeover.backgroundColour}
          title={cardTakeover.title}
          subtitle={cardTakeover.subtitle}
          bodyCopy={cardTakeover.bodyCopy}
          richText={cardTakeover.richText}
          className={styles.cardCopy}
          singleCardRow={singleCardRow}
        />

        {cardTakeover.image && singleCardRow && (
          <CardImage
            imageFit={cardTakeover.imageFit}
            image={cardTakeover.image}
            hideImageOnMobile={hideImageOnMobile}
          />
        )}
      </div>
    </div>
  );
};
