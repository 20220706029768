import React, { FC, useEffect, useState } from "react";
import { AlphabetisedKey } from "../hooks/useSortGlossaryTerms";
import { Panel, PanelItem, PanelLayout } from "../../Panel";
import { Colour } from "../../constants";
import styles from "./styles.module.scss";
import { GlossaryMobileNavigation } from "./GlossaryMobileNavigation";
import { GlossaryDesktopNavigation } from "./GlossaryDesktopNavigation";
import sortBy from "lodash/sortBy";
import throttle from "lodash/throttle";

export interface GlossaryNavigationPropsBase {
  links: AlphabetisedKey[];
}

export const GlossaryNavigation: FC<GlossaryNavigationPropsBase> = ({
  links
}) => {
  const [activeScrollLink, setActiveScrollLink] = useState(links[0]);
  const navigationBarsOffset = 300;

  const configureActiveScrollLink = () => {
    let offsets: { hash: AlphabetisedKey; offset: number }[] = [];

    document.querySelectorAll(`[data-role="alpha-key"]`).forEach(el => {
      offsets.push({
        hash: el.getAttribute("data-id") as AlphabetisedKey,
        offset: Math.abs(el.getBoundingClientRect().top - navigationBarsOffset)
      });
    });

    setActiveScrollLink(sortBy(offsets, ["offset"])[0]?.hash);
  };

  useEffect(() => {
    const callback = throttle(configureActiveScrollLink, 100);

    window.addEventListener("scroll", callback);

    return () => window.removeEventListener("scroll", callback);
  }, []);

  return (
    <Panel
      className={styles.glossaryNavigationPanel}
      colour={Colour.warmStone}
      layout={PanelLayout.full}
      short
    >
      <PanelItem className={styles.glossaryNavigationItem}>
        <GlossaryDesktopNavigation
          links={links}
          activeScrollLink={activeScrollLink}
        />
        <GlossaryMobileNavigation
          links={links}
          activeScrollLink={activeScrollLink}
        />
      </PanelItem>
    </Panel>
  );
};
