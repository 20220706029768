import React, { FC } from "react";
import { Colour } from "../constants";
import styles from "./styles.module.scss";
import cx from "classnames";
import { CardBackgroundImage } from "components/CardBackgroundImage";
import { useRichText } from "../../hooks/useRichText";
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData
} from "gatsby-source-contentful/rich-text";
import { useIsBlogPage } from "hooks/useIsBlogPage";
import { CmsImage, CmsResponsiveImage } from "../Blog";

export interface CmsFourCardPanelCard {
  id: string;
  title?: string;
  subtitle?: string;
  iconImage?: CmsImage;
  backgroundImage?: CmsResponsiveImage;
  backgroundColour: Colour;
  className?: string;
  richText?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
}

export const FourCardPanelCard: FC<CmsFourCardPanelCard> = ({
  title,
  subtitle,
  backgroundImage,
  backgroundColour,
  className,
  richText,
  iconImage
}) => {
  const shouldPad = () => {
    return backgroundImage && (title || subtitle || richText);
  };

  const isBlog = useIsBlogPage();

  return (
    <div
      className={cx(styles.card, styles[backgroundColour], className, {
        [styles.noImage]: !backgroundImage,
        [styles.blogCard]: isBlog
      })}
    >
      <div
        className={cx(styles.copy, {
          [styles.hasBackground]: shouldPad()
        })}
      >
        {iconImage && (
          <img
            className={styles.icon}
            alt={iconImage.title}
            src={iconImage?.file?.url}
          />
        )}
        {title && <h5 className={styles.title}>{title}</h5>}
        {subtitle && <p>{subtitle}</p>}
        {richText && <>{useRichText(richText)}</>}
      </div>

      {backgroundImage && <CardBackgroundImage image={backgroundImage} />}
    </div>
  );
};
