export type Tuple<T = any> = [string, string];

export const utmParameters = [
  "utm_campaign",
  "utm_source",
  "utm_medium",
  "utm_term",
  "utm_content"
];

export type UtmParams = Partial<{
  campaign: string;
  source: string;
  medium: string;
  term: string;
  content: string;
}>;

export const splitQueryString = (queryString: string) =>
  queryString
    .replace(/^\?/, "")
    .split("&")
    .map(pair => pair.split("=").map(decodeURIComponent) as Tuple<string>);

export const isUtmParameter = (key: string) => utmParameters.includes(key);
export const filterUtmParameters = (queryParams: Tuple<string>[]): UtmParams =>
  queryParams
    .filter(([key]) => isUtmParameter(key))
    .reduce((obj, [key, value]) => {
      obj[key.replace(/^utm_/, "")] = value;
      return obj;
    }, {} as UtmParams);

export const getUtmParameters = (queryString = window.location.search) => {
  if (!window.mettleUtm) {
    window.mettleUtm = filterUtmParameters(splitQueryString(queryString));
  }
  return window.mettleUtm;
};

declare global {
  interface Window {
    mettleUtm?: UtmParams;
  }
}
