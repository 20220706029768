import * as Sentry from "@sentry/gatsby";

export default (e: any) => {
  const EXEMPTED_ERRORS = [
    "Error: Request blocked by client, probably adblock"
  ];

  if (EXEMPTED_ERRORS.indexOf(e.toString()) === -1) {
    Sentry.captureException(e);
  }
};
