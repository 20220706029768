import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { DefaultLayout } from "layouts";
import { graphql } from "gatsby";
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData
} from "gatsby-source-contentful/rich-text";
import styles from "./styles.module.scss";
import { Colour } from "components/constants";
import { EligibilityHeader } from "components/EligibilityHeader";
import { SiteFooter } from "components/SiteFooter";
import { RichText } from "components/RichText";

interface AccountServicesPageProps {
  data: {
    page: {
      title: string;
      intro: RenderRichTextData<ContentfulRichTextGatsbyReference>;
      contactTable: {
        contactTable: string;
      };
      accountAccessIntro: RenderRichTextData<ContentfulRichTextGatsbyReference>;
      accountAccessTable: {
        accountAccessTable: string;
      };
      operationalSecurityIntro: RenderRichTextData<ContentfulRichTextGatsbyReference>;
      operationalSecurityTable: {
        operationalSecurityTable: string;
      };
      complaintsIntro: RenderRichTextData<ContentfulRichTextGatsbyReference>;
      openAccountTable: {
        openAccountTable: string;
      };
      body: RenderRichTextData<ContentfulRichTextGatsbyReference>;
    };
  };
}

interface ScrollableMarkdownProps {
  content: string;
}

const ScrollableMarkdown = ({ content }: ScrollableMarkdownProps) => (
  <div className={styles.tableContainer}>
    <ReactMarkdown remarkPlugins={[remarkGfm]} children={content} />
  </div>
);

export const AccountServicesPage: React.FC<AccountServicesPageProps> = ({
  data: { page }
}) => (
  <DefaultLayout title={page.title} background={Colour.white} footer={false}>
    <EligibilityHeader title={page.title} />
    <RichText body={page.intro} />
    <ScrollableMarkdown content={page.contactTable.contactTable} />
    <RichText body={page.accountAccessIntro} />
    <ScrollableMarkdown content={page.accountAccessTable.accountAccessTable} />
    <RichText body={page.operationalSecurityIntro} />
    <ScrollableMarkdown
      content={page.operationalSecurityTable.operationalSecurityTable}
    />
    <RichText body={page.complaintsIntro} />
    <ScrollableMarkdown content={page.openAccountTable.openAccountTable} />
    <RichText body={page.body} />
    <SiteFooter />
  </DefaultLayout>
);

AccountServicesPage.displayName = "AccountServicesPage";

export default AccountServicesPage;

export const pageQuery = graphql`
  query AccountServicesPageQuery {
    page: contentfulAccountServicesPage {
      title
      intro {
        raw
      }
      contactTable {
        contactTable
      }
      accountAccessIntro {
        raw
      }
      accountAccessTable {
        accountAccessTable
      }
      operationalSecurityIntro {
        raw
      }
      operationalSecurityTable {
        operationalSecurityTable
      }
      complaintsIntro {
        raw
      }
      openAccountTable {
        openAccountTable
      }
      body {
        raw
      }
    }
  }
`;
