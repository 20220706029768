import React, { FC } from "react";
import YAMLData from "content/404.yaml";
import { DefaultLayout } from "layouts";
import { Link } from "components/Link";
import { Panel, PanelItem, PanelLayout } from "components/Panel";
import error404 from "assets/illustrations/404.png";
import styles from "./styles.module.scss";
import { Colour } from "components/constants";

const ErrorPage: FC = () => (
  <DefaultLayout background={Colour.warmStone}>
    <Panel layout={PanelLayout.full} colour={Colour.warmStone}>
      <PanelItem>
        <div className={styles.container}>
          <img
            src={error404}
            alt="404 - Page Not Found - Cactus"
            className={styles.illustration}
          />
          <h2>{YAMLData.title}</h2>
          <p>{YAMLData.content}</p>
          <Link url="/">{YAMLData.link}</Link>
        </div>
      </PanelItem>
    </Panel>
  </DefaultLayout>
);

export default ErrorPage;
