import { MutableRefObject, useEffect } from "react";

/**
 * On mount, capture the currently focussed element and store it within
 * the supplied ref. On unmount, the captured element will be focussed.
 */
export const useRestoreFocusOnUnmount = (
  previousFocus: MutableRefObject<HTMLElement | null>
) => {
  useEffect(() => {
    if (previousFocus.current) return;
    previousFocus.current = document.activeElement as HTMLElement;
    return () => {
      setTimeout(() => {
        if (!previousFocus.current) return;
        previousFocus.current.focus();
        previousFocus.current = null;
      }, 0);
    };
  }, []);
};
