import { Colour } from "../components/constants";

export const useIsDarkBackground = (colour: Colour | undefined) =>
  [
    Colour.softBlack,
    Colour.black,
    Colour.teal100,
    Colour.olive100,
    Colour.black90
  ].includes(Colour[colour!]);

export const useIsPrimaryBackground = (colour: Colour | undefined) =>
  [Colour.cyan, Colour.limeSorbet, Colour.fuschiaPunch].includes(
    Colour[colour!]
  );
