import React, {
  createContext,
  createElement,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useOpenAccountHash } from "./useDocumentHash";

export interface OnboardingContextValue {
  isVisible: boolean;

  show(): void;

  hide(): void;
}

const OnboardingContext = createContext<OnboardingContextValue>({
  isVisible: false,
  show: () => {},
  hide: () => {}
});

export const useOnboardingState = (): OnboardingContextValue =>
  useContext(OnboardingContext);

export const useOnboardingStateValue = (): OnboardingContextValue => {
  const showOpenAccountModal = useOpenAccountHash();
  const [isVisible, setVisible] = useState<boolean>(false);
  useEffect(() => setVisible(showOpenAccountModal), [showOpenAccountModal]);
  const show = useMemo(() => () => setVisible(true), []);
  const hide = useMemo(() => () => setVisible(false), []);
  return { isVisible, show, hide };
};

export const OnboardingProvider: FC<{ children: React.ReactNode }> = ({
  children
}) =>
  createElement(
    OnboardingContext.Provider,
    { value: useOnboardingStateValue() },
    children
  );

OnboardingProvider.displayName = "OnboardingProvider";
