import React, { FC, useEffect, useMemo } from "react";
import {
  CmsInfoAccordionItem,
  InfoAccordionFormat
} from "components/InfoAccordion";
import { Panel, PanelItem, PanelLayout } from "../Panel";
import { Colour } from "../constants";
import {
  AlphabetisedKey,
  useSortGlossaryTerms
} from "./hooks/useSortGlossaryTerms";
import { GlossaryNavigation } from "./GlossaryNavigation";
import styles from "./styles.module.scss";
import { InfoAccordionItem } from "../InfoAccordion/InfoAccordionItem";

export interface GlossaryProps {
  definitions: CmsInfoAccordionItem[];
  title: string;
  format: InfoAccordionFormat;
}

export const Glossary: FC<GlossaryProps> = ({ definitions, title, format }) => {
  const sortedGlossaryTerms = useMemo(() => {
    return useSortGlossaryTerms(definitions);
  }, [definitions]);

  // SEE: https://eeveebank.atlassian.net/browse/MW-120
  // Whilst we have anchor links already, due to odd behaviour in safari
  // this workaround is required for bookmarked items where the root url is already in the
  // history stack. This may change in subsequent safari versions.
  useEffect(() => {
    const hash = window.location.hash.split("#")[1];
    const el =
      document.querySelectorAll(`[data-id="${hash}"]`)[0] ||
      document.getElementById(hash);

    if (el) {
      el.scrollIntoView();
    }
  }, []);

  return (
    <>
      <GlossaryNavigation
        links={Object.keys(sortedGlossaryTerms) as AlphabetisedKey[]}
      />
      <Panel
        className={styles.glossary}
        colour={Colour.warmStone}
        layout={PanelLayout.full}
        short
      >
        <PanelItem>
          <div
            itemScope
            itemType="https://schema.org/DefinedTermSet"
            id="https://www.mettle.co.uk/glossary/"
            className={styles.glossaryContainer}
          >
            <span itemProp="name" hidden>
              {title}
            </span>
            {Object.keys(sortedGlossaryTerms).map(
              (alphaKey: AlphabetisedKey) => {
                return (
                  <div
                    className={styles.alphabetisedSection}
                    id={alphaKey}
                    key={alphaKey}
                  >
                    <h2
                      key={alphaKey}
                      className={styles.sectionHeading}
                      data-role="alpha-key"
                      data-id={alphaKey}
                    >
                      {alphaKey}
                    </h2>
                    {sortedGlossaryTerms[alphaKey].sortedTerms.map(
                      (term: CmsInfoAccordionItem) => {
                        return (
                          <InfoAccordionItem
                            format={format}
                            key={term.id}
                            {...term}
                          />
                        );
                      }
                    )}
                    <span data-role="alpha-key" data-id={alphaKey}></span>
                  </div>
                );
              }
            )}
          </div>
        </PanelItem>
      </Panel>
    </>
  );
};

Glossary.displayName = "Glossary";
