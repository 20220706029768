// TODO showButton prop is never set to false anywhere in the code?

import React, { FC, useEffect, useState } from "react";
import { useClassNameOverride } from "@mettle/hooks";
import { SiteFooter } from "components/SiteFooter";
import { Header } from "components/Header";
import { SEO, SEOProps } from "components/SEO";
import { OnboardingContainer } from "components/OpenAccount/OnboardingContainer";
import { OnboardingProvider, DefaultAnalyticsProvider } from "hooks";
import { Colour } from "components/constants";
import "styles/main.module.scss";
import styles from "./styles.module.scss";

export interface DefaultLayoutProps extends SEOProps {
  header?: boolean;
  footer?: boolean;
  showButton?: boolean;
  background?: Colour;
  transparentHeader?: boolean;
  aboveFoldExperimentation?: boolean;
  children?: React.ReactNode;
}

export const DefaultLayout: FC<DefaultLayoutProps> = ({
  children,
  header = true,
  footer = true,
  showButton = true,
  transparentHeader = false,
  background = Colour.warmStone,
  aboveFoldExperimentation,
  ...seoProps
}) => {
  const [hidden, setHidden] = useState(aboveFoldExperimentation);
  useClassNameOverride(styles[background], true);

  useEffect(() => {
    setHidden(
      !document.cookie.match("OptanonAlertBoxClosed") &&
        aboveFoldExperimentation
    );
  }, []);

  return (
    <DefaultAnalyticsProvider>
      <OnboardingProvider>
        <SEO {...seoProps}>
          {header && (
            <Header showButton={showButton} transparent={transparentHeader} />
          )}
          <a id="skip-navigation" className={styles.skipNavigation}>
            Skip navigation
          </a>
          <div
            id="main"
            style={{
              filter: hidden ? "blur(25px)" : "none",
              transition: "filter 150ms ease"
            }}
          >
            {children}
            {footer && <SiteFooter />}
          </div>
          <OnboardingContainer />
        </SEO>
      </OnboardingProvider>
    </DefaultAnalyticsProvider>
  );
};
DefaultLayout.displayName = "DefaultLayout";
