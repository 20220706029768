import React, { FC } from "react";
import { Colour } from "../constants";
import { Panel, PanelItem, PanelLayout } from "../Panel";
import { Link } from "../Link";
import arrowRight from "assets/icons/arrow-right-punch.svg";
import styles from "./styles.module.scss";
import SVG from "react-inlinesvg";
import cx from "classnames";

export type BreadCrumbDataType = { title: string; slug: string };

export interface BreadCrumbsProps {
  category: BreadCrumbDataType;
  post?: BreadCrumbDataType;
}

const Icon = ({ className }: { className?: string }) => (
  <SVG src={arrowRight} className={cx(styles.icon, className)} />
);

export const BreadCrumbs: FC<BreadCrumbsProps> = ({ category, post }) => {
  return (
    <Panel
      data-testid="breadcrumbs"
      colour={Colour.softBlack}
      layout={PanelLayout.full}
      short
    >
      <PanelItem className={styles.breadCrumbs}>
        {category && (
          <>
            <Link allowActive={false} url="/blog/">
              Content Hub
            </Link>
            <Icon />
            <Link allowActive={false} url={`/blog/${category.slug}/`}>
              {category.title}
            </Link>
            {post && (
              <>
                <Icon className={styles.lastIcon} />
                <Link
                  className={styles.lastLink}
                  allowActive={false}
                  url={`/blog/${post.slug}/`}
                >
                  {post.title}
                </Link>
              </>
            )}
          </>
        )}
      </PanelItem>
    </Panel>
  );
};

BreadCrumbs.displayName = "BreadCrumbs";
