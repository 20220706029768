import React from "react";
import { useIsClient } from "@mettle/hooks";
import { TocLinkList } from "./TocLinkList";
import styles from "./TableOfContents.module.scss";
import { CmsBlogPostRichTextSection } from "../../BlogHome/BlogPostRichTextSection";
import { useGenerateTocHeadings } from "hooks/useGenerateTocHeadings";

interface Props {
  sections: CmsBlogPostRichTextSection[];
}

export const TableOfContents: React.FC<Props> = ({ sections }) => {
  const isClient = useIsClient();
  const headings = useGenerateTocHeadings(sections);

  if (!isClient) return null;

  return (
    <div data-testid="toc" className={styles.tableOfContents}>
      <div className={styles.toc}>
        <h5>Content</h5>
        <TocLinkList headings={headings} />
      </div>
    </div>
  );
};
