import React, { EventHandler, forwardRef, Ref } from "react";
import { GatsbyLinkProps, Link as GatsbyLink } from "gatsby";
import cx from "classnames";
import { useLinkIsExternal, useClickHandler, useCleanUrl } from "./hooks";
import styles from "./styles.module.scss";
import { Colour } from "../constants";
import {
  useIsDarkBackground,
  useIsPrimaryBackground
} from "../../hooks/useBackgroundColourUtils";

export interface LinkProps<
  V extends React.SyntheticEvent<HTMLAnchorElement, any>
> extends Omit<GatsbyLinkProps<any>, "onClick" | "to"> {
  url?: string;
  newTab?: boolean;
  onClick?: EventHandler<V>;
  className?: string;
  activeClassName?: string;
  allowPartialActive?: boolean;
  allowActive?: boolean;
  ref?: Ref<HTMLAnchorElement>;
  backgroundColour?: Colour;
  secondary?: boolean;
  children?: React.ReactNode;
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps<any>>(
  (
    {
      url,
      newTab,
      onClick,
      children,
      className,
      activeClassName,
      allowPartialActive = true,
      allowActive = true,
      tabIndex = 0,
      backgroundColour = Colour.warmStone,
      secondary,
      ...anchorProps
    },
    ref
  ) => {
    const external = useLinkIsExternal(url, newTab);
    const handleClick = useClickHandler(url, onClick, external);
    const cleanUrl = useCleanUrl(url, external);
    const colorStyles = () => {
      return {
        [styles.darkBackground]: useIsDarkBackground(backgroundColour),
        [styles.primaryBackground]: useIsPrimaryBackground(backgroundColour),
        [styles.secondary]: Boolean(secondary)
      };
    };

    const commonProps: Partial<LinkProps<any>> = {
      ref,
      children,
      className: cx(styles.link, colorStyles(), className),
      onClick: handleClick,
      onKeyDown: event => event.key === "Enter" && handleClick(event),
      tabIndex: tabIndex,
      ...(external ? { rel: "noopener", target: "_self" } : {}),
      ...anchorProps
    };

    if (external) {
      return <a href={cleanUrl} {...commonProps} />;
    }

    const activeCx: string = allowActive
      ? cx(styles.activeLink, activeClassName)
      : "";

    return (
      // @ts-ignore `ref` in commonProps is not _guaranteed_ compatible by type signature
      <GatsbyLink
        to={cleanUrl}
        activeClassName={activeCx}
        getProps={({ isCurrent, isPartiallyCurrent }) => {
          const isActive =
            url &&
            (isCurrent ||
              (url !== "/" && isPartiallyCurrent && allowPartialActive));
          return {
            className: cx(
              styles.link,
              {
                [activeCx]: isActive
              },
              colorStyles(),
              className
            )
          };
        }}
        {...commonProps}
      />
    );
  }
);
