import React from "react";
import kebabCase from "lodash/kebabCase";
import { useIsClient, useScrollPosition } from "@mettle/hooks";
import { TocLink } from "./TocLink";
import styles from "./TocLinkList.module.scss";

interface Props {
  headings: string[];
}

const getPageHeadingOffsets = (headings: string[]): number[] =>
  headings.map(heading => {
    const el = document.getElementById(kebabCase(heading));
    return el?.offsetTop || Number.MAX_VALUE;
  });

export const getActiveIndex = (
  scrollPosition: number,
  offsets: number[],
  wnd: Window = window
) => {
  // Cut-off line for marking the upcoming heading as active
  const scrollOffset = wnd.innerHeight * 0.2;
  return offsets.reduce(
    (prev, current, index) =>
      scrollPosition + scrollOffset > current ? index : prev,
    0
  );
};

export const TocLinkList: React.FC<Props> = ({ headings }) => {
  const isClient = useIsClient();
  const scroll = useScrollPosition(isClient ? window : null);
  if (!isClient) return null;

  const offsets = getPageHeadingOffsets(headings);
  const activeIndex = getActiveIndex(scroll, offsets);

  return (
    <div data-testid="toc-link-list" className={styles.tocLinkList}>
      {headings.map((heading, index) => (
        <TocLink
          key={heading}
          heading={heading}
          active={index === activeIndex}
        />
      ))}
    </div>
  );
};
