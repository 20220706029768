import { useMemo } from "react";
import { useIsClient } from "@mettle/hooks";

export const useRootElement = (): HTMLDivElement | null => {
  const isClient = useIsClient();
  return useMemo(
    () =>
      isClient
        ? (document.querySelector("#___gatsby") as HTMLDivElement)
        : null,
    [isClient]
  );
};
