import React, { FC } from "react";
import { CmsCategory } from "../Blog";
import { graphql } from "gatsby";
import { BlogCategories } from "../Blog/BlogHome/BlogCategories";
import { SearchBox } from "../SearchBox";
import { Panel, PanelItem, PanelLayout } from "../Panel";
import { Colour } from "../constants";
import { useIsClient } from "@mettle/hooks";
import { CmsPageSectionBase } from "../CardPanel";

export interface CmsContentHubNavBarSection extends CmsPageSectionBase {
  id: string;
  categories: CmsCategory[];
  slug: string;
}

export const ContentHubNav: FC<CmsContentHubNavBarSection> = ({
  categories,
  slug
}) => {
  const isClient = useIsClient();

  return (
    <>
      <Panel layout={PanelLayout.full} colour={Colour.warmStone} short>
        <PanelItem>
          <BlogCategories slug={slug} categories={categories} />
        </PanelItem>
      </Panel>
      {isClient && !window.location.pathname.match(/search/) && <SearchBox />}
    </>
  );
};

export const ContentfulContentHubNavBarFragment = graphql`
  fragment ContentfulContentHubNavBarFragment on ContentfulContentHubNavBar {
    contentful_id
    categories {
      id
      slug
      title
    }
  }
`;

ContentHubNav.displayName = "ContentHubNav";
