import React from "react";
import styles from "./styles.module.scss";

interface Props {
  title: string;
  subTitle?: string;
}

export const EligibilityHeader: React.FC<Props> = ({ title, subTitle }) => (
  <div className={styles.eligibilityHeader}>
    <div className={styles.inner}>
      <h1>{title}</h1>
      <h2>{subTitle}</h2>
    </div>
  </div>
);

EligibilityHeader.displayName = "EligibilityHeader";
