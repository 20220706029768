import React, { FC, useEffect, useState } from "react";
import { IconographyButton } from "components/IconographyButton";
import share from "assets/icons/share.svg";
import styles from "./styles.module.scss";
import cx from "classnames";
import { useIsClient } from "@mettle/hooks";

export interface ShareButtonProps {
  url: string;
  title: string;
  subtitle: string;
}

interface TooltipProps {
  text: string;
}

export const copyToClipboard = (value?: string): void => {
  const el = document.createElement("textarea");

  if (value) {
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }
};

export const Tooltip: React.FC<TooltipProps> = ({ text }) => (
  <div className={styles.tooltip}>
    <div className={styles.text}>{text}</div>
    <div className={styles.triangle} />
  </div>
);
Tooltip.displayName = "Tooltip";

export const ShareButton: FC<ShareButtonProps> = ({ url, title, subtitle }) => {
  const [copied, setCopied] = useState(false);
  const [canShare, setCanShare] = useState(false);
  const isClient = useIsClient();

  const copyLink = React.useCallback((value?: string) => {
    copyToClipboard(value);
    setCopied(true);
    const timer = setTimeout(() => setCopied(false), 2500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isClient) {
      setCanShare(Boolean(navigator["share"]));
    }
  }, [isClient]);

  const handleClick = async () => {
    const data = {
      title,
      text: subtitle,
      url
    };

    if (canShare) {
      try {
        await navigator.share(data);
      } catch {
        // ignoring this, though we need to catch it to avoid run time errors if the user cancels a share
      }
    } else {
      copyLink(url);
    }
  };

  const generatedTitle = () => `Share ${title}`;

  return (
    <span
      className={cx(styles.shareButtonContainer, {
        [styles.icon]: true,
        [styles.copy]: true,
        [styles.copied]: copied
      })}
    >
      <IconographyButton
        icon={share}
        handler={handleClick}
        value={generatedTitle()}
        className={styles.shareButton}
      />
      {!canShare && <Tooltip text={copied ? "Copied" : "Copy link"} />}
    </span>
  );
};

ShareButton.displayName = "ShareButton";
