export enum Env {
  TEST = "TEST",
  PROD = "PROD"
}

export enum OnboardingProcess {
  EMAIL = "EMAIL",
  QR = "QR"
}

export interface EnvConfig {
  ENV: Env;
  BRANCH_IO_KEY: string;
  BRANCH_IO_ENABLED: boolean;
  ONBOARDING_PROCESS: OnboardingProcess;
}

type EnvConfigProvider = () => EnvConfig;

export const getDefaultConfig: EnvConfigProvider = () => ({
  ENV: Env.TEST,
  BRANCH_IO_KEY: "key_test_gpSJGAwRvNhg7xipkEj4IfdfrqcRvVdS",
  BRANCH_IO_ENABLED: true,
  ONBOARDING_PROCESS: OnboardingProcess.QR
});

export const getProdConfig: EnvConfigProvider = () => ({
  ENV: Env.PROD,
  BRANCH_IO_KEY: "key_live_jnMGSqDRCGic6tpcjqd8LhljrFnUCQi0",
  BRANCH_IO_ENABLED: true,
  ONBOARDING_PROCESS: OnboardingProcess.QR
});
