import React, { FC } from "react";
import { NumberSize } from "../constants";
import cx from "classnames";
import { Number } from "components/Number";
import styles from "./styles.module.scss";

type NumberedListProps = {
  listItems: Array<string>;
  size?: NumberSize;
  className?: string;
};

export const NumberedList: FC<NumberedListProps> = ({
  listItems,
  size = NumberSize.small,
  className = ""
}) => (
  <ol
    data-testid="numbered-list"
    className={cx(styles.numberedList, className)}
  >
    {listItems.map((item, index) => (
      <li className={styles.listItem} key={index}>
        <Number number={index + 1} size={size} className={styles.number} />
        <p className={styles.text}>{item}</p>
      </li>
    ))}
  </ol>
);
NumberedList.displayName = "NumberedList";
