import React, { FC, useEffect, useState } from "react";
import { CmsVideo } from "components/Blog";
import { Panel, PanelItem, PanelLayout } from "components/Panel";
import { Colour, SectionPaddingDefault } from "../constants";
import { graphql } from "gatsby";
import cx from "classnames";
import styles from "./styles.module.scss";
import { CmsAsset, CmsPageSectionBase } from "../CardPanel";
import { Video } from "../Video";

export interface CmsEmbeddedVideoSection extends CmsPageSectionBase {
  title?: string;
  bodyCopy?: string;
  backgroundColour?: Colour;
  video: CmsVideo;
  poster: CmsAsset;
  mobileVideo?: CmsVideo;
  mobileVideoPoster?: CmsAsset;
}

export const EmbeddedVideoSection: FC<CmsEmbeddedVideoSection> = ({
  title,
  bodyCopy,
  video,
  poster,
  backgroundColour = Colour.warmStone,
  sectionPaddingBottom,
  sectionPaddingTop,
  mobileVideo,
  mobileVideoPoster
}) => {
  const [played, setPlayed] = useState(false);

  useEffect(() => {
    const setClicked = () => setPlayed(false);

    window.addEventListener("resize", setClicked);

    return () => window.removeEventListener("resize", setClicked);
  }, []);

  return (
    <Panel
      layout={PanelLayout.full}
      colour={Colour[backgroundColour]}
      className={styles.embeddedVideoSection}
      short
    >
      <PanelItem
        className={cx(
          styles.item,
          styles[sectionPaddingTop || SectionPaddingDefault.TOP],
          styles[sectionPaddingBottom || SectionPaddingDefault.BOTTOM]
        )}
      >
        {(title || bodyCopy) && (
          <div data-testid="copy" className={styles.copy}>
            {title && <h4 className={styles.title}>{title}</h4>}
            {bodyCopy && (
              <p className={styles.bodyCopy} data-testid="copy">
                {bodyCopy}
              </p>
            )}
          </div>
        )}
        <div
          data-testid="container"
          className={cx(styles.videoContent, { [styles.played]: played })}
          onClick={() => {
            setPlayed(true);
          }}
        >
          <Video
            className={cx(styles.video)}
            video={video}
            videoPoster={poster}
            mobileVideo={mobileVideo}
            mobileVideoPoster={mobileVideoPoster}
            play={played}
          />
        </div>
      </PanelItem>
    </Panel>
  );
};

export const ContentfulEmbeddedVideoSectionFragment = graphql`
  fragment ContentfulEmbeddedVideoSectionFragment on ContentfulEmbeddedVideoSection {
    __typename
    contentful_id
    title
    bodyCopy
    backgroundColour
    sectionPaddingBottom
    sectionPaddingTop
    poster {
      file {
        url
      }
    }
    video {
      title
      description
      file {
        url
        fileName
        contentType
        details {
          size
        }
      }
    }
    mobileVideoPoster {
      file {
        url
      }
    }
    mobileVideo {
      title
      description
      file {
        url
        fileName
        contentType
        details {
          size
        }
      }
    }
  }
`;
