import React, { FC } from "react";
import { Panel, PanelItem, PanelLayout } from "../Panel";
import { Colour } from "../constants";
import { CmsAuthor } from "../Blog";
import styles from "./styles.module.scss";
import { AuthorImage, AuthorImageSize } from "../AuthorImage";
import { AuthorLinkedInIcon } from "../AuthorLinkedInIcon";
import { useBlogRichText } from "../Blog/hooks";
import { AuthorPostsCarousel } from "../AuthorPostsCarousel";
import { AuthorHiddenSeo } from "../AuthorHiddenSeo";
import { BlogPostRichTextSection } from "../Blog/BlogHome/BlogPostRichTextSection";
import { DynamicPageSections } from "../ComposedPage/DynamicPageSections";

interface AuthorPageProps {
  author: CmsAuthor;
}

export const AuthorPage: FC<AuthorPageProps> = ({
  author: {
    name,
    company,
    knowsAbout,
    linkedInUrl,
    bio,
    extendedBio,
    blog_post,
    jobTitle,
    photo,
    slug,
    sections,
    website
  }
}) => {
  return (
    <>
      <Panel
        className={styles.authorPage}
        colour={Colour.warmStone}
        layout={PanelLayout.full}
        short
      >
        <PanelItem itemScope itemType="http://schema.org/Person">
          <div className={styles.hero}>
            <div className={styles.details}>
              <h1 itemProp="name">{name}</h1>
              <p>
                <span itemProp="jobTitle">{jobTitle}</span>
                &nbsp;at&nbsp;
                <span
                  itemProp="worksFor"
                  itemScope
                  itemType="http://schema.org/Organization"
                >
                  <span itemProp="name">{company}</span>
                  {website && <a itemProp="url" href={website} hidden />}
                </span>
              </p>
              {linkedInUrl && (
                <AuthorLinkedInIcon
                  linkedInUrl={linkedInUrl}
                  name={name}
                  className={styles.linkedIn}
                />
              )}
            </div>
            <AuthorImage
              className={styles.authorImage}
              photo={photo}
              name={name}
              size={AuthorImageSize.LARGE}
            />
          </div>
          <div itemProp="description">
            <div className={styles.bio}>{useBlogRichText(bio)}</div>
            {extendedBio && (
              <BlogPostRichTextSection __typename="" body={extendedBio} />
            )}
          </div>
          <AuthorHiddenSeo
            knowsAbout={knowsAbout}
            linkedInUrl={linkedInUrl}
            slug={slug}
          />
        </PanelItem>
      </Panel>
      {sections && <DynamicPageSections sections={sections} slug={slug} />}
      {blog_post && (
        <AuthorPostsCarousel authorName={name} blogPosts={blog_post} />
      )}
    </>
  );
};

AuthorPage.displayName = "AuthorPage";
