import React, { FC } from "react";
import { graphql } from "gatsby";
import { CmsPage, ComposedPage } from "components/ComposedPage";
import { BlogList } from "../components/Blog/BlogList";
import { CmsPageSectionType } from "../components/ComposedPage/types";
import { CmsBlogPost } from "../components";
import { useSortPosts } from "../components/Blog/hooks";

interface page extends CmsPage {
  blog_post: CmsBlogPost[];
  contentful_id?: string;
}

export interface BlogCategoryPageProps {
  data: {
    page: page;
  };
}

export const BlogHomeTemplate: FC<BlogCategoryPageProps> = ({
  data: { page }
}) => {
  const posts = useSortPosts(
    page.blog_post.filter(
      ({ slug }) =>
        [
          "test-article",
          "test-video-blog",
          "test-article-do-not-remove",
          "privacy-notice-pps",
          "privacy-notice-genie"
        ].indexOf(slug) === -1
    )
  );

  return (
    <ComposedPage
      title={page.metaTitle || page.title}
      metaTitle={page.metaTitle}
      metaDescription={page.metaDescription}
      sections={page.sections}
      slug={page.slug}
    >
      <div id={`s${page.contentful_id}`}>
        <BlogList
          __typename={CmsPageSectionType.Category}
          id={page.slug}
          title={page.title}
          slug={page.slug}
          blogPosts={posts}
        />
      </div>
    </ComposedPage>
  );
};
export default BlogHomeTemplate;

export const pageQuery = graphql`
  query getBlogCategoryPage($id: String!) {
    page: contentfulCategory(id: { eq: $id }) {
      title
      slug
      contentful_id
      metaTitle
      metaDescription
      sections {
        __typename
        ... on Node {
          ...ContentfulCardPanelFragment
          ...ContentfulPageHeroFragment
          ...ContentfulLatestPostsCarouselFragment
          ...ContentfulPageCarouselFragment
          ...ContentfulContentHubNavBarFragment
        }
      }
      blog_post {
        ...ContentfulBlogPost
      }
    }
  }
`;
