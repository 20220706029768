import { Options } from "@contentful/rich-text-react-renderer";
import {
  ContentfulRichTextGatsbyReference,
  renderRichText,
  RenderRichTextData
} from "gatsby-source-contentful/rich-text";

import { useMemo, ReactElement } from "react";

export type ReactPostProcessor = (node: ReactElement[]) => ReactElement[];

export const useRichText = (
  content: RenderRichTextData<ContentfulRichTextGatsbyReference>,
  options?: Options,
  post: ReactPostProcessor = x => x
) =>
  useMemo(
    () => content && post(renderRichText(content, options) as ReactElement[]),
    [content, options, post]
  );

export const useRichTextWithOptions = (
  options: Options,
  post?: ReactPostProcessor
) => (content: RenderRichTextData<ContentfulRichTextGatsbyReference>) =>
  useRichText(content, options, post);
