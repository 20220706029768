import React, { FC, useEffect, useRef, useState } from "react";
import {
  CmsInfoAccordionItem,
  InfoAccordionFormat
} from "components/InfoAccordion";
import { useBlogRichText } from "../../Blog/hooks";
import styles from "./styles.module.scss";
import { IconographyButton } from "../../IconographyButton";
import cross from "assets/icons/crossWhite.svg";
import cx from "classnames";
import { Link } from "../../Link";
import snakeCase from "lodash/snakeCase";
import lowerCase from "lodash/lowerCase";
import { Video } from "../../Video";

enum ExpansionPosition {
  Closed = "Closed",
  Partial = "Partial",
  Full = "Full"
}

interface ExpansionState {
  position: ExpansionPosition;
  height: number;
  windowWidth: number;
  played: boolean;
}

export interface InfoAccordionItemProps extends CmsInfoAccordionItem {
  format: InfoAccordionFormat;
}

export const InfoAccordionItem: FC<InfoAccordionItemProps> = ({
  title,
  firstParagraph,
  expansionText,
  author,
  format,
  video,
  videoPoster
}) => {
  const closed = useRef<HTMLDivElement | null>(null);
  const initial = useRef<HTMLDivElement | null>(null);
  const secondary = useRef<HTMLDivElement | null>(null);
  const [expansionState, setExpansionState] = useState<ExpansionState>({
    position: ExpansionPosition.Closed,
    height: 0,
    windowWidth: 0,
    played: false
  });

  const fullyOpen = () => expansionState.position === ExpansionPosition.Full;
  const partiallyOpen = () =>
    expansionState.position === ExpansionPosition.Partial;
  const isOpen = () => partiallyOpen() || fullyOpen();

  const configureHeights = () => {
    const newWidth = window.innerWidth;

    if (expansionState.windowWidth !== newWidth) {
      setExpansionState({
        position: ExpansionPosition.Closed,
        height: 0,
        windowWidth: newWidth,
        played: false
      });
    }
  };

  const formatTitleForLink = (title: string) => {
    return snakeCase(lowerCase(title).replace(/[^a-z0-9\s]/, ""));
  };

  const termIsHashLinked = () =>
    window.location.hash.split("#")[1] === formatTitleForLink(title);

  useEffect(() => {
    if (termIsHashLinked()) {
      setTimeout(handleInitialOpen, 350);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", configureHeights);

    return () => window.removeEventListener("resize", configureHeights);
  }, [expansionState.windowWidth]);

  useEffect(() => {
    secondary.current?.querySelectorAll("a").forEach(el => {
      el.setAttribute("tabindex", fullyOpen() ? "0" : "-1");
    });
  }, [expansionState.position]);

  const calculatePartialHeight = () => {
    return initial.current?.clientHeight || 0;
  };

  const calculateFullHeight = () => {
    return calculatePartialHeight() + (secondary.current?.clientHeight || 0);
  };

  const handleInitialOpen = () => {
    if (!isOpen()) {
      setExpansionState(prev => {
        return {
          ...prev,
          position: ExpansionPosition.Partial,
          height: calculatePartialHeight()
        };
      });
    } else {
      setExpansionState(prev => {
        return {
          ...prev,
          position: ExpansionPosition.Closed,
          height: 0,
          played: false
        };
      });
    }
  };

  const handleSecondaryToggle = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (partiallyOpen()) {
      setExpansionState(prev => {
        return {
          ...prev,
          position: ExpansionPosition.Full,
          height: calculateFullHeight()
        };
      });
    } else {
      setExpansionState(prev => {
        return {
          ...prev,
          position: ExpansionPosition.Partial,
          height: calculatePartialHeight(),
          played: false
        };
      });
    }
  };

  const handleClose = () => {
    setExpansionState(prev => {
      return {
        ...prev,
        position: ExpansionPosition.Closed,
        height: 0,
        played: false
      };
    });
  };

  const handleKeydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      handleInitialOpen();
    }
  };

  const dynamicTopLevelSeo = () => {
    return format === InfoAccordionFormat.Glossary
      ? {
          itemType: "https://schema.org/DefinedTerm"
        }
      : {
          itemProp: "mainEntity",
          itemType: "https://schema.org/Question"
        };
  };

  const dynamicSecondaryLevelSeo = () => {
    return format === InfoAccordionFormat.Glossary
      ? {}
      : {
          itemScope: true,
          itemProp: "acceptedAnswer",
          itemType: "https://schema.org/Answer"
        };
  };

  const dynamicTertiaryLevelSeo = () => {
    return format === InfoAccordionFormat.Glossary
      ? { itemProp: "description" }
      : { itemProp: "text" };
  };

  const handleOnPlay = () => {
    setExpansionState(prev => {
      return { ...prev, played: true };
    });
  };

  return (
    <div
      id={formatTitleForLink(title)}
      itemScope
      {...dynamicTopLevelSeo()}
      tabIndex={isOpen() ? -1 : 0}
      onKeyDown={handleKeydown}
      onClick={handleInitialOpen}
      className={cx(styles.infoAccordionItem, {
        [styles.closed]: !isOpen(),
        [styles.glossary]: format === InfoAccordionFormat.Glossary
      })}
    >
      {isOpen() && (
        <IconographyButton
          className={styles.closeButton}
          icon={cross}
          handler={handleClose}
          value={`Close ${title}`}
        />
      )}
      <h3 itemProp="name" ref={closed} className={styles.termTitle}>
        {title}
      </h3>
      <div
        {...dynamicSecondaryLevelSeo()}
        className={cx(styles.expansion, {
          [styles.fullOpen]: fullyOpen()
        })}
        style={{ height: expansionState.height }}
      >
        <div
          data-testid="initial"
          className={cx(styles.initial, { [styles.visible]: isOpen() })}
          ref={initial}
        >
          <p {...dynamicTertiaryLevelSeo()}>{firstParagraph?.firstParagraph}</p>
          {expansionText && (
            <Link
              role="button"
              tabIndex={partiallyOpen() ? 0 : -1}
              className={cx(styles.readButton, {
                [styles.hidden]: fullyOpen()
              })}
              onClick={handleSecondaryToggle}
              secondary
            >
              Read more
            </Link>
          )}
        </div>
        <div
          data-testid="secondary"
          className={cx(styles.secondary, {
            [styles.visible]: fullyOpen()
          })}
          ref={secondary}
        >
          <div {...dynamicTertiaryLevelSeo()} className={styles.expansionText}>
            {expansionText && useBlogRichText(expansionText)}
          </div>
          {video && (
            <div
              className={styles.video}
              onClick={e => {
                e.stopPropagation();
                handleOnPlay();
              }}
            >
              <Video
                video={video}
                videoPoster={videoPoster}
                play={expansionState.played}
                onPlay={handleOnPlay}
              />
            </div>
          )}
          {author && (
            <div
              itemProp="author"
              itemScope
              itemType="https://schema.org/Person"
              className={styles.author}
            >
              <span itemProp="name">{`By ${author.name}`}</span>
            </div>
          )}
          <Link
            role="button"
            tabIndex={fullyOpen() ? 0 : -1}
            className={cx(styles.readButton)}
            onClick={handleSecondaryToggle}
            secondary
          >
            Read less
          </Link>
        </div>
      </div>
    </div>
  );
};

InfoAccordionItem.displayName = "GlossaryTerm";
