import { LegalDocsData } from "hooks/useGetLegalDocsData";

export const getUriForDocType = (
  location: Location | null,
  legalDocsData: LegalDocsData,
  docType: string
) => {
  const uri = legalDocsData.allLegalDocument.nodes.find(
    doc => doc.type === docType
  )?.latestUri;

  if (!uri) {
    throw new Error(`No legal document found for type ${docType}`);
  }

  return location ? `${location.protocol}//${location.host}/${uri}` : uri;
};
