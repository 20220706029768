import { CmsPage, ComposedPage } from "components/ComposedPage";
import { graphql } from "gatsby";
import React, { FC } from "react";

export interface BlogHomeTemplateProps {
  data: {
    page: CmsPage;
  };
}

export const BlogHomeTemplate: FC<BlogHomeTemplateProps> = ({
  data: { page }
}) => {
  return (
    <ComposedPage
      title={page.title}
      metaTitle={page.metaTitle}
      metaDescription={page.metaDescription}
      sections={page.sections}
      slug={page.slug}
      aboveFoldExperimentation={page.aboveFoldExperimentation}
    />
  );
};
export default BlogHomeTemplate;

export const pageQuery = graphql`
  query contentHubLandingPage {
    page: contentfulContentHubLandingPage {
      id
      metaDescription
      metaTitle
      aboveFoldExperimentation
      title
      sections {
        __typename
        ... on ContentfulBlogList {
          id
          contentful_id
          title
          slug
          blogPosts {
            ...ContentfulBlogPost
          }
        }
        ... on ContentfulPageHero {
          ...ContentfulPageHeroFragment
        }
        ...ContentfulLatestPostsCarouselFragment
        ...ContentfulContentHubNavBarFragment
        ...ContentfulCategoryFragment
      }
    }
  }
`;

export const ContentfulCategoryFragment = graphql`
  fragment ContentfulCategoryFragment on ContentfulCategory {
    id
    contentful_id
    title
    slug
    blog_post {
      ...ContentfulBlogPost
    }
  }
`;

export const ContentfulBlogPostFragment = graphql`
  fragment ContentfulBlogPost on ContentfulBlogPost {
    __typename
    id
    slug
    title
    subTitle
    keywords
    createdAt
    updatedAt
    publishedDate
    readingTime
    heroImage {
      gatsbyImageData(width: 1200, placeholder: BLURRED)
      title
      description
    }
    categories {
      id
      title
      slug
      icon {
        file {
          url
        }
      }
    }
  }
`;
