import React, { FC } from "react";
import { useDeviceOs, useDeviceSize, useIsClient } from "@mettle/hooks";
import { isTabletSize } from "lib/device";
import { isAndroid, isDesktop, isIOs } from "lib/os";
import styles from "./styles.module.scss";
import { AppleStoreButton } from "./AppleStoreButton";
import { AndroidStoreButton } from "./AndroidStoreButton";
import cx from "classnames";
import { getQuickLinks, QuickLinkEnum } from "../../hooks";
import useIsPotsPage from "../../hooks/useIsPotsPage";

interface Props {
  testIosQuickLink?: string;
  testAndroidQuickLink?: string;
  iosQuickLink?: string;
  androidQuickLink?: string;
  className?: string;
}

export const AppStoreButtons: FC<Props> = ({
  testIosQuickLink,
  testAndroidQuickLink,
  iosQuickLink,
  androidQuickLink,
  className = ""
}) => {
  const os = useDeviceOs();
  const deviceSize = useDeviceSize();
  const isClient = useIsClient();
  const links = getQuickLinks();
  const isPotsPage = useIsPotsPage();
  const savingsLink =
    links[QuickLinkEnum.GET_METTLE_DESKTOP_IOS].savingsPotLink!;

  return isClient ? (
    <>
      {iosQuickLink &&
        testIosQuickLink &&
        androidQuickLink &&
        testAndroidQuickLink && (
          <div
            data-testid="store-buttons"
            className={cx(className, styles.appStoreButtons)}
          >
            {(isIOs(os) || isDesktop(os) || isTabletSize(deviceSize)) && (
              <AppleStoreButton
                iosQuickLink={isPotsPage ? savingsLink : iosQuickLink}
                testIosQuickLink={isPotsPage ? savingsLink : testIosQuickLink}
                linkClassName={styles.store}
                imgClassName={styles.appStore}
              />
            )}
            {(isAndroid(os) || isDesktop(os) || isTabletSize(deviceSize)) && (
              <AndroidStoreButton
                androidQuickLink={androidQuickLink}
                testAndroidQuickLink={testAndroidQuickLink}
                linkClassName={styles.store}
                imgClassName={styles.playStore}
              />
            )}
          </div>
        )}
    </>
  ) : null;
};
