import React, { FC } from "react";
import { graphql } from "gatsby";
import { CmsBlogPost, BlogPost } from "components/Blog";
import { DefaultLayout } from "layouts";
import { Colour } from "components/constants";
import { useBlogPostUrl } from "components/Blog/hooks";

export const BlogPostTemplate: FC<{ data: { post: CmsBlogPost } }> = ({
  data: { post }
}) => {
  return (
    <DefaultLayout
      title={post.metaTitle || `${post.title} | Mettle blog`}
      description={post.metaDescription}
      noIndex={post.noIndex}
      background={Colour.white}
      image={post.heroImage}
      twitterImage={post.heroImage}
      siteUrl={useBlogPostUrl(post)}
      type="article"
      canonicalUrl={post.canonicalUrl}
      aboveFoldExperimentation={post.aboveFoldExperimentation}
    >
      <BlogPost {...post} />
    </DefaultLayout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query getBlogPost($id: String!) {
    post: contentfulBlogPost(id: { eq: $id }) {
      slug
      title
      metaTitle
      aboveFoldExperimentation
      canonicalUrl
      metaDescription
      noIndex
      subTitle
      publishedDate
      createdAt
      updatedAt
      readingTime
      heroImage {
        gatsbyImageData(placeholder: BLURRED, width: 1200)
        title
        description
        file {
          url
        }
      }
      heroVideo {
        title
        description
        file {
          url
          fileName
          contentType
          details {
            size
          }
        }
      }
      sections {
        ...ContentfulCardPanelFragment
        ...ContentfulFourCardPanelFragment
        ...ContentfulBlogPostRichTextSectionFragment
        ...ContentfulInfoAccordionFragment
        ...ContentfulComparisonTableSectionFragment
        ...ContentfulQuoteCardFragment
        ...ContentfulCalculatorSectionFragment
        ...ContentfulPageHeroFragment
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            title
            description
            file {
              url
              fileName
              contentType
              details {
                image {
                  width
                  height
                }
              }
            }
          }
        }
      }
      author {
        ...ContentfulAuthorFragment
      }
      secondaryBylineText
      secondaryAuthor {
        ...ContentfulAuthorFragment
      }
      categories {
        title
        slug
      }
      hasTableOfContents
      documents {
        title
        file {
          fileName
          url
        }
      }
      youMayAlsoLike {
        title
        slug
        createdAt
        publishedDate
        readingTime
        heroImage {
          gatsbyImageData(placeholder: BLURRED, width: 1200)
        }
      }
      bottomBanners {
        __typename
        ... on Node {
          id
          ...ContentfulTrustPilotSectionFragment
          ...ContentfulInfoAccordionFragment
        }
      }
    }
  }
`;
