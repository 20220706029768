import { useMemo } from "react";
import { useDeviceOs } from "@mettle/hooks/lib";
import { isIOs } from "@mettle/hooks/lib/utils/os";
import { Env } from "conf/env";
import { useConfig } from "hooks/useConfig";

export const PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=uk.co.mettle.app";

export const APP_STORE_URL =
  "https://itunes.apple.com/gb/app/mettle/id1411976870?mt=8";

export enum QuickLinkEnum {
  GET_METTLE_MOBILE = "GET_METTLE_MOBILE",
  GET_METTLE_DESKTOP_IOS = "GET_METTLE_DESKTOP_IOS",
  GET_METTLE_DESKTOP_ANDROID = "GET_METTLE_DESKTOP_ANDROID"
}

interface QuickLink {
  ios: string;
  android: string;
  testLink: string;
  prodLink: string;
  savingsPotLink?: string;
}

export const getQuickLinks = (): Record<QuickLinkEnum, QuickLink> => ({
  [QuickLinkEnum.GET_METTLE_MOBILE]: {
    ios: APP_STORE_URL,
    android: PLAY_STORE_URL,
    testLink: "https://mettle.test-app.link/PCEewpXIq6",
    prodLink: "https://mettle.app.link/In5p5iMKq6"
  },
  [QuickLinkEnum.GET_METTLE_DESKTOP_IOS]: {
    ios: APP_STORE_URL,
    android: PLAY_STORE_URL,
    testLink: "https://mettle.test-app.link/RbIKb21Si8",
    prodLink: "https://mettle.app.link/XFkfHu3e77",
    savingsPotLink: "https://mettle.app.link/Jl47pj5j9Hb"
  },
  [QuickLinkEnum.GET_METTLE_DESKTOP_ANDROID]: {
    ios: APP_STORE_URL,
    android: PLAY_STORE_URL,
    testLink: "https://mettle.test-app.link/zGBeqeaTi8",
    prodLink: "https://mettle.app.link/TZH4bh5e77"
  }
});

export const useQuickLink = (whichLink: QuickLinkEnum) => {
  const config = useConfig();
  const os = useDeviceOs();

  return useMemo(() => {
    const link = getQuickLinks()[whichLink];
    if (config?.BRANCH_IO_ENABLED) {
      return config.ENV === Env.TEST ? link.testLink : link.prodLink;
    } else {
      return isIOs(os) ? link.ios : link.android;
    }
  }, [config]);
};
