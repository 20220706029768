import Fuse from "fuse.js";

export interface SearchResult<T> {
  items: T[];
  status: "found" | "not-found" | "skipped";
  searchTerm: string;
}

export interface Options {
  minLength: number;
  keys: string[];
}

export class Search<T> {
  items: T[];
  options: Options;
  fuse: Fuse<T>;

  constructor(items: T[], options: Options) {
    this.items = items;
    this.options = options;
    this.fuse = new Fuse(items, { keys: options.keys });
  }

  find(searchTerm: string): SearchResult<T> {
    if (searchTerm.length <= this.options.minLength) {
      return {
        items: [],
        status: "skipped",
        searchTerm
      };
    }
    const results = this.fuse.search(searchTerm).map(r => r.item);
    if (results.length > 0) {
      return {
        items: results,
        status: "found",
        searchTerm
      };
    }
    return {
      items: [],
      status: "not-found",
      searchTerm
    };
  }
}
