import React, { FC } from "react";
import cx from "classnames";
import styles from "./styles.module.scss";

type Props = {
  imgSrc?: string;
  text?: string;
  className?: string;
};

export const Polaroid: FC<Props> = ({ imgSrc, text, className }) => (
  <div className={cx(styles.polaroid, className)}>
    <div className={styles.image}>
      <img src={imgSrc} alt="" />
    </div>
    <div className={styles.text}>
      <p>{text}</p>
    </div>
  </div>
);
