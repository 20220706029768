import { MutableRefObject, useRef } from "react";
import { useEventListener } from "@mettle/hooks";
import { useCaptureFocusOnMount } from "./useCaptureFocusOnMount";
import { useDisableRootAccessibilityTree } from "./useDisableRootAccessibilityTree";
import { usePreventFocusOut } from "./usePreventFocusOut";
import { useRestoreFocusOnUnmount } from "./useRestoreFocusOnUnmount";

/**
 * Provides focus management and trapping for a given element.
 * Returns a ref which should be provided to the target element.
 */
export const useFocusTrap = (handleClose: () => void) => {
  const innerRef = useRef<HTMLDivElement>(null);
  const previousFocus: MutableRefObject<HTMLElement | null> = useRef(null);
  useDisableRootAccessibilityTree();
  usePreventFocusOut(innerRef);
  useCaptureFocusOnMount(innerRef);
  useRestoreFocusOnUnmount(previousFocus);
  useEventListener(innerRef.current, "keydown", (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      handleClose();
    }
  });

  return innerRef;
};
