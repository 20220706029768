import {
  ChangeEventHandler,
  KeyboardEventHandler,
  ClipboardEventHandler,
  FocusEventHandler,
  ReactNode,
  useMemo,
  useState,
  SyntheticEvent,
  ComponentType,
  useContext
} from "react";
import * as Sentry from "@sentry/gatsby";
import { BranchIO } from "branch-sdk";
import { AnalyticsContext, useFormAnalytics } from "./useAnalytics";

export interface FormFieldDefinition<T> {
  Input?: ComponentType<HTMLInputElement> | "input";
  type: HTMLInputElement["type"];
  placeholder?: HTMLInputElement["placeholder"];
  className?: HTMLDivElement["className"];
  focusClassName?: HTMLDivElement["className"];
  label?: ReactNode;
  extraContent?: ReactNode;

  validate?(value: T | null): void;

  required?: boolean;
  pattern?: RegExp;
  applyFocus: boolean;
}

type FormFieldsDefinitionMap<
  T extends Record<string, any>,
  K extends keyof T
> = Record<K, FormFieldDefinition<T[K]>>;

export type FormTouched<K extends string | symbol | number> = Record<
  K,
  boolean
>;
export type FormErrors<K extends string | symbol | number> = Record<
  K,
  Error | null
>;

export const validateFormFields = <
  T extends Record<string, any>,
  K extends keyof T
>(
  fields: FormFieldsDefinitionMap<T, K>,
  values: T
) =>
  Object.keys(fields).reduce((errors, key) => {
    const { validate } = fields[key];
    errors[key] = null;
    if (validate) {
      try {
        validate(values[key]);
      } catch (error) {
        errors[key] = error;
      }
    }
    return errors;
  }, {} as FormErrors<K>);

export interface FormDefinition<T, K extends keyof T> {
  // @ts-ignore
  fields: FormFieldsDefinitionMap<T, K>;

  submit(values: T, branch: BranchIO | null): Promise<void>;
}

export interface FormHookOptions<T> {
  initialState?: Partial<T>;
  onSubmitted: (submittedValues: T) => void;
}

export interface UseFieldInterface {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onFocus: FocusEventHandler<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;
  onKeyDown: KeyboardEventHandler<HTMLInputElement>;
  onPaste: ClipboardEventHandler<HTMLInputElement>;
  disabled: boolean;
  value?: string | number;
  checked?: boolean;
}

export const formStateHook =
  <T extends Record<string, any>, K extends keyof T = keyof T>({
    fields,
    submit
  }: FormDefinition<T, K>) =>
  ({ initialState = {}, onSubmitted }: FormHookOptions<T>) => {
    const { branch } = useContext(AnalyticsContext);
    const [submitAnalytics, focusAnalytics, blurAnalytics] =
      useFormAnalytics("appstore-form");

    const formFieldKeys = useMemo(() => Object.keys(fields), [fields]);

    // Track form values in an object in state
    const [values, setValues] = useState<T>(initialState as T);
    const setValue = (key: K, value: T[K]) =>
      setValues({ ...values, [key]: value });

    const initialTouched = useMemo(
      () =>
        formFieldKeys.reduce(
          (obj, key) => ({ ...obj, [key]: false }),
          {} as FormTouched<K>
        ),
      [formFieldKeys]
    );

    // Track which fields have been 'touched' (i.e. focussed then blurred)
    const [touched, setTouched] = useState<FormTouched<K>>(initialTouched);
    const hasTouched = useMemo(
      () => Object.values(touched).some(Boolean),
      [touched]
    );
    const [focusedField, setFocusedField] = useState<K | null>(null);

    const onChange = (key: K): ChangeEventHandler<HTMLInputElement> => {
      const field = fields[key];
      const eventTargetKey = field.type === "checkbox" ? "checked" : "value";
      return event => {
        setValue(key, event.target[eventTargetKey] as T[K]);
        if (field.type !== "checkbox") {
          setTouched({ ...touched, [key]: false });
        }
      };
    };

    const onKeyDown = (key: K): KeyboardEventHandler<HTMLInputElement> => {
      const type = fields[key].type;
      return e => {
        if (type === "number") {
          if (
            [
              "Delete",
              "Backspace",
              "Tab",
              "Escape",
              "Enter",
              "Home",
              "End",
              "ArrowRight",
              "ArrowLeft"
            ].includes(e.key) ||
            ((e.ctrlKey || e.metaKey) && ["a", "c", "v", "x"].includes(e.key))
          ) {
            // let it happen, don't do anything
            return;
          }
          // Block the event if it's not a digit
          if (!e.key.match(/\d/)) {
            e.preventDefault();
          }
        }
      };
    };

    const onPaste = (key: K): ClipboardEventHandler<HTMLInputElement> => {
      const pattern = fields[key].pattern || /.+/;
      return event => {
        const data = event.clipboardData.getData("text");
        if (!pattern.test(data)) {
          event.preventDefault();
        }
      };
    };

    const onFocus =
      (key: K): FocusEventHandler<HTMLInputElement> =>
      event => {
        focusAnalytics(event);
        setFocusedField(key);
      };

    const onBlur =
      (key: K): FocusEventHandler<HTMLInputElement> =>
      event => {
        setTouched({ ...touched, [key]: true });
        setFocusedField(null);
        blurAnalytics(event);
      };

    // Track which fields have which errors.
    const errors = useMemo<FormErrors<K>>(
      () => validateFormFields(fields, values),
      [fields, values]
    );
    const hasErrors = useMemo(
      () => Object.values(errors).some(Boolean),
      [errors]
    );

    // Track submission progress
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const [submissionError, setSubmissionError] = useState<Error>();

    const useField = (key: K): UseFieldInterface => {
      const type = fields[key].type;

      const val =
        type === "checkbox"
          ? { checked: values[key] || false }
          : { value: values[key] || "" };

      return {
        ...val,
        onChange: onChange(key),
        onFocus: onFocus(key),
        onBlur: onBlur(key),
        onKeyDown: onKeyDown(key),
        onPaste: onPaste(key),
        disabled: isSubmitting
      };
    };

    const onFormSubmit = async (event: SyntheticEvent) => {
      event.preventDefault();
      if (isSubmitting) return;
      setSubmissionError(undefined);

      if (hasErrors) {
        setTouched(
          formFieldKeys.reduce(
            (obj, key) => ({ ...obj, [key]: true }),
            {} as FormTouched<K>
          )
        );
        return;
      }

      setSubmitting(true);
      try {
        await submit(values, branch);
        onSubmitted(values);
        submitAnalytics();
      } catch (error) {
        setSubmissionError(
          new Error("Something went wrong. Refresh the page and try again.")
        );
        Sentry.captureException(error);
      } finally {
        setSubmitting(false);
      }
    };

    return {
      focusedField,
      fields,
      touched,
      values,
      errors,
      hasErrors,
      hasTouched,
      onChange,
      onBlur,
      onFormSubmit,
      useField,
      isSubmitting,
      submissionError
    };
  };
