import React, { FC } from "react";
import { CmsBlogPost } from "../types";
import styles from "./styles.module.scss";
import { BlogPostCard } from "../BlogHome";
import { BlogHomeSection } from "../BlogHome/BlogHomeSection";
import { Panel, PanelItem } from "../../Panel";
import { Colour } from "../../constants";
import { CmsPageSectionBase } from "../../CardPanel";
import cx from "classnames";

export interface CmsBlogListSection extends CmsPageSectionBase {
  __typename: string;
  id: string;
  title: string;
  slug: string;
  blogPosts: CmsBlogPost[];
}

export const BlogList: FC<CmsBlogListSection> = ({
  title,
  blogPosts: [firstPost, ...restPosts]
}) => {
  return (
    <Panel colour={Colour.warmStone} short className={styles.blogList}>
      <PanelItem>
        <div className={cx(styles.content)}>
          {title && <h4 className={styles.listTitle}>{title}</h4>}
          <BlogPostCard
            __typename={firstPost.__typename}
            id={firstPost.id}
            imageCritical
            layout="triple"
            post={firstPost}
          />
          <BlogHomeSection posts={restPosts} showSubTitle={false} />
        </div>
      </PanelItem>
    </Panel>
  );
};

BlogList.displayName = "BlogList";
