import React, { FC } from "react";
import styles from "./styles.module.scss";
import { Button, ButtonVariants } from "../../Button";
import cx from "classnames";
import { CalculatorInput } from "../CalculatorInput";
import { useCalculatorState } from "../../../hooks/useCalculatorState";

export interface SimpleInterestState {
  valid: boolean;
  monthly: string;
  yearly: string;
  compoundYearly: string;
  savingGoal: string;
  rate: string;
}

export const SimpleInterestCalculator: FC = () => {
  const {
    handleSavingInputChange,
    handleInterestInputChange,
    calculate,
    savingGoal,
    MAX_GOAL,
    MAX_RATE,
    rate,
    valid,
    monthly,
    yearly
  } = useCalculatorState();

  return (
    <div className={styles.calculator}>
      <div className={styles.inputs}>
        <CalculatorInput
          changeHandler={handleSavingInputChange}
          name="saving_goal"
          label="Saving goal"
          type="number"
          invalidMessage="Invalid number"
          unit="currency"
          max={MAX_GOAL}
          helpText="How much do you want to save a month?"
          value={savingGoal}
          placeholder="0"
        />

        <CalculatorInput
          changeHandler={handleInterestInputChange}
          name="interest_rate"
          type="number"
          label="Interest rate"
          invalidMessage="Invalid number"
          max={MAX_RATE}
          unit="percentage"
          helpText="The interest rate on the monthly amount."
          value={rate}
          placeholder="0"
        />
      </div>

      <div>
        <Button
          className={styles.button}
          onClick={calculate}
          variant={ButtonVariants.PRIMARY}
          disabled={!valid}
        >
          Calculate
        </Button>
      </div>

      <div className={styles.results}>
        <p className={styles.title}>Interest earned</p>
        <div className={styles.outputs}>
          <div>
            Monthly
            <span
              data-testid="monthly"
              className={cx(styles.result, {
                [styles.nonZero]: Number(monthly) !== 0
              })}
            >
              £{monthly}
            </span>
          </div>
          <div>
            Yearly
            <span
              data-testid="yearly"
              className={cx(styles.result, {
                [styles.nonZero]: Number(yearly) !== 0
              })}
            >
              £{yearly}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

SimpleInterestCalculator.displayName = "SimpleInterestCalculator";
