import React, { FC } from "react";
import { CmsPageCarouselSection, PageCarousel } from "../PageCarousel";
import { graphql, useStaticQuery } from "gatsby";
import { useMappedPosts } from "../Blog/hooks/useMappedPosts";

export type CmsLatestPostsCarouselSection = Omit<
  CmsPageCarouselSection,
  "items"
>;

export const LatestPostsCarousel: FC<CmsLatestPostsCarouselSection> = props => {
  const data = useStaticQuery(graphql`
    query LatestPosts {
      allContentfulBlogPost(
        filter: {
          slug: {
            nin: [
              "test-article"
              "test-video-blog"
              "test-article-do-not-remove"
              "privacy-notice-pps"
              "privacy-notice-genie"
            ]
          }
        }
        sort: [{ publishedDate: DESC }, { createdAt: DESC }]
        limit: 9
      ) {
        nodes {
          __typename
          id
          slug
          title
          createdAt
          publishedDate
          readingTime
          heroImage {
            gatsbyImageData(width: 1200, placeholder: BLURRED)
            title
            description
          }
          categories {
            id
            title
          }
        }
      }
    }
  `);

  const mapped = useMappedPosts(data.allContentfulBlogPost.nodes);

  return <PageCarousel {...props} items={mapped} />;
};

export const ContentfulLatestPostsCarouselFragment = graphql`
  fragment ContentfulLatestPostsCarouselFragment on ContentfulLatestPostsCarousel {
    __typename
    id
    contentful_id
    showControls
    internalTitle
    autoScroll
    autoScrollInterval
    backgroundColour
    displayItems
    linkText
    linkUrl
    title
    sectionPaddingTop
    sectionPaddingBottom
  }
`;
