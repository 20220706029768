import React, { useEffect, useState } from "react";

export const useIntersectionObserver = (
  element: React.MutableRefObject<any>,
  shouldObserve: boolean | undefined,
  threshold?: number,
  unset?: boolean
) => {
  const DEFAULT_THRESHOLD = 0.9;
  const [isIntersecting, setIsIntersecting] = useState(false);
  const configuredThreshold = threshold || DEFAULT_THRESHOLD;
  const defaultOptions = {
    root: null,
    rootMargin: "0px",
    threshold: configuredThreshold
  };

  const callback = (entries: any) => {
    entries.forEach((entry: any) => {
      if (entry.intersectionRatio >= configuredThreshold) {
        setIsIntersecting(true);
      } else if (unset) {
        setIsIntersecting(false);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callback, defaultOptions);

    if (shouldObserve && element.current) {
      observer.observe(element.current);
    }

    return () => {
      if (shouldObserve && element.current) {
        observer.unobserve(element.current);
      }
    };
  }, [shouldObserve, element]);

  return isIntersecting;
};
