import { apiCall } from "./apiCall";
import { getUtmParameters } from "./queryString";
import { EmailFormConfig } from "components/CaptureLeadSection/emailFormConfig";

export interface LeadResponse {
  leadId: string;
}

export const captureLeadEmail = (
  emailFormConfig: EmailFormConfig,
  email: string
) =>
  apiCall<LeadResponse>(emailFormConfig, "/leads", {
    method: "POST",
    body: JSON.stringify({
      email,
      utmParams: getUtmParameters(),
      tags: emailFormConfig.tags
    })
  });
