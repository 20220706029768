import React, { FC, useCallback } from "react";
import { useDeviceOs, useIsClient } from "@mettle/hooks";
import { PanelItem } from "components/Panel";
import { HorizontalPosition, VerticalPosition } from "components/constants";
import { useMobileAppLink, DeepLinkTypes } from "hooks";
import styles from "../styles.module.scss";
import { MobileLeftPanel } from "./MobileLeftPanel";
import { DesktopLeftPanel } from "./DesktopLeftPanel";
import { Data, DeepLinkData } from "./data";

const getDeepLinkData = (deepLinkType: DeepLinkTypes): DeepLinkData => {
  return { ...Data.default, ...Data[deepLinkType] } as DeepLinkData;
};

export const DeepLinkLeftPanel: FC = () => {
  const isClient = useIsClient();
  const deviceOS = useDeviceOs();
  const { internalDeepLink, deepLinkType } = useMobileAppLink();
  const { steps, title, alternatively, desktopMessage } =
    getDeepLinkData(deepLinkType);
  const isMobileDevice = deviceOS === "ios" || deviceOS === "android";

  const onTapButton = useCallback(() => {
    window.open(internalDeepLink, "_self");
  }, [internalDeepLink]);

  if (!isClient) {
    return null;
  }

  return (
    <PanelItem
      className={styles.heroPanel}
      horizontalPosition={HorizontalPosition.left}
      verticalPosition={VerticalPosition.top}
    >
      {isMobileDevice ? (
        <MobileLeftPanel onTapButton={onTapButton} />
      ) : (
        <DesktopLeftPanel
          title={title}
          listItems={steps}
          alternatively={alternatively}
          desktopMessage={desktopMessage}
        />
      )}
    </PanelItem>
  );
};
DeepLinkLeftPanel.displayName = "DeepLinkLeftPanel";
