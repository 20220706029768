import React, { FC } from "react";
import { DefaultLayout } from "layouts";
import { Panel, PanelItem, PanelLayout } from "components/Panel";

import {
  DeepLinkLeftPanel,
  DeepLinkRightPanel
} from "components/DeepLinkPagePanel";

import styles from "./styles.module.scss";

import { Colour } from "components/constants";

const DeepLinkPage: FC = () => (
  <DefaultLayout>
    <Panel
      colour={Colour.warmStone}
      className={styles.appPanel}
      layout={PanelLayout.full}
      short
    >
      <PanelItem className={styles.panelItem}>
        <div className={styles.content}>
          <DeepLinkLeftPanel />
          <DeepLinkRightPanel />
        </div>
      </PanelItem>
    </Panel>
  </DefaultLayout>
);

export default DeepLinkPage;
