import React, { FC } from "react";
import { graphql } from "gatsby";
import { CmsPageSectionBase, ImageFit } from "../CardPanel";
import { Colour, SectionPaddingDefault } from "../constants";
import { Panel, PanelLayout } from "../Panel";
import cx from "classnames";
import styles from "./styles.module.scss";
import { HeroCtas } from "../PageHero/HeroCtas";
import Marquee from "react-fast-marquee";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";

export interface CmsTickerTapeSection extends CmsPageSectionBase {
  backgroundColour: Colour;
  tickerItems: string;
  showOpenAccountButton: boolean;
  openAccountButtonText?: string;
  primaryCtaUrl?: string;
  primaryCtaText?: string;
  secondaryCtaUrl?: string;
  secondaryCtaText?: string;
  dividerStyle: Colour;
  backgroundImage?: any; // TODO change to CmsResponsiveImage when mw-62 update deps is merged
}

export const TickerTapeSection: FC<CmsTickerTapeSection> = ({
  sectionPaddingTop,
  sectionPaddingBottom,
  backgroundColour,
  tickerItems,
  dividerStyle,
  backgroundImage,
  ...ctaProps
}) => {
  const isBullet = dividerStyle === Colour.softBlack;

  const Items: FC<{ isMobile?: boolean }> = ({ isMobile }) => {
    return (
      <div className={styles.item}>
        {tickerItems.split(";").map(item => {
          return (
            <span key={item}>
              {item}
              {isBullet && !isMobile && (
                <span className={styles.bullet}>&#x2022;</span>
              )}
              {(!isBullet || isMobile) && (
                <span className={styles.fullStop}>.</span>
              )}
            </span>
          );
        })}
      </div>
    );
  };

  return (
    <Panel
      className={styles.tickerTapeSection}
      colour={Colour[backgroundColour]}
      layout={PanelLayout.full}
      short
    >
      <div
        className={cx(
          styles.content,
          styles[sectionPaddingTop || SectionPaddingDefault.TOP],
          styles[sectionPaddingBottom || SectionPaddingDefault.BOTTOM]
        )}
      >
        <div className={styles.desktopTicker}>
          <Marquee autoFill>
            <Items />
          </Marquee>
        </div>
        <div className={styles.mobileStack}>
          <Items isMobile />
        </div>
        <HeroCtas className={styles.ctas} {...ctaProps} />
      </div>

      {backgroundImage && (
        <div className={styles.backgroundImage}>
          <GatsbyImage
            alt=""
            imgStyle={{
              objectFit: ImageFit.COVER
            }}
            image={getImage(backgroundImage) as IGatsbyImageData}
          />
        </div>
      )}
    </Panel>
  );
};

TickerTapeSection.displayName = "TickerTapeSection";

export const ContentfulTickerTapeSectionFragment = graphql`
  fragment ContentfulTickerTapeSectionFragment on ContentfulTickerTapeSection {
    __typename
    contentful_id
    sectionPaddingTop
    sectionPaddingBottom
    backgroundColour
    tickerItems
    showOpenAccountButton
    openAccountButtonText
    primaryCtaUrl
    primaryCtaText
    secondaryCtaUrl
    secondaryCtaText
    dividerStyle
    backgroundImage {
      gatsbyImageData(placeholder: BLURRED, quality: 80)
    }
  }
`;
