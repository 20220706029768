import { RefObject } from "react";
import { useEventListener } from "@mettle/hooks";

/**
 * Given a ref to an HTML element, prevent focus from leaving that element
 * or its (grand)children.
 */
export const usePreventFocusOut = (ref: RefObject<HTMLElement>) => {
  useEventListener(window, "focusin", event => {
    if (!ref.current || !event.target) return;
    if (!ref.current.contains(event.target as Node)) {
      ref.current.focus();
    }
  });
};
