import React, { FC, useMemo } from "react";
import { graphql, StaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import { CmsJsonSchema } from "../ComposedPage";
import { CmsResponsiveImage } from "../Blog";

export const seoQuery = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`;

interface SEOMetadata {
  title: string;
  description: string;
  siteUrl: string;
}

export interface SiteMetadata {
  site: {
    siteMetadata: SEOMetadata;
  };
}

export interface SEOProps {
  title?: string;
  description?: string;
  siteUrl?: string;
  image?: CmsResponsiveImage;
  twitterImage?: CmsResponsiveImage;
  type?: "website" | "article";
  canonicalUrl?: string;
  children?: React.ReactNode;
  noIndex?: boolean;
  jsonSchema?: CmsJsonSchema;
}

export const forceProtocol = (protocol: "http" | "https", url: string) =>
  url.replace(/^(?:https?:)?\/\//, `${protocol}://`);

export type OpenGraphTags = Record<string, string | CircularOpenGraphTags>;

export interface CircularOpenGraphTags extends OpenGraphTags {}

export const openGraphMetaTags = (
  tags: OpenGraphTags,
  prefix: string = "og"
): React.ReactNodeArray =>
  Object.entries(tags).flatMap(([property, content]) =>
    typeof content !== "object" ? (
      <meta
        key={property}
        property={property === "*" ? prefix : [prefix, property].join(":")}
        content={content}
      />
    ) : (
      openGraphMetaTags(content, [prefix, property].join(":"))
    )
  );

export const useOpenGraphMetaTags = (
  tags: OpenGraphTags
): React.ReactNodeArray => useMemo(() => openGraphMetaTags(tags), [tags]);

interface TagProps {
  title?: string;
  description?: string;
  siteUrl?: string;
  image?: CmsResponsiveImage;
  twitterImage?: CmsResponsiveImage;
  type?: "website" | "article";
  seo: SEOMetadata;
}

const DEFAULT_DESCRIPTION =
  "Free small business account. For freelancers, contractors, sole traders and small businesses. It's easy to open – get an account today";

export const createTags = ({
  title,
  description,
  image,
  twitterImage,
  siteUrl = "",
  type = "website",
  seo
}: TagProps) => ({
  url: seo.siteUrl + siteUrl,
  title: title?.includes("|") ? title : `${seo.description} | ${seo.title}`,
  description: description || DEFAULT_DESCRIPTION,
  type,
  local: "en_GB",
  image: {
    "*": forceProtocol("http", image?.file?.url || `${seo.siteUrl}/ogp.jpg`),
    type: "image/jpeg",
    secure_url: forceProtocol(
      "https",
      image?.file?.url || `${seo.siteUrl}/ogp.jpg`
    ),
    width: (image?.gatsbyImageData.width || 1200).toString(),
    height: (image?.gatsbyImageData.height || 630).toString()
  },
  twitter: {
    card: "summary_large_image",
    site: "@joinmettle",
    image: forceProtocol(
      "https",
      twitterImage?.file?.url || `${seo.siteUrl}/ogp-twitter.jpg`
    )
  }
});

export const SEO: FC<SEOProps> = ({
  children,
  title,
  description,
  image,
  twitterImage,
  siteUrl = "",
  type = "website",
  canonicalUrl,
  noIndex = false,
  jsonSchema
}) => (
  <StaticQuery
    query={seoQuery}
    render={({ site: { siteMetadata: seo } }: SiteMetadata) => (
      <>
        {/* Helmet will be removed in https://eeveebank.atlassian.net/browse/MW-191 */}
        {/* @ts-ignore */}
        <Helmet
          title={title}
          titleTemplate={
            title && title.includes("|") ? "%s" : `%s | ${seo.title}`
          }
          defaultTitle={`${seo.description} | ${seo.title}`}
        >
          <meta
            name="description"
            content={description || DEFAULT_DESCRIPTION}
          />
          {noIndex && <meta name="robots" content="noindex" />}
          {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
          {jsonSchema?.jsonSchema && (
            <script type="application/ld+json">
              {/* parses schema string from contentful to valid JSON, returns the stringified representation of the notation that Helmet expects */}
              {`${JSON.stringify(JSON.parse(jsonSchema?.jsonSchema))}`}
            </script>
          )}
          {useOpenGraphMetaTags(
            createTags({
              title,
              description,
              image,
              twitterImage,
              siteUrl,
              type,
              seo
            })
          )}
        </Helmet>
        {children}
      </>
    )}
  />
);

SEO.displayName = "SEO";
