import React, { FC } from "react";
import styles from "./styles.module.scss";
import { Colour } from "../constants";
import cx from "classnames";
import { AppleStoreButton } from "components/AppStoreButtons/AppleStoreButton";
import { AndroidStoreButton } from "components/AppStoreButtons/AndroidStoreButton";

export enum PlatformType {
  ANDROID = "Android",
  APPLE = "Apple"
}

export interface AppStoreRatingCardProps {
  id: string;
  appStoreRating?: string;
  appStoreRatingDescription?: string;
  appStoreType?: PlatformType;
  backgroundColour: Colour;
  testIosQuickLink: string;
  iosQuickLink: string;
  testAndroidQuickLink: string;
  androidQuickLink: string;
}

export const AppStoreRatingCard: FC<AppStoreRatingCardProps> = ({
  appStoreRating,
  appStoreRatingDescription,
  appStoreType,
  backgroundColour,
  iosQuickLink,
  testIosQuickLink,
  androidQuickLink,
  testAndroidQuickLink
}) => {
  return (
    <div className={cx(styles.appStoreCard, styles[backgroundColour])}>
      <div>
        <p>
          {appStoreRating} {appStoreRatingDescription}
        </p>
      </div>
      <div>
        {appStoreType === PlatformType.APPLE && (
          <AppleStoreButton
            iosQuickLink={iosQuickLink}
            testIosQuickLink={testIosQuickLink}
            imgClassName={styles.appStore}
          />
        )}
        {appStoreType === PlatformType.ANDROID && (
          <AndroidStoreButton
            androidQuickLink={androidQuickLink}
            testAndroidQuickLink={testAndroidQuickLink}
            imgClassName={styles.playStore}
          />
        )}
      </div>
    </div>
  );
};
