import React, { FC } from "react";
import styles from "./InlineAsset.module.scss";
import { useIsClient } from "@mettle/hooks";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";

export interface InlineAssetProps {
  caption: string;
  url: string;
  width: number;
  height: number;
  aspectRatio?: number;
}

const defaultBaseSizes = [1280, 800, 480, 240];

export const InlineAsset: FC<InlineAssetProps> = ({
  caption,
  url,
  width,
  height,
  aspectRatio
}) => {
  const isClient = useIsClient();
  const image = getImage({
    layout: "fullWidth",
    width: width,
    height: height,
    images: {
      sources: [
        {
          sizes: generateSizes(width),
          srcSet: generateSrcSet(url, width, width / height, "webp"),
          type: "image/webp"
        }
      ],
      fallback: {
        src: url,
        srcSet: generateSrcSet(url, width, width / height, "jpg"),
        sizes: generateSizes(width)
      }
    }
  }) as IGatsbyImageData;

  return (
    <div className={styles.inlineAsset}>
      {isClient ? (
        <GatsbyImage className={styles.image} image={image} alt={caption} />
      ) : null}
      <p className={styles.caption}>{caption}</p>
    </div>
  );
};
InlineAsset.displayName = "InlineAsset";

/** This component is used for rendering a side-by-side pair of assets */
export const InlineAssetPair: FC<{ children: React.ReactNode }> = ({
  children
}) => <div className={styles.inlineAssetPair}>{children}</div>;
InlineAssetPair.displayName = "InlineAssetPair";

export const generateSrcSet = (
  url: string,
  fullWidth: number,
  ratio: number,
  format: string,
  baseSizes: number[] = defaultBaseSizes
): string => {
  const allSizes = [fullWidth, ...baseSizes.filter(x => x < fullWidth)];
  return allSizes
    .map((width, index) =>
      index
        ? `${url}?${new URLSearchParams({
            w: width.toFixed(),
            h: (width / ratio).toFixed(),
            fm: format
          }).toString()} ${width}w`
        : url
    )
    .reverse()
    .join(", ");
};

export const generateSizes = (
  fullWidth: number,
  baseSizes: number[] = defaultBaseSizes
): string => {
  const allSizes = [fullWidth, ...baseSizes.filter(x => x < fullWidth)];
  return allSizes
    .map((width, index) =>
      index ? `(max-width: ${width}px) ${width}px` : `${width}px`
    )
    .reverse()
    .join(", ");
};
