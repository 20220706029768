import { RefObject, useEffect } from "react";

/**
 * Given a ref to an HTML element, focus that element on mount.
 * Focus will be captured whenever the supplied ref changes.
 */
export const useCaptureFocusOnMount = (innerRef: RefObject<HTMLElement>) => {
  useEffect(() => {
    if (!innerRef.current) return;
    innerRef.current.focus();
  }, [innerRef.current]);
};
