import React from "react";
import cx from "classnames";
import kebabCase from "lodash/kebabCase";
import styles from "./TocLink.module.scss";

export interface TocLinkProps {
  heading: string;
  active: boolean;
  onClick?: () => void;
  doc?: () => Document;
}
export const TocLink: React.FC<TocLinkProps> = ({
  heading,
  active,
  onClick = () => {},
  doc = () => document
}) => (
  <a
    href="#"
    className={cx(styles.tocLink, { [styles.active]: active })}
    onClick={event => {
      event.preventDefault();
      const el = doc().getElementById(kebabCase(heading));
      el?.scrollIntoView(true);
      onClick();
    }}
  >
    {heading}
  </a>
);
