import React, { FC } from "react";
import YAMLData from "content/footer.yaml";
import { Colour } from "components/constants";
import { Panel, PanelItem, PanelLayout } from "components/Panel";
import styles from "./styles.module.scss";
import { SupplierVariant } from "./types";

interface SmallPrintPanelProps {
  variant: SupplierVariant;
}

export const SmallPrintPanel: FC<SmallPrintPanelProps> = ({ variant }) => (
  <Panel
    colour={Colour.black}
    className={styles.footerLinksPanel}
    short
    layout={PanelLayout.full}
  >
    <PanelItem className={styles.panelContent}>
      <p className={styles.smallPrint}>
        The Mettle bank account, provided by National Westminster Bank plc
        trading as Mettle, is protected by the Financial Services Compensation
        Scheme (FSCS). This means that eligible deposits are{" "}
        <a
          target="_blank"
          rel="noopener"
          href="https://www.fscs.org.uk/what-we-cover/#:~:text=Banks%2520%2526%2520building%2520societies&text=If%2520you%2520hold%2520money%2520with,building%2520society%2520or%2520credit%2520union"
        >
          protected up to £85,000
        </a>
        . For more information visit{" "}
        <a target="_blank" rel="noopener" href="https://www.fscs.org.uk/">
          FSCS.org.uk
        </a>
      </p>

      <p className={styles.smallPrint}>
        The Mettle e-money account is provided by PPS, a trading name of Prepay
        Technologies Ltd which is an electronic money institution regulated by
        the Financial Conduct Authority under the Electronic Money Regulations
        2011 (FRN 900010) for the issuing of electronic money. Financial
        Services Compensation Scheme (FSCS) protection does not apply to
        electronic money institutions.
      </p>

      <p className={styles.smallPrint}>
        Mastercard® is a registered trademark, and the circles design is a
        trademark of Mastercard International Incorporated.
      </p>
      <p className={styles.smallPrint}>
        National Westminster Bank plc has its registered office at:{" "}
        {YAMLData.companyDetails.address.replace(
          /^(\d+)\s+/,
          (_: string, x: string) => x + String.fromCharCode(160)
        )}
      </p>
      <p className={styles.smallPrint}>
        © {new Date().getFullYear()} National Westminster Bank plc
      </p>
    </PanelItem>
  </Panel>
);
