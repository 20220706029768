import React, { FC } from "react";
import { CmsBlogPost } from "../types";
import { BlogPostHero } from "./BlogPostHero";
import styles from "./BlogPost.module.scss";
import { Panel, PanelItem, PanelLayout } from "../../Panel";
import { Colour } from "../../constants";
import { VideoAsset } from "./VideoAsset";
import cx from "classnames";
import { ArticleSections } from "./BlogPost";
import { BlogPostMetadata } from "./PostMetadata";

export const BlogPostNative: FC<CmsBlogPost> = ({
  title,
  subTitle,
  heroImage,
  createdAt,
  publishedDate,
  readingTime,
  sections,
  heroVideo
}) => {
  return (
    <div itemScope itemProp="https://schema.org/BlogPosting">
      {heroVideo && (
        <Panel colour={Colour.warmStone} layout={PanelLayout.full} short>
          <PanelItem>
            <VideoAsset
              autoHeight
              poster={heroImage?.file?.url}
              url={heroVideo.file.url}
              fileName={heroVideo.file.fileName}
              videoType={heroVideo.description}
              contentType={heroVideo.file.contentType}
              className={cx(styles.heroVideo)}
            />
          </PanelItem>
        </Panel>
      )}
      <Panel layout={PanelLayout.full} colour={Colour.warmStone} short>
        <PanelItem className={styles.blogPost}>
          <div className={styles.container}>
            <article className={styles.article}>
              <h1 itemProp="headline">{title}</h1>
              <BlogPostMetadata
                categories={[]}
                createdAt={publishedDate || createdAt}
                readingTime={readingTime}
              />
              <h2 itemProp="abstract">{subTitle}</h2>
              {heroImage && !heroVideo && (
                <BlogPostHero heroImage={heroImage} className={styles.hero} />
              )}
              <section itemProp="articleBody">
                {sections.map((section, index) => {
                  const Component = ArticleSections[section.__typename];
                  return Component ? (
                    <Component key={index} {...section} />
                  ) : null;
                })}
              </section>
            </article>
          </div>
        </PanelItem>
      </Panel>
    </div>
  );
};
BlogPostNative.displayName = "BlogPostNative";
