import styles from "./styles.module.scss";
import cx from "classnames";
import React, { FC } from "react";
import { GlossaryNavigationBarProps } from "./GlossaryMobileNavigation";

export const GlossaryDesktopNavigation: FC<GlossaryNavigationBarProps> = ({
  links,
  activeScrollLink
}) => {
  return (
    <div className={styles.desktopNavigation} role="navigation">
      {links.map(link => (
        <a
          data-testid={`desktop-glossary-link-${link}`}
          key={link}
          className={cx(styles.link, {
            [styles.active]: link === activeScrollLink
          })}
          href={`#${link}`}
        >
          <h5>{link}</h5>
        </a>
      ))}
    </div>
  );
};

GlossaryDesktopNavigation.displayName = "GlossaryDesktopNavigation";
