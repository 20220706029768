import React, { FC } from "react";
import { BlogPostCard } from "./BlogPostCard";
import { CmsBlogPost } from "../types";
import styles from "./BlogHomeSection.module.scss";

export interface BlogHomeSectionProps {
  title?: string;
  posts: CmsBlogPost[];
  searchable?: boolean;
  showSubTitle?: boolean;
}

export const BlogHomeSection: FC<BlogHomeSectionProps> = ({ posts }) => {
  return (
    <section className={styles.blogHomeSection}>
      {posts.map(post => (
        <BlogPostCard
          __typename="ContentfulBlogPost"
          id={post.id}
          key={post.slug}
          post={post}
        />
      ))}
    </section>
  );
};
