import { CmsInfoAccordionItem } from "components/InfoAccordion";
import orderBy from "lodash/orderBy";

export type AlphabetisedKey = "A-D" | "E-H" | "I-L" | "M-Q" | "U-Z";

export type SortedGlossaryTerms = Record<
  AlphabetisedKey,
  {
    regex: RegExp;
    sortedTerms: CmsInfoAccordionItem[];
  }
>;

export const useSortGlossaryTerms = (
  glossaryTerms: CmsInfoAccordionItem[]
): SortedGlossaryTerms => {
  let sorted: SortedGlossaryTerms = {
    "A-D": {
      regex: /[A-D]/,
      sortedTerms: []
    },
    "E-H": {
      regex: /[E-H]/,
      sortedTerms: []
    },
    "I-L": {
      regex: /[I-L]/,
      sortedTerms: []
    },
    "M-Q": {
      regex: /[M-Q]/,
      sortedTerms: []
    },
    "U-Z": {
      regex: /[U-Z]/,
      sortedTerms: []
    }
  };

  glossaryTerms.forEach(term => {
    term = { ...term, title: term.title.trim() };

    Object.keys(sorted).forEach(chunk => {
      if (sorted[chunk].regex.test(term.title.charAt(0))) {
        sorted[chunk].sortedTerms.push(term);
        sorted[chunk].sortedTerms = orderBy(sorted[chunk].sortedTerms, [
          "title"
        ]);
      }
    });
  });

  return sorted;
};
