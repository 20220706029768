import React, { FC } from "react";
import { useConfig } from "hooks";
import { OnboardingProcess } from "conf/env";
import { OnboardingFlowProps } from "../types";
import { Panel, PanelItem } from "components/Panel";
import { Supports } from "components/OpenAccount/components/Supports";
import { EmailFormValues, OnboardingEmailForm } from "./EmailForm";
import { EmailSentConfirmation } from "./SentConfirmation";
import { QRCodeForm } from "./QRCodeForm";
import styles from "./styles.module.scss";
import { getOpenAccountEmailFormConfig } from "../../../CaptureLeadSection/emailFormConfig";
import { Colour } from "../../../constants";

export interface OnboardingDesktopFormProps {
  onSubmitted: (submittedValues: EmailFormValues) => void;
}

const formComponentMap = {
  [OnboardingProcess.EMAIL]: OnboardingEmailForm,
  [OnboardingProcess.QR]: QRCodeForm
};

export const OnboardingDesktopForm: FC<OnboardingDesktopFormProps> = ({
  onSubmitted
}) => {
  const config = useConfig();

  const FormComponent =
    formComponentMap[config?.ONBOARDING_PROCESS || OnboardingProcess.EMAIL];

  return (
    <section data-testid="onboarding-desktop" className={styles.onboard}>
      <Panel short colour={Colour.warmStone} className={styles.panel}>
        <FormComponent onSubmitted={onSubmitted} className={styles.form} />
      </Panel>
      <Panel short colour={Colour.warmStone}>
        <PanelItem>
          <div className={styles.bottom}>
            <Supports />
          </div>
        </PanelItem>
      </Panel>
    </section>
  );
};

export const OnboardingDesktop: FC<OnboardingFlowProps> = ({
  submittedValues,
  onSubmitted,
  onClose
}) => {
  if (submittedValues === null) {
    return <OnboardingDesktopForm onSubmitted={onSubmitted} />;
  } else {
    return (
      <EmailSentConfirmation
        onClose={onClose}
        onBack={() => onSubmitted(null)}
        submittedValues={submittedValues as EmailFormValues}
        emailFormConfig={getOpenAccountEmailFormConfig()}
      />
    );
  }
};
