import { graphql, useStaticQuery } from "gatsby";

export interface LegalDocsData {
  allLegalDocument: {
    nodes: {
      type: string;
      versions: string[];
      latestVersion: string;
      latestUri: string;
    }[];
  };
}

export const useGetLegalDocsData = () => {
  return useStaticQuery<LegalDocsData>(graphql`
    {
      allLegalDocument {
        nodes {
          type
          versions
          latestVersion
          latestUri
        }
      }
    }
  `);
};
