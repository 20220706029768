import React, { FC } from "react";
import { useScrollPercentage } from "hooks/useScrollPercentage";
import styles from "./ScrollProgressBar.module.scss";
import { useIsClient } from "@mettle/hooks";

export const ScrollProgressBar: FC = () => {
  const progress = useScrollPercentage({});
  const isClient = useIsClient();

  return isClient ? (
    <div className={styles.progressBar}>
      <div
        data-testid="progress"
        className={styles.progress}
        style={{ width: `${progress * 100}%` }}
      />
    </div>
  ) : null;
};
ScrollProgressBar.displayName = "ScrollProgressBar";
