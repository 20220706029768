import React from "react";
import * as Sentry from "@sentry/gatsby";
import cx from "classnames";
import { BranchData } from "branch-sdk";
import { captureLeadEmail, LeadResponse } from "lib";
import { Button } from "components/Button";
import { Link } from "components/Link";
import { BlueSpinner } from "../Spinner/Spinner";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import emailIllustration from "assets/illustrations/email.svg";
import back from "assets/icons/back.svg";
import styles from "./styles.module.scss";
import { EmailFormConfig } from "components/CaptureLeadSection/emailFormConfig";
import { EmailFormValues } from "../EmailForm";

interface ConfirmationProps {
  type: ConfirmationRenderingType;
  onBack?: () => void;
  onClose?: () => void;
  labels: ConfirmationLabels;
  hasImage: boolean;
  resubmit?: () => Promise<LeadResponse | BranchData | undefined>;
  darkText?: boolean;
}
export enum ConfirmationRenderingType {
  MODAL = "MODAL",
  PAGE = "PAGE"
}

export const SentConfirmation: React.FC<ConfirmationProps> = ({
  type = ConfirmationRenderingType.MODAL,
  onBack,
  onClose = () => {},
  labels,
  hasImage,
  resubmit,
  darkText = false
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [resendError, setResendError] = React.useState<Error>();

  const resubmitCallback = React.useCallback(async () => {
    try {
      setLoading(true);
      await resubmit!();
    } catch (error) {
      setResendError(
        new Error("Something went wrong. Refresh the page and try again.")
      );
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  }, [resubmit, setLoading]);

  return (
    <div
      className={cx(styles.sentConfirmation, {
        [styles.modal]: type === ConfirmationRenderingType.MODAL,
        [styles.page]: type === ConfirmationRenderingType.PAGE,
        [styles.darkText]: darkText
      })}
    >
      {loading && (
        <div className={styles.loading}>
          <BlueSpinner />
        </div>
      )}
      {type === ConfirmationRenderingType.MODAL && onBack && (
        <button
          className={styles.back}
          onClick={onBack}
          aria-label="Back"
          data-testid="back-button"
        >
          <img src={back} alt="Back" />
        </button>
      )}
      <section>
        {hasImage && <img src={emailIllustration} alt="Email" />}
        <h3>{labels.title}</h3>
        <p className={styles.confirmationText}>{labels.main}</p>
        {type === ConfirmationRenderingType.MODAL && (
          <Button
            onClick={onClose}
            className={styles.closeButton}
            aria-label="Okay"
            data-testid="ok-button"
          >
            Okay
          </Button>
        )}
        <p className={styles.bottomNote}>{labels.bottomNote}</p>
        {resubmit && (
          <div className={styles.resend}>
            <Link onClick={resubmitCallback} aria-label="Resend message">
              Resend message
            </Link>
          </div>
        )}
        {type === ConfirmationRenderingType.PAGE && onBack && (
          <div className={styles.differentNumber} data-testid="back-link">
            <Link onClick={onBack} aria-label="Send to a different number">
              Send to a different number
            </Link>
          </div>
        )}
        {resendError && (
          <ErrorMessage error={resendError} className={styles.error} />
        )}
      </section>
    </div>
  );
};
SentConfirmation.displayName = "SentConfirmation";

interface Props {
  onBack: () => void;
  onClose?: () => void;
  type?: ConfirmationRenderingType;
}

interface EmailFormConfirmation extends Props {
  submittedValues: EmailFormValues;
  emailFormConfig: EmailFormConfig;
}

export const EmailSentConfirmation: React.FC<EmailFormConfirmation> = ({
  type = ConfirmationRenderingType.MODAL,
  onBack,
  onClose,
  submittedValues,
  emailFormConfig
}) => {
  const email = submittedValues.email;
  const resubmit = React.useCallback(
    () => captureLeadEmail(emailFormConfig, email),
    [email]
  );

  return (
    <SentConfirmation
      type={type}
      labels={getEmailLabels(email)}
      onBack={onBack}
      onClose={onClose}
      hasImage
      resubmit={resubmit}
    />
  );
};
EmailSentConfirmation.displayName = "EmailSentConfirmation";
interface ConfirmationLabels {
  title: string;
  main: React.ReactNode;
  bottomNote: string;
}

const getEmailLabels = (email: string): ConfirmationLabels => ({
  title: "Check your inbox",
  main: `We've sent an email to ${email}. Open the email on your phone to get started.`,
  bottomNote:
    "It could take a few minutes for the email to arrive. Please check your spam folders."
});
