import React, { FC } from "react";
import { CmsInfoAccordionSection } from "../InfoAccordion";
import { Panel, PanelItem, PanelLayout } from "components/Panel";
import { IdentImageTitle } from "../IdentImageTitle";
import { Colour, SectionPaddingDefault } from "../constants";
import cx from "classnames";
import styles from "./styles.module.scss";
import { InfoAccordionItem } from "../InfoAccordion/InfoAccordionItem";
import { useBlogRichText } from "../Blog/hooks";
import { useIsBlogPage } from "../../hooks/useIsBlogPage";

type FaqSectionProps = Omit<CmsInfoAccordionSection, "__typename">;

export const FaqSection: FC<FaqSectionProps> = ({
  items,
  title,
  titleIdentImage,
  sectionPaddingBottom,
  sectionPaddingTop,
  footerRichText,
  format
}) => {
  const isBlog = useIsBlogPage();

  return (
    <Panel short layout={PanelLayout.full} colour={Colour.warmStone}>
      <PanelItem
        className={cx(
          styles.faqSection,
          styles[sectionPaddingTop || SectionPaddingDefault.TOP],
          styles[sectionPaddingBottom || SectionPaddingDefault.BOTTOM],
          { [styles.isBlog]: isBlog }
        )}
      >
        {titleIdentImage ? (
          <div className={styles.title}>
            <IdentImageTitle
              Tag="h4"
              image={titleIdentImage}
              title={title}
              imageAlignment="right"
            />
          </div>
        ) : (
          <h4 className={cx(styles.title, styles.noIdentImage)}>{title}</h4>
        )}

        <div className={styles.items}>
          {items.map(item => {
            return (
              <InfoAccordionItem format={format} key={item.id} {...item} />
            );
          })}
        </div>
        {footerRichText && (
          <div className={styles.footerText}>
            {useBlogRichText(footerRichText)}
          </div>
        )}
      </PanelItem>
    </Panel>
  );
};

FaqSection.displayName = "FaqSection";
