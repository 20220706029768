import React, { FC, useEffect, useRef } from "react";
import lottie from "lottie-web";

export interface LottieAnimationProps {
  className?: string;
  play: boolean;
  path: string;
  loop: boolean;
}

export const LottieAnimation: FC<LottieAnimationProps> = ({
  className,
  play,
  path,
  loop = false
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef({
    play: () => {},
    stop: () => {}
  });

  useEffect(() => {
    const containerEl = containerRef.current;

    if (containerEl) {
      animationRef.current = lottie.loadAnimation({
        container: containerEl,
        autoplay: false,
        loop: loop,
        path,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      });
    }
  }, [loop, path]);

  useEffect(() => {
    if (play) {
      animationRef.current.play();
    } else {
      animationRef.current.stop();
    }
  }, [play]);

  return <div className={className} ref={containerRef} />;
};

LottieAnimation.displayName = "LottieAnimation";
