export interface RetryParams {
  fn: () => Promise<void>;
  maxAttempts: number;
  delayInSeconds: number;
}

export const retry = async ({
  fn,
  maxAttempts,
  delayInSeconds
}: RetryParams) => {
  const execute: Function = async (attempt: number) => {
    try {
      return await fn();
    } catch (err) {
      if (attempt <= maxAttempts) {
        const nextAttempt = attempt + 1;
        console.error(`Retrying after ${delayInSeconds} seconds due to:`, err);
        return delay(() => execute(nextAttempt), delayInSeconds * 1000);
      } else {
        throw err;
      }
    }
  };
  return execute(1);
};

const delay = (fn: () => Promise<void>, ms: number) =>
  new Promise(resolve => setTimeout(() => resolve(fn()), ms));
