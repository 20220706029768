export enum Size {
  small = "small",
  medium = "medium",
  large = "large"
}

export enum NumberSize {
  small = "small",
  large = "large"
}

export enum HorizontalPosition {
  left = "left",
  right = "right"
}

export enum VerticalPosition {
  top = "top",
  bottom = "bottom"
}

export enum BodySize {
  maxTablet = 1080,
  maxMobile = 720,
  maxSmallMobile = 320
}

export enum HeaderHeight {
  large = 80,
  medium = 64,
  small = 56
}

export enum SectionPaddingDefault {
  TOP = "paddingTop64",
  BOTTOM = "paddingBottom64"
}
