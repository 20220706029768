import React, { FC } from "react";
import { graphql } from "gatsby";
import { BlogPost } from "components/Blog";
import { Colour } from "../../../components/constants";
import { DefaultLayout } from "layouts";
import { PrivacyPolicyPageProps } from "../e-money";

export const GeniePrivacyPage: FC<PrivacyPolicyPageProps> = ({
  data: { page }
}) => {
  return (
    <DefaultLayout
      title={page.metaTitle || page.title}
      description={page.metaDescription}
      background={Colour.warmStone}
    >
      <BlogPost {...page} isPrivacyPage={true} />
    </DefaultLayout>
  );
};

export default GeniePrivacyPage;

export const pageQuery = graphql`
  query getGeniePrivacyPolicy {
    page: contentfulBlogPost(contentful_id: { eq: "y1bGbsjM3t1EMQ03UH6KR" }) {
      slug
      title
      metaTitle
      canonicalUrl
      metaDescription
      subTitle
      publishedDate
      createdAt
      updatedAt
      readingTime
      sections {
        ...ContentfulCardPanelFragment
        ...ContentfulFourCardPanelFragment
        ...ContentfulBlogPostRichTextSectionFragment
      }
      categories {
        title
        slug
      }
      hasTableOfContents
      documents {
        title
        file {
          fileName
          url
        }
      }
    }
  }
`;
