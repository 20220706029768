import React, { FC } from "react";
import styles from "./styles.module.scss";
import cx from "classnames";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { CmsResponsiveImage } from "../Blog";

export enum AuthorImageSize {
  LARGE = "large",
  SMALL = "small",
  MEDIUM = "medium"
}

export interface AuthorImageProps {
  photo: CmsResponsiveImage;
  name: string;
  className?: string;
  size?: AuthorImageSize;
}

export const AuthorImage: FC<AuthorImageProps> = ({
  photo,
  name,
  className,
  size = AuthorImageSize.SMALL
}) => {
  return (
    <GatsbyImage
      className={cx(className, styles.authorImage, {
        [styles.small]: size === AuthorImageSize.SMALL,
        [styles.medium]: size === AuthorImageSize.MEDIUM,
        [styles.large]: size === AuthorImageSize.LARGE
      })}
      imgStyle={{ objectFit: "cover" }}
      image={getImage(photo.gatsbyImageData) as IGatsbyImageData}
      itemProp="image"
      alt={name}
    />
  );
};
