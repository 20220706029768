const ProductionPrefix = "/app/";
const DevelopmentPrefix = "/app/dev/";
const StagingPrefix = "/app/staging/";

export const mobileDeepLink = (pathName: string) => {
  let schema = "mettle:/";
  let path = pathName;
  if (pathName.match(DevelopmentPrefix)) {
    schema = "mettle-dev:/";
    path = pathName.replace(DevelopmentPrefix, ProductionPrefix);
  } else if (pathName.match(StagingPrefix)) {
    schema = "mettle-staging:/";
    path = pathName.replace(StagingPrefix, ProductionPrefix);
  }

  return schema + path;
};
