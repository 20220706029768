import {
  createEmailValidationFunction,
  EmailIcon
} from "components/OpenAccount/flows/desktop/EmailForm";
import { Form } from "components/OpenAccount/flows/desktop/Form/Form";
import { formStateHook } from "hooks";
import React, { FC } from "react";
import { LostCardPage } from "../LostCardPageLayout";
import { getLostCardEmailFormConfig } from "./lostCardEmailFormConfig";
import emailStyles from "components/OpenAccount/flows/desktop/EmailForm/styles.module.scss";
import styles from "./styles.module.scss";
import { fetchCarefully } from "lib/fetchCarefully";

interface LostCardFormProps {
  page: LostCardPage;
  onSubmitted: () => void;
}

interface EmailFormValues {
  email: string;
  confirmCardLock: boolean;
}

export interface LostCardEmailFormConfig {
  confirmCardLockText: string;
  noEmailValidationError: string;
  emailValidationError: string;
}

const createPolicyAgreedValidationFunction = () => (agreed: boolean) => {
  if (!agreed) {
    throw new Error();
  }
};

const getConfirmCardLockFieldConfig = (
  lostCardEmailFormConfig: LostCardEmailFormConfig
) => {
  return {
    type: "checkbox",
    applyFocus: false,
    label: (
      <p className={styles.confirmCheckboxText}>
        {lostCardEmailFormConfig.confirmCardLockText}
      </p>
    ),
    required: true,
    validate: createPolicyAgreedValidationFunction()
  };
};

export const postLostCardEmailAddress = async (emailAddress: string) => {
  if (!emailAddress) return;
  const response = await fetchCarefully("/cmd/card/ooh/lock/request", {
    method: "POST",
    body: JSON.stringify({ email: emailAddress }),
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error("Unsuccessful response received");
  }
};

const createLostCardFormHook = (
  emailFormConfig: LostCardEmailFormConfig,
  onSubmit: (emailAddress: string) => Promise<void>
) =>
  formStateHook<EmailFormValues>({
    fields: {
      email: {
        type: "text",
        applyFocus: false,
        placeholder: "Email address",
        className: emailStyles.emailInput,
        focusClassName: emailStyles.focus,
        required: true,
        pattern: /.+/,
        extraContent: <EmailIcon />,
        validate: createEmailValidationFunction(emailFormConfig)
      },
      confirmCardLock: getConfirmCardLockFieldConfig(emailFormConfig)
    },
    async submit(values) {
      await onSubmit(values.email);
    }
  });

export const LostCardForm: FC<LostCardFormProps> = ({ page, onSubmitted }) => {
  const config = getLostCardEmailFormConfig(page.formCheckboxText!);
  const useEmailForm = createLostCardFormHook(config, postLostCardEmailAddress);
  const emailForm = useEmailForm({ onSubmitted: onSubmitted });

  return (
    <Form
      {...emailForm}
      onSubmitted={() => {}}
      sendButtonText="Submit"
      className={styles.lostCardEmailForm}
    />
  );
};

export default LostCardForm;
