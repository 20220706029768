import { EventHandler, SyntheticEvent } from "react";
import { handleNavigation, withDefaultPrevented } from "./handleNavigation";
import { useExternalNavigation } from "../../../hooks";

export const useClickHandler = <
  E extends HTMLElement,
  V extends React.SyntheticEvent<E, any>
>(
  url?: string,
  onClick?: EventHandler<V>,
  external?: boolean
): React.EventHandler<V> => {
  const logExternalNavigation = useExternalNavigation();

  if (onClick) {
    return withDefaultPrevented(onClick);
  }

  if (url) {
    const handler = handleNavigation(url, external);
    return (event: SyntheticEvent<HTMLElement, any>) => {
      logExternalNavigation(url, external);
      handler(event);
    };
  }
  return () => {};
};
