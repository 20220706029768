import React, { FC } from "react";
import { Button, ButtonVariants } from "../../Button";
import { OpenAccountButton } from "components/OpenAccount/OpenAccountButton";
import { Colour } from "../../constants";
import { Link } from "../../Link";
import cx from "classnames";
import styles from "./styles.module.scss";

export interface HeroCtaProps {
  className?: string;
  primaryCtaText?: string;
  primaryCtaUrl?: string;
  secondaryCtaText?: string;
  secondaryCtaUrl?: string;
  linkUrl?: string;
  linkText?: string;
  showOpenAccountButton?: boolean;
  openAccountButtonText?: string;
  backgroundColour?: Colour;
}

export const HeroCtas: FC<HeroCtaProps> = ({
  className,
  primaryCtaText,
  primaryCtaUrl,
  secondaryCtaText,
  secondaryCtaUrl,
  linkUrl,
  linkText,
  backgroundColour,
  showOpenAccountButton,
  openAccountButtonText
}) => {
  const hasContent = () =>
    (primaryCtaText && primaryCtaUrl) ||
    (secondaryCtaText && secondaryCtaUrl) ||
    (linkText && linkUrl) ||
    showOpenAccountButton;

  return (
    <>
      {hasContent() && (
        <div className={cx(styles.heroCtas, className)}>
          {showOpenAccountButton && (
            <OpenAccountButton text={openAccountButtonText} />
          )}
          {primaryCtaText && primaryCtaUrl && (
            <Button url={primaryCtaUrl}>{primaryCtaText}</Button>
          )}
          {secondaryCtaText && secondaryCtaUrl && (
            <Button variant={ButtonVariants.SECONDARY} url={secondaryCtaUrl}>
              {secondaryCtaText}
            </Button>
          )}
          {linkUrl && linkText && (
            <Link
              className={styles.heroLink}
              url={linkUrl}
              backgroundColour={backgroundColour}
            >
              {linkText}
            </Link>
          )}
        </div>
      )}
    </>
  );
};

HeroCtas.displayName = "Hero Ctas";
