import React, { FC } from "react";
import cx from "classnames";
import styles from "./styles.module.scss";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { ImageFit } from "../../CardPanel";
import { CmsResponsiveImage } from "../../Blog";

export interface CardImageProps {
  hideImageOnMobile?: boolean;
  imageFit?: ImageFit;
  image: CmsResponsiveImage;
  className?: string;
}

export const CardImage: FC<CardImageProps> = ({
  hideImageOnMobile = false,
  imageFit,
  image,
  className
}) => {
  return (
    <div
      className={cx(styles.cardImage, className, {
        [styles.hiddenOnMobile]: Boolean(hideImageOnMobile)
      })}
    >
      <GatsbyImage
        imgStyle={{
          objectFit: imageFit || ImageFit.CONTAIN
        }}
        image={getImage(image.gatsbyImageData) as IGatsbyImageData}
        alt={image.title}
      />
    </div>
  );
};
