import cx from "classnames";
import React, { FC } from "react";
import styles from "./styles.module.scss";
import { HorizontalPosition, VerticalPosition } from "components/constants";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";

export interface BackgroundImagePanelItemProps {
  className?: string;
  image: IGatsbyImageData;
  horizontalPosition?: HorizontalPosition;
  verticalPosition?: VerticalPosition;
  maxHeight?: number;
  children?: React.ReactNode;
}

export const BackgroundImagePanelItem: FC<BackgroundImagePanelItemProps> = ({
  horizontalPosition,
  verticalPosition,
  image,
  className,
  children,
  maxHeight = 1440,
  ...props
}) => {
  const fetchedImage = getImage(image);

  return (
    <div
      className={cx(styles.item, {
        [styles[horizontalPosition!]]: Boolean(horizontalPosition),
        [styles[verticalPosition!]]: Boolean(verticalPosition),
        [className!]: Boolean(className)
      })}
      style={{
        maxHeight
      }}
      {...props}
    >
      <GatsbyImage
        className={styles.backgroundImage}
        alt="NatWestBoxed"
        image={fetchedImage as IGatsbyImageData}
      />
      {children}
    </div>
  );
};
