import React, { FC } from "react";
import { graphql } from "gatsby";
import { CmsResponsiveImage } from "components/Blog";
import { CmsAsset, CmsPageSectionBase } from "components/CardPanel";
import { CmsAuthor } from "components/Blog";
import { FaqSection } from "components/FaqSection";
import { Glossary } from "components/Glossary";
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData
} from "gatsby-source-contentful/rich-text";

export enum InfoAccordionFormat {
  Glossary = "Glossary",
  FAQ = "FAQ"
}

export interface CmsInfoAccordionItem {
  id: string;
  title: string;
  firstParagraph: { firstParagraph: string };
  expansionText?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  author: CmsAuthor;
  video?: CmsAsset;
  videoPoster?: CmsAsset;
}

export interface CmsInfoAccordionSection extends CmsPageSectionBase {
  title: string;
  titleIdentImage?: CmsResponsiveImage;
  format: InfoAccordionFormat;
  items: CmsInfoAccordionItem[];
  footerRichText?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
}

export const InfoAccordion: FC<CmsInfoAccordionSection> = ({
  title,
  format,
  items,
  footerRichText,
  sectionPaddingTop,
  sectionPaddingBottom,
  titleIdentImage
}) =>
  format === InfoAccordionFormat.Glossary ? (
    <Glossary format={format} definitions={items} title={title} />
  ) : (
    <FaqSection
      title={title}
      items={items}
      titleIdentImage={titleIdentImage}
      footerRichText={footerRichText}
      sectionPaddingTop={sectionPaddingTop}
      sectionPaddingBottom={sectionPaddingBottom}
      format={format}
    />
  );

export const ContentfulInfoAccordionFragment = graphql`
  fragment ContentfulInfoAccordionFragment on ContentfulInfoAccordion {
    __typename
    contentful_id
    title
    titleIdentImage {
      gatsbyImageData(placeholder: BLURRED)
      title
    }
    format
    sectionPaddingBottom
    sectionPaddingTop
    items {
      id
      title
      firstParagraph {
        firstParagraph
      }
      expansionText {
        raw
      }
      author {
        name
      }
      video {
        file {
          url
          fileName
        }
      }
      videoPoster {
        file {
          url
        }
      }
    }
    footerRichText: footerText {
      raw
    }
  }
`;
