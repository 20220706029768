import React, { FC, useEffect, useRef, useState } from "react";
import { CmsPageSection, PageSections } from "./index";
import { BodySize, HeaderHeight } from "../constants";

interface DynamicPageSectionsProps {
  sections: CmsPageSection[];
  slug: string;
}

const getHeaderHeight = (windowWidth: number) => {
  if (windowWidth > BodySize.maxMobile) {
    return HeaderHeight.large;
  } else if (
    windowWidth <= BodySize.maxMobile &&
    windowWidth >= BodySize.maxSmallMobile
  ) {
    return HeaderHeight.medium;
  } else {
    return HeaderHeight.small;
  }
};

export const DynamicPageSections: FC<DynamicPageSectionsProps> = ({
  sections,
  slug
}) => {
  const firstSectionRef = useRef<HTMLDivElement | null>(null);
  const [{ width, offset }, offsetState] = useState({
    offset: 0,
    width: 0
  });

  const calculateOffset = () => {
    const newWidth = window.innerWidth;
    const elementHeight = firstSectionRef.current?.clientHeight || 0;
    const headerHeight = getHeaderHeight(window.innerWidth);

    if (firstSectionRef.current && width !== newWidth) {
      if (elementHeight >= window.innerHeight - headerHeight) {
        const diff =
          window.innerHeight - (firstSectionRef.current?.clientHeight || 0);

        offsetState({
          width: window.innerWidth,
          offset: Math.min(headerHeight, diff)
        });
      } else {
        offsetState({
          width: window.innerWidth,
          offset: headerHeight
        });
      }
    }
  };

  // Intentionally empty dep array for initial render update. Width changes handled in resize callback.
  useEffect(() => calculateOffset(), []);

  useEffect(() => {
    window.addEventListener("resize", calculateOffset);

    return () => window.removeEventListener("resize", calculateOffset);
  }, [width]);

  return (
    <>
      {sections.map((section, index) => {
        const Component = PageSections[section.__typename];
        return Component ? (
          // Ignoring for now to avoid adding types to deprecated components that will be removed in https://eeveebank.atlassian.net/browse/MW-62
          <div
            data-testid={`index${index}`}
            style={
              // @ts-ignore
              !index && section.parallax
                ? { position: "sticky", top: offset }
                : {}
            }
            // @ts-ignore
            id={`s${section.contentful_id}`}
            // @ts-ignore
            key={section.contentful_id}
            // @ts-ignore
            ref={!index && section.parallax ? firstSectionRef : null}
          >
            <Component
              isPageSection
              key={index}
              pagePosition={index}
              slug={slug}
              {...section}
            />
          </div>
        ) : null;
      })}
    </>
  );
};

DynamicPageSections.displayName = "DynamicPageSections";
