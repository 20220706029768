import cx from "classnames";
import React, { FC, AllHTMLAttributes } from "react";
import styles from "./styles.module.scss";
import { HorizontalPosition, VerticalPosition } from "components/constants";

export interface PanelItemProps extends AllHTMLAttributes<HTMLDivElement> {
  className?: string;
  horizontalPosition?: HorizontalPosition;
  verticalPosition?: VerticalPosition;
}

export const PanelItem: FC<PanelItemProps> = ({
  horizontalPosition,
  verticalPosition,
  className,
  children,
  ...props
}) => (
  <div
    className={cx(styles.item, {
      [styles[horizontalPosition!]]: Boolean(horizontalPosition),
      [styles[verticalPosition!]]: Boolean(verticalPosition),
      [className!]: Boolean(className)
    })}
    {...props}
  >
    {children}
  </div>
);
