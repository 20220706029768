import React, { FC, MouseEventHandler } from "react";
import { CmsCardPanelFullScreenTakeover } from "../../CardPanel";
import styles from "./styles.module.scss";
import blackClose from "assets/icons/blackClose.svg";

export interface FullScreenTakeoverProps {
  fullScreenTakeover: CmsCardPanelFullScreenTakeover;
  closeHandler: MouseEventHandler;
  shown: Boolean;
}

export const FullScreenTakeover: FC<FullScreenTakeoverProps> = ({
  fullScreenTakeover,
  shown,
  closeHandler
}) => {
  return (
    <div
      style={{ display: shown ? "flex" : "none" }}
      className={styles.fullScreenTakeover}
    >
      <div className={styles.inner}>
        <button
          type="button"
          className={styles.closeButton}
          onClick={closeHandler}
        >
          <img src={blackClose} alt="Close" />
        </button>
        {fullScreenTakeover.title && <h4>{fullScreenTakeover.title}</h4>}
        {fullScreenTakeover.description?.description && (
          <p>{fullScreenTakeover.description.description}</p>
        )}
        {fullScreenTakeover.listTitle && (
          <p data-testid="listTitle" className={styles.listTitle}>
            {fullScreenTakeover.listTitle}
          </p>
        )}
        {fullScreenTakeover.listItems?.length ? (
          <ul data-testid="listItems" className={styles.list}>
            {fullScreenTakeover?.listItems?.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        ) : null}
      </div>
    </div>
  );
};
