import { mobileDeepLink } from "lib";
import { useIsClient } from "@mettle/hooks";

export enum DeepLinkTypes {
  Xero = "xero",
  FreeAgent = "freeagent",
  MonthlyStatements = "monthly-statements",
  Default = "default"
}

export interface MobileAppLinks {
  universalDeepLink?: string;
  internalDeepLink?: string;
  deepLinkType: DeepLinkTypes;
}

const getDeepLinkType = (universalDeepLink: string): DeepLinkTypes => {
  const matchedLinkValue = Object.values(DeepLinkTypes).find(linkType =>
    universalDeepLink.match(linkType)
  );
  return matchedLinkValue
    ? (matchedLinkValue as DeepLinkTypes)
    : DeepLinkTypes.Default;
};

export const useMobileAppLink = (): MobileAppLinks => {
  if (!useIsClient()) return { deepLinkType: DeepLinkTypes.Default };

  const universalDeepLink = window.location.href;
  const internalDeepLink = mobileDeepLink(
    window.location.pathname + window.location.search
  );
  const deepLinkType = getDeepLinkType(universalDeepLink);

  return {
    universalDeepLink,
    internalDeepLink,
    deepLinkType
  };
};
