import React, { FC, useEffect, useState } from "react";
import { Colour, SectionPaddingDefault } from "../constants";
import { graphql } from "gatsby";
import { Panel, PanelItem, PanelLayout } from "components/Panel";
import styles from "./styles.module.scss";
import cx from "classnames";
import { Link } from "../Link";
import { CmsPageSectionBase } from "../CardPanel";

export interface CmsDismissibleBannerSection extends CmsPageSectionBase {
  id: string;
  title?: string;
  subtitle?: string;
  linkUrl?: string;
  linkText?: string;
  linkPosition: "Stacked" | "Inline";
  formFactor: "Tall" | "Skinny";
  sticky: Boolean;
  backgroundColour: Colour;
}

export const DismissibleBanner: FC<CmsDismissibleBannerSection> = ({
  title,
  subtitle,
  linkUrl,
  linkText,
  backgroundColour,
  id,
  sectionPaddingBottom,
  sectionPaddingTop,
  formFactor = "Tall",
  linkPosition = "Stacked",
  sticky
}) => {
  const [hidden, setHidden] = useState(true);
  // const handleDismiss = () => {
  //   setHidden(true);
  //
  //   if (!closeOnly) {
  //     window.localStorage.setItem(id, "viewed");
  //   }
  // };
  //
  // const handleOpen = () => {
  //   setHidden(false);
  // };
  const configureBanner = () => {
    setHidden(Boolean(window.localStorage.getItem(id)));
  };

  const BannerLink = () => (
    <Link
      className={styles.link}
      url={linkUrl}
      backgroundColour={backgroundColour}
    >
      {linkText}
    </Link>
  );

  useEffect(() => {
    configureBanner();
  }, []);

  return (
    <Panel
      className={cx(styles.dismissibleBanner, {
        [styles.hidden]: hidden,
        [styles.sticky]: Boolean(sticky)
      })}
      layout={PanelLayout.full}
      colour={Colour[backgroundColour]}
      short
    >
      <PanelItem className={cx(styles.container, { [styles.hidden]: hidden })}>
        <div
          className={cx(
            styles.content,
            styles[sectionPaddingTop || SectionPaddingDefault.TOP],
            styles[sectionPaddingBottom || SectionPaddingDefault.BOTTOM],
            {
              [styles.hidden]: hidden,
              [styles.skinny]: formFactor === "Skinny"
            }
          )}
        >
          {title && <h5 data-testid="title">{title}</h5>}
          {subtitle && (
            <p data-testid="subtitle">
              {subtitle}&nbsp;
              {linkUrl &&
                linkText &&
                formFactor === "Tall" &&
                linkPosition === "Inline" && <BannerLink />}
            </p>
          )}
          {linkUrl &&
            linkText &&
            (linkPosition === "Stacked" || formFactor === "Skinny") && (
              <BannerLink />
            )}
        </div>
      </PanelItem>

      {/* Temp removal - awaiting redesign see: https://eeveebank.atlassian.net/browse/MB-2460 */}
      {/*<div className={styles.buttonContainer}>*/}
      {/*  {(closeOnly || !hidden) && (*/}
      {/*    <IconographyButton*/}
      {/*      className={cx(styles.dismissButton, {*/}
      {/*        [styles.hidden]: hidden && closeOnly*/}
      {/*      })}*/}
      {/*      icon={closeOnly ? downArrow : crossWhite}*/}
      {/*      handler={!hidden ? handleDismiss : handleOpen}*/}
      {/*      value="Close"*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</div>*/}
    </Panel>
  );
};

DismissibleBanner.displayName = "DismissibleBanner";

export const ContentfulDismissibleBannerFragment = graphql`
  fragment ContentfulDismissibleBannerFragment on ContentfulDismissibleBanner {
    __typename
    id
    contentful_id
    title
    subtitle
    linkUrl
    linkText
    linkPosition
    formFactor
    sticky
    backgroundColour
    closeOnly
    sectionPaddingTop
    sectionPaddingBottom
  }
`;
