import React from "react";
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData
} from "gatsby-source-contentful/rich-text";

import { useBlogRichText } from "../Blog/hooks";
import styles from "./styles.module.scss";

interface RichTextProps {
  body: RenderRichTextData<ContentfulRichTextGatsbyReference>;
}

export const RichText: React.FC<RichTextProps> = ({ body }) => (
  <div className={styles.richText}>
    <section>{useBlogRichText(body)}</section>
  </div>
);

RichText.displayName = "RichText";
