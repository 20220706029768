import React, { FC, useEffect, useRef, useState } from "react";

interface InlineVideoProps {
  src: string;
  play: boolean;
  loop: boolean;
}

export const InlineVideo: FC<InlineVideoProps> = ({
  src,
  play = false,
  loop = false
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [canPlay, setCanPlay] = useState(false);
  const handleCanPlayThrough = () => {
    setCanPlay(true);
  };

  useEffect(() => {
    if (videoRef.current && play && canPlay) {
      videoRef.current.play();
    }
  }, [play, canPlay]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener("canplaythrough", handleCanPlayThrough);
    }

    return () =>
      videoRef.current?.removeEventListener(
        "canplaythrough",
        handleCanPlayThrough
      );
  }, []);

  return (
    <video
      data-testid="video"
      ref={videoRef}
      controls={false}
      autoPlay={true}
      preload="metadata"
      src={src}
      loop={loop}
      muted={true}
      playsInline={true}
    />
  );
};

InlineVideo.displayName = "InlineVideo";
