import { DeviceOS } from "@mettle/hooks";

export function isAndroid(os: DeviceOS): boolean {
  return os === DeviceOS.Android;
}

export function isIOs(os: DeviceOS): boolean {
  return os === DeviceOS.iOS;
}

export function isDesktop(os: DeviceOS): boolean {
  return os === DeviceOS.Desktop;
}

export const isMobileOs = (os: DeviceOS) => isIOs(os) || isAndroid(os);
