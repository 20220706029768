import React, { FC } from "react";
import { CmsPageSectionBase } from "../CardPanel";
import { Colour, SectionPaddingDefault } from "../constants";
import { Panel, PanelItem, PanelLayout } from "../Panel";
import styles from "./styles.module.scss";
import { graphql } from "gatsby";
import { PanelTitles } from "../PanelTitles";
import cx from "classnames";
import { SimpleInterestCalculator } from "./Calculators/SimpleInterestCalculator";
import { CompoundInterestCalculator } from "./Calculators/CompoundInterestCalculator";
import { useIsBlogPage } from "../../hooks/useIsBlogPage";

export enum CalculatorType {
  SIMPLE_INTEREST = "simpleInterest",
  COMPOUND_INTEREST = "compoundInterest"
}

export interface CmsCalculatorSection extends CmsPageSectionBase {
  title: string;
  calculatorTitle: string;
  calculatorBodyCopy: {
    calculatorBodyCopy: string;
  };
  footnote: string;
  backgroundColour: Colour;
  calculatorType: CalculatorType;
}

const calculatorTypeMap: Record<CalculatorType, React.FC> = {
  [CalculatorType.SIMPLE_INTEREST]: SimpleInterestCalculator,
  [CalculatorType.COMPOUND_INTEREST]: CompoundInterestCalculator
};

export const CalculatorSection: FC<CmsCalculatorSection> = ({
  title,
  calculatorTitle,
  calculatorBodyCopy: { calculatorBodyCopy },
  footnote,
  backgroundColour,
  calculatorType,
  sectionPaddingBottom,
  sectionPaddingTop
}) => {
  const Calculator = calculatorTypeMap[calculatorType];

  return (
    <Panel
      className={cx(
        styles.calculatorSection,
        styles[sectionPaddingTop || SectionPaddingDefault.TOP],
        styles[sectionPaddingBottom || SectionPaddingDefault.BOTTOM]
      )}
      layout={PanelLayout.full}
      colour={Colour[backgroundColour]}
      short
    >
      <PanelItem className={cx({ [styles.blogItem]: useIsBlogPage() })}>
        <PanelTitles title={title} />
        <div className={cx(styles.content)}>
          <div
            className={cx(styles.calculatorPane, {
              [styles.isBlog]: useIsBlogPage()
            })}
          >
            <div className={styles.text}>
              <h5 className={styles.calculatorTitle}>{calculatorTitle}</h5>
              <p>{calculatorBodyCopy}</p>
            </div>
            <div className={styles.calculator}>
              {Calculator ? <Calculator /> : null}
            </div>
          </div>
          <div className={styles.footnote}>{footnote}</div>
        </div>
      </PanelItem>
    </Panel>
  );
};

CalculatorSection.displayName = "CalculatorSection";

export const ContentfulCalculatorSectionFragment = graphql`
  fragment ContentfulCalculatorSectionFragment on ContentfulCalculatorSection {
    __typename
    id
    contentful_id
    title
    calculatorTitle
    calculatorBodyCopy {
      calculatorBodyCopy
    }
    calculatorType
    backgroundColour
    footnote
    sectionPaddingTop
    sectionPaddingBottom
  }
`;
