import { Panel, PanelItem, PanelLayout } from "components/Panel";
import React, { FC } from "react";
import styles from "./styles.module.scss";
import { Colour, SectionPaddingDefault } from "components/constants";
import { Card } from "../Card";
import cx from "classnames";
import { graphql } from "gatsby";
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData
} from "gatsby-source-contentful/rich-text";
import { useIsBlogPage } from "hooks/useIsBlogPage";
import { CmsImage, CmsResponsiveImage } from "components/Blog";
import { useIsPrivacyPage } from "../../hooks/useIsPrivacyPage";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { PanelTitles } from "../PanelTitles";
import { CmsQuoteCard, QuoteCard } from "../QuoteCard";

export interface CmsCardPanelFullScreenTakeover {
  id: string;
  title: string;
  listItems?: string[];
  listTitle?: string;
  description: {
    description: string;
  };
}

export interface CmsCardPanelCardTakeover {
  title?: string;
  subtitle?: string;
  bodyCopy?: string;
  image?: CmsResponsiveImage;
  imageFit?: ImageFit;
  richText?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  backgroundColour?: Colour;
}

export enum ImageFit {
  CONTAIN = "contain",
  COVER = "cover"
}

export enum CardCopyPosition {
  TOP = "top",
  BOTTOM = "bottom",
  CENTRE = "centre"
}

export interface CmsAsset {
  file: {
    url: string;
    fileName?: string;
  };
}

export enum AnimationTrigger {
  onScroll = "onScroll",
  onScrollIntoView = "onScrollIntoView"
}

export interface CmsCardPanelCard {
  __typename: string;
  id: string;
  title?: string;
  number?: string;
  subtitle?: string;
  iconImage?: CmsImage;
  bodyCopy?: string;
  backgroundColour?: Colour;
  fullBleedImage?: boolean;
  url?: string;
  urlDisplay?: string;
  image?: CmsResponsiveImage;
  imageFit?: ImageFit;
  hideImageOnMobile?: boolean;
  lottieFile?: CmsAsset;
  inlineVideo?: CmsAsset;
  video?: CmsAsset;
  videoPoster?: CmsAsset;
  mobileVideo?: CmsAsset;
  mobileVideoPoster?: CmsAsset;
  loopAnimation?: boolean;
  animationTrigger?: AnimationTrigger;
  fullScreenTakeover?: CmsCardPanelFullScreenTakeover;
  cardTakeover?: CmsCardPanelCardTakeover;
  backgroundImage?: CmsResponsiveImage;
  padCopy?: boolean;
  increasedPadding?: boolean;
  copyPosition?: CardCopyPosition;
  richText?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  showOpenAccountButton?: boolean;
  openAccountButtonText?: string;
  primaryCtaText?: string;
  primaryCtaUrl?: string;
  richTextListMarkerStyle?: string;
  reduceImageSize?: boolean;
  reverseOrder?: boolean;
}

export interface CardProps extends CmsCardPanelCard {
  rowLength?: number;
  inCarousel?: boolean;
  index: number;
  animateCardPosition?: boolean;
  cardPanel?: boolean;
}

export interface CmsCardPanelRow {
  id: string;
  cards: CmsCardPanelCard[];
}

export interface CmsCardPanelSection extends CmsPageSectionBase {
  title?: string;
  subtitle?: string;
  backgroundColour?: string;
  backgroundImage?: CmsResponsiveImage;
  cardRows: CmsCardPanelRow[];
  animateCardPosition?: boolean;
  footerText?: string;
  ctaText?: string;
  ctaUrl?: string;
  showOpenAccountButton?: boolean;
  increasedPadding?: boolean;
}

export interface CmsPageSectionBase {
  __typename: string;
  contentful_id?: string;
  sectionPaddingTop?: string;
  sectionPaddingBottom?: string;
  parallax?: boolean;
  isPageSection?: boolean;
}

enum CompatibleCardItemType {
  ContentfulCardPanelCard = "ContentfulCardPanelCard",
  ContentfulQuoteCard = "ContentfulQuoteCard"
}

const CardTypeMap: Record<
  CompatibleCardItemType,
  React.FC<CmsQuoteCard | CardProps>
> = {
  [CompatibleCardItemType.ContentfulCardPanelCard]: Card,
  [CompatibleCardItemType.ContentfulQuoteCard]: QuoteCard
};

export const CardPanel: FC<CmsCardPanelSection> = ({
  title,
  subtitle,
  backgroundColour = "teal20",
  backgroundImage,
  cardRows,
  animateCardPosition,
  footerText,
  ctaText,
  ctaUrl,
  sectionPaddingTop,
  sectionPaddingBottom,
  showOpenAccountButton,
  increasedPadding
}) => {
  const multiCardRow = (row: CmsCardPanelRow): Boolean => row.cards.length > 1;
  const isBlog = useIsBlogPage();
  const isPrivacy = useIsPrivacyPage();

  return (
    <Panel
      className={cx(styles.cardPanelSection)}
      layout={PanelLayout.full}
      colour={Colour[backgroundColour]}
      short
    >
      <PanelItem
        className={cx(styles.cardPanel, {
          [styles.blogItem]: isBlog,
          [styles.privacyItem]: isPrivacy
        })}
      >
        <div
          className={cx(
            styles.content,
            styles[sectionPaddingTop || SectionPaddingDefault.TOP],
            styles[sectionPaddingBottom || SectionPaddingDefault.BOTTOM]
          )}
        >
          <div
            className={cx(styles.container, {
              [styles.increasedPadding]: increasedPadding
            })}
          >
            <PanelTitles
              title={title}
              subtitle={subtitle}
              ctaText={ctaText}
              ctaUrl={ctaUrl}
              showOpenAccountButton={showOpenAccountButton}
            />
            {cardRows.map((row: CmsCardPanelRow) => {
              return (
                <div
                  key={row.id}
                  className={cx(styles.row, {
                    [styles.multiCardRow]: multiCardRow(row)
                  })}
                >
                  {row.cards.map((card, index) => {
                    const Component = CardTypeMap[card.__typename];

                    return Component ? (
                      <div key={card.id} className={styles.cardContainer}>
                        <Component
                          animateCardPosition={animateCardPosition}
                          {...card}
                          cardPanel={true}
                          rowLength={row.cards.length}
                          index={index}
                        />
                      </div>
                    ) : null;
                  })}
                </div>
              );
            })}
            {footerText && <h5 className={styles.footerText}>{footerText}</h5>}
          </div>
        </div>
      </PanelItem>
      {backgroundImage && (
        <div className={styles.backgroundImage}>
          <GatsbyImage
            alt=""
            imgStyle={{
              objectFit: ImageFit.COVER
            }}
            image={
              getImage(backgroundImage.gatsbyImageData) as IGatsbyImageData
            }
          />
        </div>
      )}
    </Panel>
  );
};

export const ContentfulCardPanelFragment = graphql`
  fragment ContentfulCardPanelFragment on ContentfulCardPanel {
    __typename
    id
    contentful_id
    title
    subtitle
    backgroundColour
    animateCardPosition
    backgroundImage {
      gatsbyImageData(placeholder: BLURRED)
      title
    }
    ctaText
    ctaUrl
    footerText
    showOpenAccountButton
    sectionPaddingTop
    sectionPaddingBottom
    increasedPadding
    cardRows {
      id
      cards {
        ...ContentfulCardPanelCardFragment
        ...ContentfulQuoteCardFragment
      }
    }
  }
`;

export const ContentfulCardPanelCardFragment = graphql`
  fragment ContentfulCardPanelCardFragment on ContentfulCardPanelCard {
    __typename
    backgroundColour
    id
    subtitle
    iconImage {
      title
      description
      file {
        url
      }
    }
    bodyCopy
    showOpenAccountButton
    openAccountButtonText
    primaryCtaText
    primaryCtaUrl
    title
    number
    url
    urlDisplay
    hideImageOnMobile
    richText {
      raw
    }
    richTextListMarkerStyle
    image {
      gatsbyImageData(placeholder: BLURRED)
      title
    }
    imageFit
    lottieFile {
      file {
        url
      }
    }
    inlineVideo {
      file {
        url
      }
    }
    video {
      file {
        url
        fileName
      }
    }
    videoPoster {
      file {
        url
      }
    }
    mobileVideo {
      file {
        url
        fileName
      }
    }
    mobileVideoPoster {
      file {
        url
      }
    }
    loopAnimation
    animationTrigger
    backgroundImage {
      gatsbyImageData(placeholder: BLURRED)
      title
    }
    padCopy
    increasedPadding
    copyPosition
    reduceImageSize
    reverseOrder
    fullScreenTakeover {
      id
      title
      listItems
      listTitle
      description {
        description
      }
    }
    cardTakeover {
      title
      subtitle
      bodyCopy
      richText {
        raw
      }
      backgroundColour
      image {
        gatsbyImageData(placeholder: BLURRED)
        title
      }
      imageFit
    }
  }
`;

CardPanel.displayName = "Card Panel";
