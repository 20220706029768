import React, { FC } from "react";
import styles from "./styles.module.scss";
import cx from "classnames";
import { Templates, TrustBox } from "../HomePagePanel/TrustPilot";

export interface TrustPilotRatingCardProps {
  backgroundColour: string;
}

export const TrustPilotRatingCard: FC<TrustPilotRatingCardProps> = ({
  backgroundColour
}) => {
  return (
    <div className={cx(styles.trustPilotRatingCard, styles[backgroundColour])}>
      <TrustBox template={Templates.Mini} />
    </div>
  );
};
