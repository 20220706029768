import { useStaticQuery, graphql } from "gatsby";

export interface MenuSubLink {
  id: string;
  title: string;
  url: string;
  isNew: boolean;
}

export interface MenuLink {
  id: string;
  title: string;
  children: MenuSubLink[];
  nestedSections?: CmsLinkSection[];
  subtitle?: string;
}

interface CmsLinkSection {
  id: string;
  title: string;
  headerLinks: MenuSubLink[];
  headerLinkSections?: CmsLinkSection[];
  children?: MenuSubLink[];
  subtitle?: string;
}

export const useMenuLinks = (): MenuLink[] => {
  const data = useStaticQuery(graphql`
    query SiteHeaderLinks {
      contentfulSiteHeader {
        headerLinkSections {
          id
          title
          subtitle
          headerLinks {
            id
            url
            title
            isNew
          }
          headerLinkSections {
            id
            title
            subtitle
            headerLinks {
              id
              url
              title
              isNew
            }
          }
        }
      }
    }
  `);

  const mappedChildren = (section: CmsLinkSection) =>
    section.headerLinks.map((link: MenuSubLink) => ({
      id: link.id,
      title: link.title,
      url: link.url,
      isNew: link.isNew
    }));

  return data.contentfulSiteHeader.headerLinkSections.map(
    (section: CmsLinkSection) => ({
      id: section.id,
      title: section.title,
      subtitle: section.subtitle,
      children: mappedChildren(section),
      nestedSections: section.headerLinkSections?.map(
        (section: CmsLinkSection) => ({
          id: section.id,
          title: section.title,
          subtitle: section.subtitle,
          children: mappedChildren(section)
        })
      )
    })
  );
};
