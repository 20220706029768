import React, { FC } from "react";
import { graphql } from "gatsby";
import { LostCardPageLayout } from "components/LostCard/LostCardPageLayout";
import { LostCardPageProps } from "..";

export const CardLockedPage: FC<LostCardPageProps> = ({ data: { page } }) => (
  <LostCardPageLayout page={page} title="We've locked your card" />
);

export const PageQuery = graphql`
  query LostCardCardLockedPageQuery {
    page: contentfulLostCardPage(lostCardPageId: { eq: "cardLocked" }) {
      darkTheme
      title
      image {
        gatsbyImageData(width: 2560, placeholder: BLURRED)
      }
      mainContent {
        raw
      }
      helpText {
        raw
      }
    }
  }
`;

export default CardLockedPage;
