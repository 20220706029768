import React, { FC } from "react";
import { Panel, PanelLayout, PanelItem } from "components/Panel";
import { Colour } from "components/constants";
import { natwest } from "assets/logos";
import styles from "./styles.module.scss";

export const NatwestPanel: FC = () => (
  <Panel
    colour={Colour.white}
    className={styles.natwestPanel}
    layout={PanelLayout.full}
    short
  >
    <PanelItem className={styles.byNatwest}>
      <img src={natwest} alt="NatWest" />
    </PanelItem>
  </Panel>
);
