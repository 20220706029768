import { useEffect, MutableRefObject, useRef } from "react";
import { useRootElement } from "./useRootElement";

/**
 * On mount, sets `aria-hidden="true"` on the root element of the page,
 * such that it will not be visible to screen readers. On unmount,
 * the previous state of the element will be restored.
 */
export const useDisableRootAccessibilityTree = () => {
  const root = useRootElement();
  const prevHidden: MutableRefObject<string | null> = useRef(null);
  useEffect(() => {
    if (!root) return;
    prevHidden.current = root.getAttribute("aria-hidden");
    root.setAttribute("aria-hidden", "true");
    return () => {
      root.setAttribute("aria-hidden", prevHidden.current || "");
      prevHidden.current = null;
    };
  }, [root]);
};
