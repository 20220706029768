import React, { FC } from "react";
import { Colour } from "../constants";
import styles from "../SiteFooter/styles.module.scss";
import { Panel, PanelItem, PanelLayout } from "components/Panel";
import { useStaticQuery, graphql } from "gatsby";
import { useRichText } from "../../hooks/useRichText";
import { FooterSocialLink, SocialLinks } from "./SocialLinks";
import { FooterLinkSectionProps, LinkSection } from "./LinkSection";

export const SiteFooter: FC = () => {
  const data = useStaticQuery(graphql`
    query footerQuery {
      contentfulSiteFooter {
        copyrightOwner
        footerLinkSections {
          id
          title
          isPrivacy
          footerLinks {
            id
            url
            name
          }
        }
        logo {
          title
          file {
            url
          }
        }
        legalDisclaimer {
          raw
        }
        signOffText
        footerSocialLinks {
          id
          displayName
          platformName
          url
        }
      }
    }
  `);

  return (
    <footer>
      <Panel
        data-testid="footer"
        id="siteFooter"
        colour={Colour.black}
        className={styles.siteFooterPanel}
        short
        layout={PanelLayout.full}
      >
        <PanelItem>
          <div className={styles.signOffText}>
            {data.contentfulSiteFooter.signOffText}
          </div>
          <div className={styles.footerFlexContainer}>
            <div className={styles.socialContainer}>
              <SocialLinks
                links={
                  data.contentfulSiteFooter
                    .footerSocialLinks as FooterSocialLink[]
                }
              />
              <img
                className={styles.siteLogo}
                src={data.contentfulSiteFooter.logo.file.url}
                alt={data.contentfulSiteFooter.logo.title}
              />
            </div>

            <div className={styles.footerLinksContainer}>
              {data.contentfulSiteFooter.footerLinkSections.map(
                (section: FooterLinkSectionProps, index: number) => (
                  <LinkSection key={index} {...section} />
                )
              )}
            </div>
          </div>
          <div className={styles.legal}>
            <>{useRichText(data.contentfulSiteFooter.legalDisclaimer)}</>
          </div>
          <div className={styles.copyright}>
            Copyright {new Date().getFullYear()} &copy;{" "}
            {data.contentfulSiteFooter.copyrightOwner}
          </div>
        </PanelItem>
      </Panel>
    </footer>
  );
};
