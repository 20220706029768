import React from "react";
import cx from "classnames";
import styles from "./styles.module.scss";
import { Link } from "components/Link";
import { PRIVACY_NOTICE_URL } from "../../flows/desktop/CommonForm";
import { AppStoreButtons } from "../../../AppStoreButtons";
import { getQuickLinks, QuickLinkEnum } from "../../../../hooks";

export const Supports: React.FC = () => {
  const quickLinks = getQuickLinks();
  const { prodLink: iosProdLink, testLink: iosTestLink } =
    quickLinks[QuickLinkEnum.GET_METTLE_DESKTOP_IOS];
  const { prodLink: androidProdLink, testLink: androidTestLink } =
    quickLinks[QuickLinkEnum.GET_METTLE_DESKTOP_ANDROID];

  return (
    <div className={styles.supports}>
      <div className={styles.supportsContainer}>
        <div className={styles.supported}>
          <div className={styles.header}>Mettle currently supports</div>
          <ul className={cx(styles.supportedList, styles.includes)}>
            <li>UK-based companies with owners that are UK residents</li>
            <li>Sole traders and limited companies (with up to two owners)</li>
            <li>Balances up to £1 million</li>
            <li>iOS and Android devices</li>
          </ul>
        </div>
        <div className={styles.unsupported}>
          <div>
            <div className={styles.header}>Loans are not supported</div>
            <ul className={cx(styles.unsupportedList, styles.excludes)}>
              <li>No access to government-backed loans or any lending</li>
            </ul>
          </div>

          <div className={styles.privacyNotice}>
            To see how we process personal data view our{" "}
            <Link newTab url={PRIVACY_NOTICE_URL}>
              privacy notice.
            </Link>
          </div>

          <AppStoreButtons
            testAndroidQuickLink={androidTestLink}
            testIosQuickLink={iosTestLink}
            androidQuickLink={androidProdLink}
            iosQuickLink={iosProdLink}
          />
        </div>
      </div>
    </div>
  );
};

Supports.displayName = "Supports";
