import React, { FC } from "react";
import { Colour } from "components/constants";
import { Panel, PanelItem, PanelLayout } from "components/Panel";
import { Link } from "components/Link";
import { facebookLogo, linkedInLogo, twitterLogo } from "assets/social";
import styles from "./styles.module.scss";

export type SocialLink = [string, string, string];

const socialLinks: SocialLink[] = [
  ["Facebook", facebookLogo, "https://www.facebook.com/joinmettle/"],
  ["LinkedIn", linkedInLogo, "https://www.linkedin.com/company/join-mettle/"],
  ["Twitter", twitterLogo, "https://twitter.com/joinmettle"]
];

export const SocialLinksPanel: FC = () => (
  <Panel
    id="socialLinks"
    colour={Colour.black}
    className={styles.footerLinksPanel}
    short
    layout={PanelLayout.full}
  >
    <PanelItem className={styles.panelContent}>
      <ul className={styles.socialLinks}>
        {socialLinks.map(([title, image, url]) => (
          <li key={title}>
            <Link newTab url={url} title={title}>
              <img src={image} alt={title} />
            </Link>
          </li>
        ))}
      </ul>
    </PanelItem>
  </Panel>
);
