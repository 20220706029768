import React, { FC } from "react";
import styles from "./styles.module.scss";
import { Button, ButtonVariants } from "../../Button";
import cx from "classnames";
import { CalculatorInput } from "../CalculatorInput";
import { useCalculatorState } from "../../../hooks/useCalculatorState";

export const CompoundInterestCalculator: FC = () => {
  const {
    handleSavingInputChange,
    handleInterestInputChange,
    calculate,
    savingGoal,
    MAX_GOAL,
    MAX_RATE,
    rate,
    valid,
    compoundYearly
  } = useCalculatorState();

  return (
    <div className={styles.calculator}>
      <div className={styles.inputs}>
        <CalculatorInput
          changeHandler={handleSavingInputChange}
          name="saving_goal"
          label="Saving goal"
          type="number"
          invalidMessage="Invalid number"
          unit="currency"
          max={MAX_GOAL}
          helpText="What is the amount of your initial deposit?"
          value={savingGoal}
          placeholder="0"
        />

        <CalculatorInput
          changeHandler={handleInterestInputChange}
          name="interest_rate"
          type="number"
          label="Interest rate"
          invalidMessage="Invalid number"
          max={MAX_RATE}
          unit="percentage"
          helpText="The interest rate on the intial amount compounded monthly."
          value={rate}
          placeholder="0"
        />
      </div>

      <div>
        <Button
          className={styles.button}
          onClick={calculate}
          variant={ButtonVariants.PRIMARY}
          disabled={!valid}
        >
          Calculate
        </Button>
      </div>

      <div className={styles.results}>
        <p className={styles.title}>Interest earned</p>
        <div className={styles.outputs}>
          <div>
            Yearly
            <span
              data-testid="yearly"
              className={cx(styles.result, {
                [styles.nonZero]: Number(compoundYearly) !== 0
              })}
            >
              £{compoundYearly}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

CompoundInterestCalculator.displayName = "CompoundInterestCalculator";
