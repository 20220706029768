import React, { FC } from "react";
import { Colour, SectionPaddingDefault } from "../constants";
import { graphql } from "gatsby";
import { Panel, PanelItem, PanelLayout } from "components/Panel";
import styles from "./styles.module.scss";
import cx from "classnames";
import { CmsPageSectionBase } from "components/CardPanel";
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData
} from "gatsby-source-contentful/rich-text";
import { useRichText } from "../../hooks/useRichText";
import { useIsDarkBackground } from "../../hooks/useBackgroundColourUtils";

export interface CmsDisclaimerSection extends CmsPageSectionBase {
  backgroundColour: Colour;
  copy?: { copy: string };
  copyRichText?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
}

export const DisclaimerSection: FC<CmsDisclaimerSection> = ({
  backgroundColour,
  copy,
  copyRichText,
  sectionPaddingTop,
  sectionPaddingBottom
}) => {
  return (
    <Panel layout={PanelLayout.full} colour={Colour[backgroundColour]} short>
      <PanelItem
        className={cx(
          styles.disclaimerSection,
          styles[sectionPaddingTop || SectionPaddingDefault.TOP],
          styles[sectionPaddingBottom || SectionPaddingDefault.BOTTOM],
          {
            [styles.isDarkBackground]: useIsDarkBackground(backgroundColour)
          }
        )}
      >
        {copy && <span className={styles.copy}>{copy.copy}</span>}
        {copyRichText && (
          <span className={styles.copy}>{useRichText(copyRichText)}</span>
        )}
      </PanelItem>
    </Panel>
  );
};

DisclaimerSection.displayName = "DisclaimerSection";

export const ContentfulDisclaimerSectionFragment = graphql`
  fragment ContentfulDisclaimerSectionFragment on ContentfulDisclaimerSection {
    __typename
    contentful_id
    backgroundColour
    sectionPaddingTop
    sectionPaddingBottom
    copy {
      copy
    }
    copyRichText {
      raw
    }
  }
`;
