import React from "react";
import { TocLink } from "./TocLink";
import { InlineDropDownNav } from "components/InlineDropDownNav";
import allIcon from "assets/icons/All.svg";

interface ToCDropdownProps {
  headings: string[];
}

export const TocDropdown: React.FC<ToCDropdownProps> = ({ headings }) => {
  return (
    <InlineDropDownNav
      closedText="Content"
      openText="Content"
      options={headings.map(heading => (
        <TocLink key={heading} heading={heading} active={false} />
      ))}
      icon={allIcon}
    />
  );
};
