import { CmsSectionColour } from "components/FeaturesPage";
import { graphql } from "gatsby";
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData
} from "gatsby-source-contentful/rich-text";

export interface EmailFormConfig {
  backgroundColour: CmsSectionColour;
  tags: string[];

  header: string;
  subHeader: string;
  policyCheckbox: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  buttonText: string;
  noEmailValidationError: string;
  emailValidationError: string;
  policyCheckboxValidationError: string;
  serverError: string;

  confirmationHeader: string;
  confirmationSubHeader: string;
  confirmationDisclaimer: string;
  confirmationAnotherMail: string;
}

export interface CmsCaptureEmailSection extends EmailFormConfig {
  __typename: "CmsCaptureEmailSection";
  title: string;
}

export const getCmsCaptureEmailSection = (): CmsCaptureEmailSection => ({
  __typename: "CmsCaptureEmailSection",
  title: "CmsCaptureEmailSectionTitle",
  ...getCaptureLeadFormConfig()
});

export const CaptureEmailFragment = graphql`
  fragment CaptureEmailFragment on ContentfulCaptureEmail {
    title
    backgroundColour
    tags

    header
    subHeader
    policyCheckbox {
      raw
    }
    buttonText
    noEmailValidationError
    emailValidationError
    policyCheckboxValidationError
    serverError

    confirmationHeader
    confirmationSubHeader
    confirmationDisclaimer
    confirmationAnotherMail
  }
`;

export const getCaptureLeadFormConfig = (): EmailFormConfig => ({
  backgroundColour: CmsSectionColour.BLACK,
  tags: ["CaptureLead"],

  header: "Looking to start a business?",
  subHeader:
    "Get the Mettle guide to starting a business straight to your inbox",
  policyCheckbox: { raw: "", references: [] },
  buttonText: "Submit",

  noEmailValidationError: "Please enter your email address.",
  emailValidationError: "That email doesn't look right. Please try again.",
  policyCheckboxValidationError: "Confirmation needed.",
  serverError: "Something went wrong. Refresh the page and try again.",

  confirmationHeader: "Keep an eye on your inbox",
  confirmationSubHeader: "We’ve sent an email to",
  confirmationDisclaimer: "If you didn’t get an email, check your spam folder",
  confirmationAnotherMail: "Send to a different email"
});

export const getOpenAccountEmailFormConfig = (): EmailFormConfig => ({
  backgroundColour: CmsSectionColour.WHITE,
  tags: ["OpenAccountEmail"],

  header: "Apply in minutes from the app",
  subHeader:
    "Enter your email and we'll send you a link to get the app on your phone",
  buttonText: "Send email",
  policyCheckbox: { raw: "", references: [] },

  noEmailValidationError: "Please enter your email address.",
  emailValidationError: "That email doesn't look right. Please try again.",
  policyCheckboxValidationError: "Confirmation needed.",
  serverError: "Something went wrong. Refresh the page and try again.",

  confirmationHeader: "Keep an eye on your inbox",
  confirmationSubHeader: "We’ve sent an email to",
  confirmationDisclaimer: "If you didn’t get an email, check your spam folder",
  confirmationAnotherMail: "Send to a different email"
});
