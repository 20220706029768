import React, { FC, useState } from "react";
import { Panel, PanelItem, PanelLayout } from "components/Panel";
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData
} from "gatsby-source-contentful/rich-text";
import styles from "./styles.module.scss";
import { Colour } from "components/constants";
import { DefaultLayout } from "layouts";
import { useRichText } from "hooks/useRichText";
import cx from "classnames";
import LostCardForm from "../LostCardForm";
import {
  ConfirmationRenderingType,
  SentConfirmation
} from "components/OpenAccount/flows/desktop/SentConfirmation";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";

export interface LostCardPage {
  darkTheme: boolean;
  image: IGatsbyImageData;
  title: string;
  errorText?: string;
  mainContent: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  helpText?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  formCheckboxText?: string;
  confirmationTextTitle?: string;
  confirmationTextBody?: string;
  confirmationTextFooter?: string;
}

interface LostCardPageLayoutProps {
  page: LostCardPage;
  title: string;
  includeForm?: boolean;
}

const BaseContent: FC<{ page: LostCardPage; children?: React.ReactNode }> = ({
  page,
  children
}) => {
  return (
    <Panel
      colour={Colour.warmStone}
      layout={PanelLayout.full}
      className={styles.baseContent}
    >
      <PanelItem>
        <div className={styles.content}>
          <div className={cx(styles.container, styles.warmStone)}>
            <div
              className={cx(styles.lostCardCopy, {
                [styles.darkText]: !page.darkTheme
              })}
            >
              <h2>{page.title}</h2>
              {page.errorText && (
                <p className={styles.errorText}>{page.errorText}</p>
              )}

              {useRichText(page.mainContent)}

              {children}

              {page.helpText && (
                <div className={styles.helpText}>
                  {useRichText(page.helpText)}
                </div>
              )}
            </div>
          </div>
        </div>
      </PanelItem>

      <GatsbyImage
        className={styles.backgroundImage}
        alt="BackgroundImage"
        image={getImage(page.image) as IGatsbyImageData}
      />
    </Panel>
  );
};

const ContentWithForm: FC<{
  page: LostCardPage;
  colour: Colour;
  onSubmitted: () => void;
}> = ({ page, onSubmitted }) => {
  return (
    <BaseContent page={page}>
      <div className={styles.formContent}>
        <LostCardForm page={page} onSubmitted={onSubmitted} />
      </div>
    </BaseContent>
  );
};

export const LostCardPageLayout: FC<LostCardPageLayoutProps> = ({
  page,
  title,
  includeForm = false
}) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const colour = page.darkTheme ? Colour.black : Colour.white;

  return (
    <DefaultLayout title={title} background={colour}>
      {formSubmitted ? (
        <div className={styles.sentConfirmation}>
          <SentConfirmation
            type={ConfirmationRenderingType.PAGE}
            hasImage
            labels={{
              title: page.confirmationTextTitle!,
              main: page.confirmationTextBody,
              bottomNote: page.confirmationTextFooter!
            }}
            darkText
          />
        </div>
      ) : includeForm ? (
        <ContentWithForm
          page={page}
          colour={colour}
          onSubmitted={() => setFormSubmitted(true)}
        />
      ) : (
        <BaseContent page={page} />
      )}
    </DefaultLayout>
  );
};
