export interface DeepLinkData {
  title: string;
  alternatively: string;
  desktopMessage: string;
  mobileMessage: string;
  button: string;
  steps: string[];
}

export const Data: Record<string, Partial<DeepLinkData>> = {
  default: {
    title: "This is a mobile link",
    alternatively: "Alternatively, connect through the app",
    desktopMessage:
      "Scan this QR code on your mobile phone or tap the email link from your mobile",
    mobileMessage:
      "The link you used is meant to be opened in the Mettle app. Tap the button below on the phone you use Mettle on",
    button: "Open the Mettle App"
  },

  xero: {
    title: "To connect your Xero account scan this QR code",
    steps: [
      "On your Account screen, tap Connections",
      "Select Xero",
      "Agree to sharing your data",
      "Select the date you'd like your transactions to show – you can share up to 12 months of Mettle transactions",
      "You'll be directed to Xero where you'll need to login with your Xero credentials"
    ]
  },

  freeagent: {
    title: "To connect your FreeAgent account scan this QR code",
    desktopMessage:
      "Scan this QR code on your mobile phone and select 'I already have a FreeAgent account' to confirm the access requested by FreeAgent'"
  },

  "monthly-statements": {
    title: "To access your monthly statements please scan this QR code",
    alternatively: "Alternatively, you can view your statements in the app",
    steps: [
      "Go to your Account screen",
      "Tap 'Transactions and statements'",
      "Select the month for which you want to download the statement"
    ]
  }
};
