import React, { FC } from "react";
import { graphql } from "gatsby";
import {
  LostCardPage,
  LostCardPageLayout
} from "components/LostCard/LostCardPageLayout";

export interface LostCardPageProps {
  data: {
    page: LostCardPage;
  };
}

export const LostCardLinkExpiredPage: FC<LostCardPageProps> = ({
  data: { page }
}) => (
  <LostCardPageLayout title="Card lock link expired" page={page} includeForm />
);

export const PageQuery = graphql`
  query LostCardLinkExpiredPageQuery {
    page: contentfulLostCardPage(lostCardPageId: { eq: "linkExpired" }) {
      darkTheme
      image {
        gatsbyImageData(width: 2560, placeholder: BLURRED)
      }
      title
      mainContent {
        raw
      }
      helpText {
        raw
      }
      formCheckboxText
      errorText
      confirmationTextTitle
      confirmationTextBody
      confirmationTextFooter
    }
  }
`;

export default LostCardLinkExpiredPage;
