import * as Sentry from "@sentry/gatsby";
import { fetchCarefully } from "./fetchCarefully";
import { EmailFormConfig } from "components/CaptureLeadSection/emailFormConfig";

export async function apiCall<T extends {}>(
  emailFormConfig: EmailFormConfig,
  endpoint: string,
  options: RequestInit = {}
): Promise<T> {
  const response = await fetchCarefully(`/intercom${endpoint}`, {
    ...options,
    headers: {
      ...options.headers,
      "content-type": "application/json"
    }
  });

  if (!response.status) {
    throw new Error(emailFormConfig.serverError);
  }
  if (!response.ok) {
    throw new Error(emailFormConfig.serverError);
  }

  const text = await response.text();
  try {
    return text.length ? JSON.parse(text) : {};
  } catch (error) {
    Sentry.captureException(error);
    throw new Error(emailFormConfig.serverError);
  }
}

export const MAX_FIELD_LENGTH = 255;
