import React, {
  FC,
  TouchEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { Panel, PanelItem, PanelLayout } from "../Panel";
import styles from "./styles.module.scss";
import { BodySize, Colour, SectionPaddingDefault } from "../constants";
import { VelocityComponent } from "velocity-react";
import cx from "classnames";
import { Link } from "../Link";
import { CmsQuoteCard, QuoteCard } from "components/QuoteCard";
import {
  CmsSocialProofingCard,
  SocialProofingCard
} from "../SocialProofingCard";
import { IconographyButton } from "../IconographyButton";
import arrowRight from "assets/icons/arrow-right-white.svg";
import arrowLeft from "assets/icons/arrow-left-white.svg";
import { BlogPostCard, BlogPostCardProps } from "../Blog";
import { graphql } from "gatsby";
import { CmsCardPanelCard, CmsPageSectionBase } from "../CardPanel";
import { Card } from "components/Card";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";

export interface CmsPageCarouselSection extends CmsPageSectionBase {
  title?: string;
  subtitle?: string;
  linkText?: string;
  linkUrl?: string;
  centerTitles?: boolean;
  backgroundColour: Colour;
  displayItems: number;
  showControls: boolean;
  autoScroll: boolean;
  autoScrollInterval?: number;
  items: CarouselItemType[];
}

export type CarouselItemType =
  | CmsQuoteCard
  | CmsSocialProofingCard
  | BlogPostCardProps
  | CmsCardPanelCard;

export enum CompatibleCarouselItemsType {
  QuoteCard = "ContentfulQuoteCard",
  SocialProofing = "ContentfulSocialProofingCard",
  BlogPost = "ContentfulBlogPost",
  Card = "ContentfulCardPanelCard"
}

export const CarouselItemTypeMap: Record<
  CompatibleCarouselItemsType,
  React.FC<CarouselItemType>
> = {
  [CompatibleCarouselItemsType.QuoteCard]: QuoteCard,
  [CompatibleCarouselItemsType.SocialProofing]: SocialProofingCard,
  [CompatibleCarouselItemsType.BlogPost]: BlogPostCard,
  [CompatibleCarouselItemsType.Card]: Card
};

interface CarouselState {
  carouselPosition: number;
  paneCount: number;
  itemsPerPane: number;
  autoScrollDisabled: boolean;
  forwardButtonDisabled: boolean;
  backwardButtonDisabled: boolean;
  flexStyle: string;
  width: number;
}

export const PageCarousel: FC<CmsPageCarouselSection> = ({
  title,
  subtitle,
  linkText,
  linkUrl,
  centerTitles,
  backgroundColour,
  items,
  displayItems,
  showControls,
  autoScroll,
  autoScrollInterval = 1000,
  sectionPaddingTop,
  sectionPaddingBottom
}) => {
  const [
    {
      carouselPosition,
      paneCount,
      itemsPerPane,
      autoScrollDisabled,
      forwardButtonDisabled,
      backwardButtonDisabled,
      flexStyle,
      width
    },
    setCarouselState
  ] = useState<CarouselState>({
    carouselPosition: 0,
    paneCount: items.length,
    itemsPerPane: displayItems,
    autoScrollDisabled: !autoScroll,
    forwardButtonDisabled: false,
    backwardButtonDisabled: true,
    flexStyle: "",
    width: 0
  });

  const carouselRef = useRef(null);
  const [touchStartX, setTouchStartX] = useState(0);
  const isInView = useIntersectionObserver(carouselRef, true, 0.9, true);

  const touchStart = useCallback((evt: TouchEvent<HTMLDivElement>) => {
    const touchStartClientX = evt.changedTouches[0]?.clientX;
    if (touchStartClientX) {
      setTouchStartX(touchStartClientX);
    }
  }, []);

  const touchEnd = useCallback(
    (evt: TouchEvent<HTMLDivElement>) => {
      const touchEndClientX = evt.changedTouches[0]?.clientX;
      if (touchEndClientX !== undefined) {
        const deviation = 30; // Detecting actual left or right swipe
        if (touchEndClientX >= touchStartX + deviation) {
          handleCarouselBackward();
        }
        if (touchEndClientX <= touchStartX - deviation) {
          handleCarouselForward();
        }
      }
    },
    [touchStartX]
  );

  const willTransitionToLastPane = (): boolean => {
    return items.length - carouselPosition * itemsPerPane < itemsPerPane;
  };
  const calculateMarginLeft = () => {
    if (carouselPosition) {
      if (willTransitionToLastPane()) {
        return `-${
          carouselPosition * 100 -
          (100 - ((items.length % itemsPerPane) / itemsPerPane) * 100)
        }%`;
      } else {
        return `-${carouselPosition}00%`;
      }
    }

    return 0;
  };

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (autoScroll && !autoScrollDisabled && isInView) {
      timeout = setTimeout(() => {
        if (carouselPosition < paneCount - 1) {
          setCarouselState(prevState => {
            return {
              ...prevState,
              carouselPosition: prevState.carouselPosition + 1,
              forwardButtonDisabled: carouselPosition + 1 === paneCount - 1,
              backwardButtonDisabled: false
            };
          });
        }
      }, autoScrollInterval);
    }

    return () => clearTimeout(timeout);
  }, [carouselPosition, autoScrollDisabled, autoScroll, paneCount, isInView]);

  const handleResize = () => {
    const newWidth = window.innerWidth;
    const resetState = {
      carouselPosition: 0,
      forwardButtonDisabled: false,
      backwardButtonDisabled: true,
      width: newWidth,
      autoScrollDisabled: false
    };

    if (Math.abs(width - newWidth) > 30) {
      if (newWidth > BodySize.maxTablet) {
        setCarouselState(prevState => {
          return {
            ...prevState,
            ...resetState,
            paneCount: Math.ceil(items.length / displayItems),
            itemsPerPane: displayItems,
            flexStyle: styles[`itemFlex${displayItems}`]
          };
        });
      } else if (
        newWidth >= BodySize.maxMobile &&
        newWidth <= BodySize.maxTablet
      ) {
        setCarouselState(prevState => {
          return {
            ...prevState,
            ...resetState,
            paneCount: Math.ceil(items.length / Math.min(2, displayItems)),
            itemsPerPane: Math.min(2, displayItems),
            flexStyle: styles[`itemFlex${Math.min(2, displayItems)}`]
          };
        });
      } else {
        setCarouselState(prevState => {
          return {
            ...prevState,
            ...resetState,
            paneCount: items.length,
            itemsPerPane: 1,
            flexStyle: ""
          };
        });
      }
    } else {
      setCarouselState(prevState => {
        return {
          ...prevState,
          width: newWidth
        };
      });
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", handleResize);
    }
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      handleResize();
    }
  }, []);

  const handleCarouselForward = () => {
    if (carouselPosition < paneCount - 1) {
      setCarouselState(prevState => {
        return {
          ...prevState,
          carouselPosition: carouselPosition + 1,
          autoScrollDisabled: true,
          forwardButtonDisabled: carouselPosition + 1 === paneCount - 1,
          backwardButtonDisabled: false
        };
      });
    }
  };

  const handleCarouselBackward = () => {
    if (carouselPosition > 0) {
      setCarouselState(prevState => {
        return {
          ...prevState,
          carouselPosition: carouselPosition - 1,
          autoScrollDisabled: true,
          forwardButtonDisabled: false,
          backwardButtonDisabled: carouselPosition - 1 === 0
        };
      });
    }
  };

  const isActivePane = (index: number) => index === carouselPosition;

  return (
    <Panel
      data-testid="Carousel"
      layout={PanelLayout.full}
      colour={Colour[backgroundColour]}
      className={cx(
        styles.carouselPanel,
        styles[sectionPaddingTop || SectionPaddingDefault.TOP],
        styles[sectionPaddingBottom || SectionPaddingDefault.BOTTOM]
      )}
      short
    >
      <PanelItem className={styles.carousel}>
        <div
          className={cx(styles.context, {
            [styles.centerTitles]: Boolean(centerTitles)
          })}
        >
          {title && <h4 className={styles.title}>{title}</h4>}
          {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
          {linkText && linkUrl && (
            <Link className={styles.link} url={linkUrl}>
              {linkText}
            </Link>
          )}
        </div>
        <div className={styles.carouselContainer} ref={carouselRef}>
          <VelocityComponent
            animation={{ marginLeft: calculateMarginLeft() }}
            duration={1000}
            timeout={1000}
          >
            <div
              className={styles.inner}
              onTouchStart={touchStart}
              onTouchMove={touchEnd}
            >
              {items.map(item => {
                const Component = CarouselItemTypeMap[item.__typename];

                return Component ? (
                  <div key={item.id} className={cx(styles.item, flexStyle)}>
                    <Component {...item} inCarousel />
                  </div>
                ) : null;
              })}
            </div>
          </VelocityComponent>
        </div>
        <div className={styles.controlsContainer}>
          {!showControls && (
            <div className={styles.indicators}>
              {Array(paneCount)
                .fill("")
                .map((_, index) => (
                  <div
                    key={index}
                    className={cx(styles.indicator, {
                      [styles.active]: isActivePane(index)
                    })}
                  ></div>
                ))}
            </div>
          )}
          {showControls && paneCount > 1 && (
            <div className={styles.controls}>
              <IconographyButton
                value="Back"
                hollow
                icon={arrowLeft}
                handler={handleCarouselBackward}
                disabled={backwardButtonDisabled}
              />
              <IconographyButton
                value="Forward"
                icon={arrowRight}
                handler={handleCarouselForward}
                disabled={forwardButtonDisabled}
              />
            </div>
          )}
        </div>
      </PanelItem>
    </Panel>
  );
};

export const ContentfulPageCarouselFragment = graphql`
  fragment ContentfulPageCarouselFragment on ContentfulPageCarousel {
    __typename
    id
    contentful_id
    showControls
    internalTitle
    autoScroll
    autoScrollInterval
    backgroundColour
    displayItems
    linkText
    linkUrl
    title
    subtitle
    centerTitles
    sectionPaddingTop
    sectionPaddingBottom
    items {
      ...ContentfulQuoteCardFragment
      ...ContentfulCardPanelCardFragment
      ... on ContentfulSocialProofingCard {
        __typename
        id
        androidQuickLink
        appStoreRating
        appStoreRatingDescription
        appStoreType
        backgroundColour
        cardType
        iosQuickLink
        testAndroidQuickLink
        testIosQuickLink
        awardTitle
        awardUrl
        awardImage {
          file {
            url
          }
        }
      }
    }
  }
`;

PageCarousel.displayName = "PageCarousel";
