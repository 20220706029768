import styles from "./styles.module.scss";
import cx from "classnames";
import { Colour } from "components/constants";
import React, { FC, CSSProperties, RefObject } from "react";

export enum PanelLayout {
  full = "full",
  split = "split",
  fullWidth = "fullWidth"
}
export interface PanelProps {
  className?: string;
  id?: string;
  colour?: Colour;
  hasBackground?: boolean;
  short?: boolean;
  layout?: PanelLayout;
  style?: CSSProperties;
  forwardRef?: RefObject<HTMLElement>;
  itemScope?: boolean;
  itemType?: string;
  blog?: boolean;
  children?: React.ReactNode;
}

export const Panel: FC<PanelProps> = ({
  id,
  className,
  children,
  colour = Colour.black,
  hasBackground = true,
  short = false,
  layout = PanelLayout.full,
  blog = false,
  style,
  forwardRef,
  ...other
}) => {
  const sectionCls = cx(
    styles.panel,
    {
      [styles[layout]]: Boolean(layout),
      [styles.short]: short,
      [styles[colour]]: Boolean(colour),
      [styles.hasBackground]: hasBackground,
      [styles.blog]: blog
    },
    className
  );

  return (
    <section
      id={id}
      className={sectionCls}
      style={style}
      tabIndex={-1}
      ref={forwardRef}
      {...other}
    >
      {children}
    </section>
  );
};
