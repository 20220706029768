import { useWindowScrollPosition } from "@mettle/hooks";

// We need getters for Window and Document because
// these objects are not available for SSR
interface Props {
  doc?: () => Document;
  wnd?: () => Window;
}

export const useScrollPercentage = ({
  doc = () => document,
  wnd = () => window
}: Props) => {
  const scrollY = useWindowScrollPosition();
  const total = doc().body.clientHeight - wnd().innerHeight;
  return scrollY / total;
};
