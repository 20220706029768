import React, { FC } from "react";
import styles from "./styles.module.scss";
import { Link } from "../../Link";
import cx from "classnames";
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData
} from "gatsby-source-contentful/rich-text";
import { useIsBlogPage } from "hooks/useIsBlogPage";
import { CmsImage } from "components/Blog";
import { OpenAccountButton } from "components/OpenAccount/OpenAccountButton";
import { Button, ButtonVariants } from "components/Button";
import { useBlogRichText } from "../../Blog/hooks";
import { useIsPrivacyPage } from "../../../hooks/useIsPrivacyPage";
import { Colour } from "../../constants";
import { CardCopyPosition } from "../../CardPanel";

export interface CardCopyProps {
  title?: string;
  number?: string;
  subtitle?: string;
  iconImage?: CmsImage;
  padCopy?: boolean;
  increasedPadding?: boolean;
  copyPosition?: CardCopyPosition;
  bodyCopy?: string;
  urlDisplay?: string;
  url?: string;
  reducedHeaders?: boolean;
  className?: string;
  hasBackground?: boolean;
  hideImageOnMobile?: boolean;
  showOpenAccountButton?: boolean;
  openAccountButtonText?: string;
  primaryCtaText?: string;
  primaryCtaUrl?: string;
  richText?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  richTextListMarkerStyle?: string;
  backgroundColour?: Colour;
  hasImage?: boolean;
  inTakeover?: boolean;
  singleCardRow?: boolean;
  reverseOrder?: boolean;
}

export const CardCopy: FC<CardCopyProps> = ({
  title,
  number,
  bodyCopy,
  subtitle,
  urlDisplay,
  reducedHeaders,
  iconImage,
  url,
  className,
  richText,
  richTextListMarkerStyle,
  showOpenAccountButton,
  openAccountButtonText,
  primaryCtaText,
  primaryCtaUrl,
  hasBackground,
  padCopy = true,
  increasedPadding = false,
  copyPosition = CardCopyPosition.TOP,
  backgroundColour = Colour.warmStone,
  hasImage,
  inTakeover,
  singleCardRow = false,
  hideImageOnMobile,
  reverseOrder = false
}) => {
  const isPrivacy = useIsPrivacyPage();
  const isBlog = useIsBlogPage();

  return (
    <div
      className={cx(
        styles.cardCopy,
        styles[richTextListMarkerStyle!],
        styles[backgroundColour],
        className,
        {
          [styles.hasBackground]: hasBackground,
          [styles.padCopy]: padCopy,
          [styles.copyPositionBottom]: copyPosition === CardCopyPosition.BOTTOM,
          [styles.copyPositionCentre]: copyPosition === CardCopyPosition.CENTRE,
          [styles.hasImage]: hasImage,
          [styles.increasedPadding]: increasedPadding,
          [styles.hiddenImageOnMobile]: hideImageOnMobile,
          [styles.blog]: isBlog,
          [styles.privacy]: isPrivacy,
          [styles.inTakeover]: inTakeover,
          [styles.singleCardRow]: singleCardRow,
          [styles.reverseOrder]: reverseOrder
        }
      )}
    >
      {number && (
        <span
          className={cx(styles.number, { [styles.reduced]: reducedHeaders })}
        >
          {number}
        </span>
      )}

      {iconImage && (
        <img
          className={styles.icon}
          alt={iconImage.title}
          src={iconImage?.file?.url}
        />
      )}

      {title && <h4 className={styles.title}>{title}</h4>}
      {subtitle && <h5 className={styles.subtitle}>{subtitle}</h5>}
      {bodyCopy && <p className={styles.bodyCopy}>{bodyCopy}</p>}
      {urlDisplay && url && (
        <p className={styles.url}>
          <Link backgroundColour={backgroundColour} url={url}>
            {urlDisplay}
          </Link>
        </p>
      )}

      {showOpenAccountButton && (
        <div className={styles.cardButton}>
          <OpenAccountButton text={openAccountButtonText} />
        </div>
      )}

      {primaryCtaText && primaryCtaUrl && (
        <div className={styles.cardButton}>
          <Button variant={ButtonVariants.SECONDARY} url={primaryCtaUrl}>
            {primaryCtaText}
          </Button>
        </div>
      )}

      {richText && (
        <div className={styles.richText}>
          <>{useBlogRichText(richText)}</>
        </div>
      )}
    </div>
  );
};
