import { useIsClient } from "@mettle/hooks/lib";
import { useSiteMetadata } from "./useSiteMetadata";
import { useMemo } from "react";
import { EnvConfig, getDefaultConfig, getProdConfig } from "../conf/env";

export const useConfig = (): EnvConfig | null => {
  const isClient = useIsClient();
  const { hosts } = useSiteMetadata();

  return useMemo(() => {
    if (isClient) {
      return hosts.includes(window.location.host)
        ? getProdConfig()
        : getDefaultConfig();
    } else {
      return null;
    }
  }, [isClient]);
};
