import React, { FC, MouseEventHandler } from "react";
import styles from "./styles.module.scss";
import cx from "classnames";
import SVG from "react-inlinesvg";

export interface IconographyButtonProps {
  icon: string;
  handler: MouseEventHandler;
  disabled?: boolean;
  value: string;
  className?: string;
  hollow?: boolean;
}

export const IconographyButton: FC<IconographyButtonProps> = ({
  icon,
  handler,
  disabled = false,
  value,
  className,
  hollow
}) => {
  return (
    <button
      type="button"
      value={value}
      aria-label={value}
      className={cx(styles.button, className, {
        [styles.hollow]: Boolean(hollow)
      })}
      disabled={disabled}
      onKeyDown={e => e.stopPropagation()}
      onClick={handler}
    >
      <SVG src={icon} />
    </button>
  );
};
