import React, { useState } from "react";
import { SimpleInterestState } from "../components/CalculatorSection/Calculators/SimpleInterestCalculator";

export const useCalculatorState = () => {
  const MAX_GOAL = 100000;
  const MAX_RATE = 100;
  const [calculatorState, setCalculatorState] = useState<SimpleInterestState>({
    valid: true,
    monthly: "0",
    yearly: "0",
    compoundYearly: "0",
    savingGoal: "200",
    rate: "3.65"
  });

  const compoundResult = (rate: string, goal: string): number => {
    const parsedRate = parseFloat(rate);
    const parsedGoal = parseFloat(goal);

    return Math.pow(1 + parsedRate / 100, 12) * parsedGoal - parsedGoal;
  };

  const calculate = () => {
    setCalculatorState(prev => {
      const parsedRate = parseFloat(prev.rate).toFixed(2);
      const parsedSavingGoal = String(parseInt(prev.savingGoal, 10));
      const monthly = (Number(parsedRate) / 100) * Number(parsedSavingGoal);

      return {
        ...prev,
        rate: parsedRate,
        savingGoal: parsedSavingGoal,
        monthly: parseFloat(String(monthly)).toFixed(2),
        yearly: parseFloat(String(monthly * 12)).toFixed(2),
        compoundYearly: compoundResult(prev.rate, prev.savingGoal).toFixed(2)
      };
    });
  };

  const validInputs = (goal: string, rate: string) => {
    return (
      Number(goal) > 0 &&
      Number(goal) <= MAX_GOAL &&
      Number(rate) > 0 &&
      Number(rate) <= MAX_RATE
    );
  };

  const handleSavingInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target) {
      const val = e.target.value.replace(/-/, "");

      setCalculatorState(prev => {
        return {
          ...prev,
          savingGoal: val,
          valid: validInputs(val, prev.rate)
        };
      });
    }
  };

  const handleInterestInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target) {
      const val = e.target.value.replace(/-/, "");

      setCalculatorState(prev => {
        return {
          ...prev,
          rate: val,
          valid: validInputs(prev.savingGoal, val)
        };
      });
    }
  };

  return {
    MAX_GOAL,
    MAX_RATE,
    handleInterestInputChange,
    handleSavingInputChange,
    calculate,
    ...calculatorState
  };
};
