import React, { FC } from "react";
import styles from "./styles.module.scss";
import { Link } from "../Link";
import {
  facebookLogo,
  instagramLogo,
  linkedInLogo,
  tiktokLogo,
  twitterLogo
} from "../../assets/social";

type PlatformName =
  | "twitter"
  | "linkedin"
  | "facebook"
  | "instagram"
  | "tiktok";

export interface FooterSocialLink {
  id: string;
  displayName: string;
  platformName: PlatformName;
  url: string;
}

interface SocialLinkProps {
  links: FooterSocialLink[];
}

export const SocialLinks: FC<SocialLinkProps> = ({ links }) => {
  const fetchImage = (platformName: PlatformName) => {
    const imageMap = {
      twitter: twitterLogo,
      linkedin: linkedInLogo,
      facebook: facebookLogo,
      instagram: instagramLogo,
      tiktok: tiktokLogo
    };

    return imageMap[platformName];
  };

  return (
    <ul id="socialLinkList" className={styles.socialLinks}>
      {links.map(
        (link: {
          id: string;
          displayName: string;
          platformName: PlatformName;
          url: string;
        }) => {
          return (
            <li key={link.id}>
              <Link
                className={styles.link}
                newTab
                url={link.url}
                title={link.displayName}
              >
                <img
                  src={fetchImage(link.platformName)}
                  alt={link.displayName}
                />
              </Link>
            </li>
          );
        }
      )}
    </ul>
  );
};
