import React, { FC } from "react";
import { Button, ButtonProps } from "components/Button";
import {
  getQuickLinks,
  QuickLinkEnum,
  useButtonAnalytics,
  useOnboardingState
} from "hooks";
import { useDeviceOs } from "@mettle/hooks";
import { isIOs, isMobileOs } from "lib/os";
import { useConfig } from "hooks/useConfig";
import { Env } from "conf/env";
import useIsPotsPage from "hooks/useIsPotsPage";

export const OpenAccountButton: FC<ButtonProps> = ({
  id = "unknown",
  children,
  onClick = () => {},
  text,
  smallText = false,
  ...buttonProps
}) => {
  const os = useDeviceOs();
  const { show } = useOnboardingState();
  const config = useConfig();

  const quickLinks = getQuickLinks();
  const {
    prodLink: iosProdLink,
    testLink: iosTestLink,
    savingsPotLink: savingsPotLink
  } = quickLinks[QuickLinkEnum.GET_METTLE_DESKTOP_IOS];
  const { prodLink: androidProdLink, testLink: androidTestLink } =
    quickLinks[QuickLinkEnum.GET_METTLE_DESKTOP_ANDROID];
  const isPotsPage = useIsPotsPage();
  const inner = text || (children as string) || "Open account";

  const handleClick = useButtonAnalytics<HTMLAnchorElement>(
    inner,
    `open-account-${id}`,
    evt => {
      onClick(evt);
      show();
    }
  );

  const responsiveProps = () => {
    if (!config || !isMobileOs(os)) {
      return { onClick: handleClick };
    }
    const androidLink =
      config.ENV === Env.TEST ? androidTestLink : androidProdLink;
    const iosLink = config.ENV === Env.TEST ? iosTestLink : iosProdLink;
    const url = isIOs(os)
      ? isPotsPage
        ? savingsPotLink
        : iosLink
      : androidLink;

    return { url };
  };

  return (
    <Button
      dataTrack="open-account"
      smallText={smallText}
      {...buttonProps}
      {...responsiveProps()}
    >
      {inner}
    </Button>
  );
};
