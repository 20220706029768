import React, { FC } from "react";
import { Link } from "../Link";
import linkedInDarkLogo from "../../assets/social/linkedIn-dark.svg";

interface Props {
  linkedInUrl: string;
  name: string;
  className: string;
}
export const AuthorLinkedInIcon: FC<Props> = ({
  linkedInUrl,
  name,
  className
}) => {
  return (
    <div data-testid="linkedIn" className={className}>
      <Link url={linkedInUrl} title={`linkedIn profile for ${name}`}>
        <img src={linkedInDarkLogo} alt="linkedIn logo" />
      </Link>
    </div>
  );
};

AuthorLinkedInIcon.displayName = "AuthorLinkedInIcon";
