import React, { useEffect, useRef } from "react";
import cx from "classnames";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import {
  FormTouched,
  FormErrors,
  UseFieldInterface,
  FormFieldDefinition
} from "hooks";
import styles from "./styles.module.scss";

export interface Props {
  id: string;
  touched: FormTouched<string>;
  errors: FormErrors<string>;
  useField: (key: string) => UseFieldInterface;
  field: FormFieldDefinition<string | boolean>;
  focusedField: string | null;
}

export const FormField: React.FC<Props> = ({
  id,
  focusedField,
  touched,
  errors,
  useField,
  field
}) => {
  const {
    label,
    className = "",
    focusClassName = "",
    validate,
    extraContent,
    pattern,
    type,
    applyFocus,
    ...inputProps
  } = field;
  const error = (touched[id] && errors[id]) || undefined;
  const hasError = Boolean(error);

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        applyFocus &&
        inputRef.current &&
        (type === "text" || type === "number")
      ) {
        inputRef.current?.focus();
      }
    }, 0);
    return () => clearTimeout(timer);
  }, [inputRef.current, type]);

  return (
    <div
      className={cx(className, {
        [styles.hasError]: hasError,
        [styles.isValid]: touched[id] && !hasError,
        [styles.textInput]: type === "text" || type === "number",
        [styles.checkBox]: type === "checkbox",
        [focusClassName]: id === focusedField
      })}
      data-testid={`field-${id}`}
    >
      <input
        id={id}
        name={id}
        type={type}
        pattern={pattern && pattern.source}
        ref={inputRef}
        {...inputProps}
        {...useField(id)}
      />
      {label && <label>{label}</label>}
      {extraContent}
      <ErrorMessage error={error} />
    </div>
  );
};
FormField.displayName = "FormField";
