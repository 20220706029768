import { useIsClient, useElementResizeDetector } from "@mettle/hooks";
import { useState, useCallback } from "react";

export interface ElementSize {
  width: number;
  height: number;
}

export const getSizeFromElement = ({
  clientWidth,
  clientHeight
}: HTMLElement): ElementSize => ({ width: clientWidth, height: clientHeight });

export const useBodySize = () => {
  const isClient = useIsClient();
  const [size, setSize] = useState(
    isClient ? getSizeFromElement(document.body) : undefined
  );

  const updateSize = useCallback(
    () => setSize(getSizeFromElement(document.body)),
    []
  );

  useElementResizeDetector(isClient ? document.body : undefined, updateSize);
  return size;
};
