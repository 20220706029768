import React from "react";
import { BlogCategoryLink } from "./BlogCategory";
import filterIcon from "assets/icons/settings.svg";
import { CmsCategory } from "../types";
import styles from "./BlogCategories.module.scss";
import { InlineDropDownNav } from "../../InlineDropDownNav";

interface Props {
  categories: CmsCategory[];
  slug: string;
}

export const CategoriesDropdown: React.FC<Props> = ({
  categories = [],
  slug
}) => {
  const activeCategoryTitle = () =>
    categories.find(cat => slug === cat.slug)?.title || "Select";

  return (
    <InlineDropDownNav
      openText="Select"
      icon={filterIcon}
      closedText={activeCategoryTitle()}
      options={categories.map(category => (
        <BlogCategoryLink
          category={category}
          className={styles.category}
          key={category.slug}
        />
      ))}
    />
  );
};

export const BlogCategories: React.FC<Props> = ({ categories, slug }) => {
  return (
    <>
      <div className={styles.categories}>
        {categories.map(cmsCategory => (
          <BlogCategoryLink
            desktop
            category={cmsCategory}
            className={styles.category}
            key={cmsCategory.slug}
            active={cmsCategory.slug === slug}
          />
        ))}
      </div>
      <div className={styles.categoriesDropdown}>
        <CategoriesDropdown slug={slug} categories={categories} />
      </div>
    </>
  );
};
