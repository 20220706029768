import { BlogPostCardProps } from "../BlogHome";
import { CmsBlogPost } from "../types";

export const useMappedPosts = (posts: CmsBlogPost[]) =>
  posts.map((post): BlogPostCardProps => {
    return {
      __typename: "ContentfulBlogPost",
      post: post,
      id: post.id,
      inCarousel: true,
      showSubtitle: false,
      layout: posts.length === 1 ? "triple" : "single"
    };
  });
