import React, { FC } from "react";
import { useContributorUrl } from "../Blog/hooks/useContributorUrl";

interface AuthorHiddenSeoProps {
  knowsAbout: string;
  slug: string;
  linkedInUrl?: string;
}

export const AuthorHiddenSeo: FC<AuthorHiddenSeoProps> = ({
  knowsAbout,
  slug,
  linkedInUrl
}) => {
  return (
    <>
      <span itemProp="knowsAbout" hidden>
        {knowsAbout}
      </span>
      <span itemProp="sameAs" hidden>
        {useContributorUrl(slug, true)}
      </span>
      {linkedInUrl && (
        <span itemProp="sameAs" hidden>
          {linkedInUrl}
        </span>
      )}
    </>
  );
};

AuthorHiddenSeo.displayName = "AuthorHiddenSeo";
