import React, { FC } from "react";
import appStoreIcon from "assets/icons/appStore.png";
import { Link } from "components/Link";
import { APP_STORE_URL, useConfig } from "hooks";
import { Env } from "conf/env";

interface AppleStoreButtonProps {
  iosQuickLink: string;
  testIosQuickLink: string;
  linkClassName?: string;
  imgClassName?: string;
}

export const AppleStoreButton: FC<AppleStoreButtonProps> = ({
  testIosQuickLink,
  iosQuickLink,
  linkClassName,
  imgClassName
}) => {
  const config = useConfig();
  let iosLink = APP_STORE_URL;

  if (config?.BRANCH_IO_ENABLED) {
    iosLink = config.ENV === Env.TEST ? testIosQuickLink : iosQuickLink;
  }

  return (
    <Link url={iosLink} className={linkClassName} data-testid="ios-link">
      <img
        src={appStoreIcon}
        className={imgClassName}
        alt="Download the Mettle app on iOS"
      />
    </Link>
  );
};
