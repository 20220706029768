import React from "react";
import cx from "classnames";
import { graphql } from "gatsby";
import { Link } from "components/Link";
import { CmsCategory } from "../types";
import styles from "./BlogCategory.module.scss";

export interface BlogCategoryProps {
  category: CmsCategory;
  active?: boolean;
  className?: string;
  onClick?: () => void;
  desktop?: boolean;
}

export const BlogCategory: React.FC<BlogCategoryProps> = ({
  category,
  active = false,
  className = "",
  onClick = () => {}
}) => {
  const { title } = category;
  return (
    <div
      onClick={onClick}
      className={cx(styles.category, className, {
        [styles.active]: active
      })}
    >
      {title}
    </div>
  );
};

export const BlogCategoryLink: React.FC<BlogCategoryProps> = ({
  category,
  className,
  active,
  onClick,
  desktop
}) => {
  return (
    <Link
      url={`/blog/${category.slug + "/"}`}
      className={cx(styles.categoryLink, className, {
        [styles.desktop]: Boolean(desktop)
      })}
      activeClassName={styles.categoryLinkActive}
    >
      <BlogCategory category={category} active={active} onClick={onClick} />
    </Link>
  );
};

export const ContentfulBlogCategoryFragment = graphql`
  fragment Category on ContentfulCategory {
    id
    title
    heading
    description {
      raw
    }
    slug
    order
    icon {
      file {
        url
      }
    }
  }
`;
