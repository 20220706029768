import * as React from "react";
import { MenuLinks } from "components/Navigation/MenuLinks";

interface Props {
  className?: string;
}

export const DesktopMenu: React.FC<Props> = ({ className }) => (
  <section className={className}>
    <MenuLinks />
  </section>
);
