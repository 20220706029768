import { Options, Search, SearchResult } from "lib/search";
import { useState, useMemo, useCallback } from "react";
import debounce from "lodash/debounce";

const DEBOUNCE_DELAY = 700;

export function useSearch<T>(items: T[], options: Options) {
  const search = useMemo(() => new Search(items, options), [items, options]);
  const [results, setResults] = useState<SearchResult<any>>({
    items: [],
    status: "skipped",
    searchTerm: ""
  });

  const find = useCallback(
    (term: string) => {
      setResults(search.find(term));
    },
    [search, setResults]
  );

  const clearResults = useCallback(() => {
    setResults({
      items: [],
      status: "skipped",
      searchTerm: ""
    });
  }, [setResults]);

  const debouncedFind = useCallback(
    debounce(find, DEBOUNCE_DELAY, {
      leading: false,
      trailing: true
    }),
    [find]
  );

  return {
    clearResults: clearResults,
    find: debouncedFind,
    results
  };
}
